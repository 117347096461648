<div class="container-fulid">
  <div class="row">
    <h3>Credit Details</h3>
  </div>

  <div class="row d-flex align-items-center justify-content-between">

    <!-- Commodity Name Dropdown (Start) -->
    <div class="col-lg-3 col-md-4 col-sm-6 ms-2">
      <div class="form-floating">
        <select class="form-select" aria-label="Floating label select example"
          (change)="updateDetails($event,'commodity')" [(ngModel)]="depositorName">
          <option selected disabled value="">Select Commodity Name</option>
          <option value="all">All</option>
          <option *ngFor="let commodity of this.depositorNameDetails">{{commodity}}</option>
        </select>
        <label for="floatingSelect">Commodity Name</label>
      </div>
    </div>

    <!-- Search Input (End) -->
    <div class="col-lg-3 col-md-4 col-sm-6">
      <div class="form-floating">
        <input type="search" class="form-control" placeholder="Search" [(ngModel)]="searchText">
        <label class="text-gray">Search</label>
      </div>
    </div>

    <div class="col-lg-2 col-md-4 mt-2 d-flex justify-content-end align-items-end">
      <button class="btn btn-danger mt-2" (click)="resetFilters()">Clear Filters <img class="img-fluid" alt="clear"
          src="assets/img/icons/clear-alt.png"></button>
    </div>
    <div class="col-lg-2 col-md-4 mt-2">
      <button class="btn btn-success mt-2" (click)="downloadExcel()">Download Excel <img class="img-fluid" alt="clear"
          src="assets/img/icons/file-excel.png"></button>
    </div>
  </div>


  <div class="py-2"></div>

  <div class="container-fluid">
    <div class="table-style table-responsive table-bordered">
      <table class="table table-head-fixed text-nowrap order-table text-center">
        <thead>
          <tr class="sticky-top">
            <th>No</th>
            <th>Depositor Name</th>
            <th>Available Balances</th>
            <th>Account Number</th>
            <th>Transaction History</th>
            <th>Add Cash</th>
          </tr>
        </thead>
        <tbody *ngIf="(getCreditDetails | filter:searchText).length > 0; else noDataFound">
          <tr *ngFor="let aftc of getCreditDetails | filter: searchText;let i = index">
            <td>{{i+1}}</td>
            <td>{{aftc?.depositorName}}</td>
            <td>{{aftc?.availableBalance | currency : 'INR'}}</td>
            <td>{{aftc?.accountNo}}</td>
            <td><i class="fa fa-eye" matTooltip="Click to view traction history"
                (click)="showTransactionHistory(aftc?.transactionTrackingDetails)"></i></td>
            <td><button class="btn btn-success" type="button" matTooltip="Click to add credit amount"
                (click)="showCreditPopup(aftc);">Add Cash</button></td>
          </tr>
        </tbody>
        <ng-template #noDataFound>
          <tr class="text-center ubuntu-bold h6 text-danger">
            <td colspan="12">Data Not Found</td>
          </tr>
        </ng-template>
      </table>
    </div>
  </div>
</div>

<div class="modal fade show" tabindex="-1" [style.display]="creditAddDetails ? 'block' : 'none'"
  style="background-color: rgba(87,86,86,0.64)">
  <div class="modal-dialog modal-xl modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Depositor Name # {{depositorName}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="creditAddDetails = false"></button>
      </div>
      <div class="modal-body bg-light">
        <div class="container">
          <form [formGroup]="transactionDetails">
            <div class="row">
              <div class="col-md-6 col-lg-4 col-12 mt-2">
                <div class="form-floating">
                  <input type="text" class="form-control text-capitalize" placeholder="Depositor Name" [readOnly]="true"
                    formControlName="depositorName">
                  <label class="form-label">Depositor Name</label>
                </div>
              </div>

              <div class="col-md-6 col-lg-4 col-12 mt-2">
                <div class="form-floating">
                  <input type="text" class="form-control text-capitalize" placeholder="Depositor Name" [readOnly]="true"
                    formControlName="serviceType">
                  <label class="form-label">Service Type</label>
                </div>
              </div>

              <div class="col-md-6 col-lg-4 col-12 mt-2">
                <div class="form-floating">
                  <input type="tel" class="form-control text-capitalize" placeholder="Enter Credit Amount"
                    formControlName="amount" (keypress)="OnlyNumbers($event)">
                  <label class="form-label">Enter Credit Amount <span class="text-danger">*</span></label>
                </div>
                <span *ngIf="transactionDetailsErrorValidation.amount" class="text-danger ms-2">{{
                  transactionDetailsErrorValidation.amount }}</span>
              </div>

              <div class="col-md-6 col-lg-4 col-12 mt-2">
                <div class="form-floating">
                  <input type="text" class="form-control text-capitalize" placeholder="Enter Transaction / Bill No"
                    formControlName="transactionId">
                  <label class="form-label">Enter Transaction / Bill No <span class="text-danger">*</span></label>
                </div>
                <span *ngIf="transactionDetailsErrorValidation.transactionId" class="text-danger ms-2">{{
                  transactionDetailsErrorValidation.transactionId }}</span>
              </div>

              <div class="col-md-6 col-lg-4 col-12 mt-2">
                <div class="form-floating">
                  <input type="date" class="form-control text-capitalize" placeholder="Billing Date"
                    formControlName="billingDate" (change)="showBillingDate($event)">
                  <label class="form-label">Billing Date <span class="text-danger">*</span></label>
                </div>
                <span *ngIf="transactionDetailsErrorValidation.billingDate" class="text-danger ms-2">{{
                  transactionDetailsErrorValidation.billingDate }}</span>
              </div>

            </div>
            <div class="row mt-5">
              <div class="col-md-12 col-lg-12 col-12 mt-2 text-center">
                <button type="submit" class="btn btn-success" (click)="saveDetails()">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade show" tabindex="-1" [style.display]="transactionHistory ? 'block' : 'none'"
  style="background-color: rgba(87,86,86,0.64)">
  <div class="modal-dialog modal-xl modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Depositor Name # {{depositorName}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="transactionHistory = false"></button>
      </div>
      <div class="modal-body bg-light">
        <div class="table-style table-responsive table-bordered">
          <table class="table table-head-fixed text-nowrap order-table text-center">
            <thead>
              <tr class="sticky-top">
                <th>S.No</th>
                <th scope="col">Depositor Name</th>
                <th scope="col">Amount</th>
                <th scope="col">Add By</th>
                <th scope="col">Note</th>
                <th scope="col">Transaction / Bill No</th>
                <th scope="col">Billing Date</th>
                <th scope="col">Payment Type</th>
                <th scope="col">Date & Time</th>
              </tr>
            </thead>
            <tbody class="text-gray"
              *ngIf="(transactionHistoryDetails | filter:searchText).length > 0; else noDataFoundTransaction">
              <tr *ngFor="let amount of this.transactionHistoryDetails; let i = index;">
                <td>{{i + 1}}</td>
                <td>{{amount?.depositorName || '-'}}</td>
                <td>{{(amount?.amount | currency : 'INR') || '-'}}</td>
                <td>{{amount?.addedBy || '-'}}</td>
                <td>{{amount?.notes || '-'}}</td>
                <td>{{amount?.transactionId || '-'}}</td>
                <td>{{(amount?.billingDate | date : "dd-MM-yyyy") || '-'}}</td>
                <td><span class="badge" [ngClass]="{'badge-gradient-red': amount?.paymentType === 'DEBIT',
                    'badge-gradient-dark-green': amount?.paymentType === 'CREDIT' }">{{
                    formatStatus(amount?.paymentType) || '-'}}</span></td>
                <td>{{(amount?.createdAt | date : "dd-MM-yyy / hh:mm:ss aa") || '-'}}</td>
              </tr>
            </tbody>
            <ng-template #noDataFoundTransaction>
              <tr class="text-center ubuntu-bold h6 text-danger">
                <td colspan="12">Data Not Found</td>
              </tr>
            </ng-template>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>


<ngx-spinner>
  <div id="page">
    <div id="container">
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div class="fw-bolder" id="h3"><img src="../assets/img/AFTC logo.png" class="logo" alt="Sample image"></div>
    </div>
  </div>
</ngx-spinner>