import { Component, OnInit } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { ColdStorageMaintenance } from "@components/aftc-digitalization/aftc-digitalization-models/coldStorageMaintenance";


@Component({
  selector: 'app-stock-management',
  templateUrl: "./stock-management.component.html",
  styleUrls: ["./stock-management.component.scss"]
})

export class StockManagementComponent implements OnInit {
  searchText;
  destroy$: Subject<boolean> = new Subject<boolean>();
  stockListDetails: ColdStorageMaintenance[] = [];
  tableStockListData: ColdStorageMaintenance[] = [];

  selectedFilters: { depositorName: string; commodity: string; maintenanceType: string } = {
    depositorName: '',
    commodity: '',
    maintenanceType: '',
  };
  filters = [
    { key: 'depositorName', label: 'Depositor Name', options: [] },
    { key: 'commodity', label: 'Commodity Name', options: [] },
    { key: 'maintenanceType', label: 'Maintenance Type', options: [] },
  ];


  constructor(
    private apiServices: ApiService,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService
  ) {
  }

  ngOnInit() {
    this.getAllStockDetails();
  }


  //All Stock Details get function
  getAllStockDetails() {
    this.spinner.show();
    this.apiServices
      .getAllStockDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe((stockList) => {
        this.spinner.hide();
        this.stockListDetails = stockList;
        this.stockListDetails.sort((a, b) => b.stockMaintainId - a.stockMaintainId);
        this.initializeFilters();
        this.tableStockListData = this.stockListDetails;
        console.log('stock list::', this.stockListDetails);

      }, error => {
        this.spinner.hide();
        console.error("Error:", error);
        const errorMessage = error?.error?.message || "Something went wrong, please try again";
        this.toaster.error(errorMessage);
      })
  }

  // Filter data based on selected filters
  initializeFilters() {
    this.filters.forEach((filter) => {
      filter.options = Array.from(
        new Set(
          this.stockListDetails
            .map((item) => item[filter.key])
            .filter((val) => val && val.trim())
        )
      );
    });
  }

  //Select values filter function
  applyFilters() {
    this.tableStockListData = this.stockListDetails.filter((item) =>
      Object.keys(this.selectedFilters).every((key) => {
        const selectedValue = this.selectedFilters[key];
        return selectedValue === '' || selectedValue === 'all' || item[key] === selectedValue;
      })
    );
  }

  // Reset filters function
  resetFilters() {
    this.selectedFilters = { depositorName: '', commodity: '', maintenanceType: '', };
    this.tableStockListData = [...this.stockListDetails];
  }

  //Format stock status function
  formatStatus(status: string): string {
    switch (status) {
      case 'DEPOSIT':
        return 'Deposit';
      case 'DELIVERY':
        return 'Delivery';
      default:
        return status;
    }
  }
  
  //Download Excel file function
  downloadExcel() {
    if (!this.tableStockListData || this.tableStockListData.length === 0) {
      this.toaster.warning('No data available to download!');
      return;
    }

    // Map data for Excel
    const exportData = this.tableStockListData.map((stock, index) => ({
      'S.No': index + 1,
      'Deposit No': stock.depositorName || '-',
      'Depositor Name': stock.depositorName || '-',
      'Commodity': stock.commodityName || '-',
      'Closing Packages': stock.closingPackage || '-',
      'Closing Weight': stock.closingWeight || '-',
      'Date': stock.date
        ? new Date(stock.date).toLocaleDateString('en-GB')
        : '-',
      'Maintenance Type': stock.maintenanceType || '-',
    }));

    // Create worksheet
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    // Create workbook
    const workbook: XLSX.WorkBook = {
      Sheets: { 'Filtered Data': worksheet },
      SheetNames: ['Filtered Data'],
    };

    // Generate Excel file
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    // Save the file
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const fileName = `Filtered_Data_${new Date().toISOString().slice(0, 10)}.xlsx`;
    saveAs(blob, fileName);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
