import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalDataServiceService {

  constructor() { }

   /*getHeaders() {
    return new HttpHeaders({
        "Authorization":this.getToken()
    });
}*/

  getHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ this.getToken(),
      'Access-Control-Allow-Origin': '*'
    });
  }

getToken() {
    return localStorage.getItem("authHeader")
}
getContentHeader() {
  return new HttpHeaders({
'Content-Type': 'application/json',
'Access-Control-Allow-Origin': '*',
'Platform' : 'WEB'
  })
}
}
