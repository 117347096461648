import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from "@angular/common";
import { ColdStorageDepositDetails } from "@components/aftc-digitalization/aftc-digitalization-models/coldStorageDepositDetails";


@Component({
    selector: "app-depositor-view",
    templateUrl: "./depositor-view.component.html",
    styleUrls: ["./depositor-view.component.scss"]
})
export class DepositorViewComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    getAllDepositorsDetails: ColdStorageDepositDetails[] = [];
    depositorDetails: ColdStorageDepositDetails[] = [];
    searchText;
    depositorNameDetails: string[] = [];
    CommodityNameDetails: string[] = [];
    depositorCreatedDateDetails: string[] = [];
    depositorName: string = "";
    commodityName: string = "";
    depositorCreatedDate: string = "";

    constructor(private apiServices: ApiService, private router: Router,
        private toaster: ToastrService, private spinner: NgxSpinnerService, private datePipe: DatePipe) {

    }

    ngOnInit(): void {
        this.getAllColdStorageDepositorDetails();
    }

    getAllColdStorageDepositorDetails() {
        this.spinner.show();
        this.apiServices.getColdStorageDepositorDetails()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                console.log(data);
                this.spinner.hide();
                this.getAllDepositorsDetails = data.sort((a, b) => b.stockDepositId - a.stockDepositId)
                this.depositorDetails = this.getAllDepositorsDetails;
                this.depositorNameDetails = [...new Set(this.getAllDepositorsDetails.map(item => item.depositorName))];
                this.CommodityNameDetails = [...new Set(this.getAllDepositorsDetails.map(item => item.commodityName))];
                this.depositorCreatedDateDetails = [...new Set(this.getAllDepositorsDetails.map(item => this.datePipe.transform(item.createdAt * 1000, 'dd/MM/yyyy')))];
            }, (error: any) => {
                this.spinner.hide();
                console.log("Something Went wrong");
                this.toaster.error(error.error.msg || "Something went wrong, Please try again later....")
            })
    }

    // review details filter functionality
    updateDetails(event: any, filterType: string) {
        const value = event.target.value;
        switch (filterType) {
            case 'name':
                this.depositorName = value !== 'Select Depositor Name' ? value : 'all';
                break;

            case 'commodity':
                this.commodityName = value !== 'Select Commodity Name' ? value : 'all';
                break;

            case 'date':
                this.depositorCreatedDate = value !== 'Select Date' ? value : 'all';
                break;

            default:
                break;
        }
        this.applyFilter();
    }

    // filter based on the show table functionality
    applyFilter() {
        let filteredData = [...this.getAllDepositorsDetails];

        if (this.depositorName && this.depositorName !== 'all') {
            filteredData = filteredData.filter(item => item.depositorName === this.depositorName);
        }

        this.CommodityNameDetails = [...new Set(filteredData.map(item => item.commodityName))];
        this.depositorCreatedDateDetails = [...new Set(filteredData.map(item =>
            this.datePipe.transform(item.createdAt * 1000, 'dd/MM/yyyy')
        ))];

        if (this.commodityName && this.commodityName !== 'all') {
            filteredData = filteredData.filter(item => item.commodityName === this.commodityName);
        }

        this.depositorCreatedDateDetails = [...new Set(filteredData.map(item =>
            this.datePipe.transform(item.createdAt * 1000, 'dd/MM/yyyy')
        ))];

        if (this.depositorCreatedDate && this.depositorCreatedDate !== 'all') {
            filteredData = filteredData.filter(item =>
                this.datePipe.transform(item.createdAt * 1000, 'dd/MM/yyyy') === this.depositorCreatedDate
            );
        }
        this.depositorDetails = filteredData;
    }

    resetReviewFilter() {
        this.searchText = '';
        this.depositorName = '';
        this.commodityName = '';
        this.depositorCreatedDate = '';

        this.depositorDetails = [...this.getAllDepositorsDetails];
        this.depositorNameDetails = [...new Set(this.getAllDepositorsDetails.map(item => item.depositorName))];
        this.CommodityNameDetails = [...new Set(this.getAllDepositorsDetails.map(item => item.commodityName))];
        this.depositorCreatedDateDetails = [...new Set(this.getAllDepositorsDetails.map(item =>
            this.datePipe.transform(item.createdAt * 1000, 'dd/MM/yyyy')
        ))];
    }
}
