import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { ColdStorageCreditedDetails, TransactionTrackingDetails } from "@components/aftc-digitalization/aftc-digitalization-models/cold-storage-credited-details";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

@Component({
  selector: "app-cold-storage-credit-screen",
  templateUrl: "./cold-storage-credit-screen.component.html",
  styleUrls: ["./cold-storage-credit-screen.component.scss"]
})
export class ColdStorageCreditScreenComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  searchText;
  getCreditDetails: ColdStorageCreditedDetails[] = [];
  creditAddDetails: boolean = false;
  depositorName: string = "";
  adminId: number = 0;
  userType: string = "";
  transactionHistory: boolean = false;
  transactionHistoryDetails: TransactionTrackingDetails[] = [];
  billingDate: number = 0;
  depositorNameDetails: string[] = [];
  getAllCreditDetails: ColdStorageCreditedDetails[] = [];

  constructor(private apiServices: ApiService, private router: Router,
    private toaster: ToastrService, private spinner: NgxSpinnerService) {

  }

  transactionDetails = new UntypedFormGroup({
    depositorName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    amount: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    serviceType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    status: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    depositorDetailsId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    addedById: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    addedBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    depositorUserId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    paymentType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    transactionId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    billingDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
  })

  transactionDetailsErrorValidation = {
    amount: "",
    transactionId: "",
    billingDate: "",
  }

  ngOnInit(): void {
    this.getAllCreditedDetails();
    this.adminId = parseInt(localStorage.getItem('loginId'));
    this.userType = localStorage.getItem('role');
  }

  //All Credited details get function
  getAllCreditedDetails() {
    this.spinner.show();
    this.apiServices.getColdStorageAllCreditDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        console.log(data);
        this.spinner.hide();
        this.getAllCreditDetails = data.sort((a, b) => b.depositorDetailsId - a.depositorDetailsId);
        this.depositorNameDetails = [...new Set(this.getAllCreditDetails.map(item => item.depositorName))];
        this.getCreditDetails = this.getAllCreditDetails
      }, (error: any) => {
        this.spinner.hide();
        console.log("Something Went wrong");
        this.toaster.error(error.error.msg || "Something went wrong, Please try again later....")
      });
  }


  // Current select filter value set function
  updateDetails(event: any, filterType: string) {
    const value = event.target.value;

    switch (filterType) {
      case 'name':
        this.depositorName = value !== 'Select Depositor Name' ? value : 'all';
        break;
    }

    this.applyFilter();
  }

  //Apply filter function
  applyFilter() {
    // Start with the full dataset
    let filteredData = [...this.getAllCreditDetails];

    // Apply depositor name filter
    if (this.depositorName && this.depositorName !== 'all') {
      filteredData = filteredData.filter(item => item.depositorName === this.depositorName);
    }

    this.getCreditDetails = filteredData;
  }


  // Depositor add cash popup open & set value function
  showCreditPopup(account: ColdStorageCreditedDetails) {
    if (account.depositorDetailsId != 0) {
      this.transactionDetails.reset();
      this.creditAddDetails = true;
      this.depositorName = account.depositorName;
      this.transactionDetails.controls['depositorName'].setValue(account.depositorName);
      this.transactionDetails.controls['serviceType'].setValue("Cold Storage");
      this.transactionDetails.controls['depositorDetailsId'].setValue(account.depositorDetailsId);
      this.transactionDetails.controls['addedById'].setValue(this.adminId);
      this.transactionDetails.controls['addedBy'].setValue(this.userType);
      this.transactionDetails.controls['status'].setValue(1);
      this.transactionDetails.controls['paymentType'].setValue("CREDIT");
    } else {
      this.toaster.error("Depositor details not found, Please try again later....")
      this.creditAddDetails = false;
    }
  }

  //Credited card save validation function
  saveDetails() {
    this.transactionDetailsErrorValidation.amount = "";
    this.transactionDetailsErrorValidation.transactionId = "";
    this.transactionDetailsErrorValidation.billingDate = "";
    let hasError = false;
    if (this.transactionDetails.get("amount")?.invalid) {
      this.transactionDetails.markAllAsTouched();
      this.transactionDetailsErrorValidation.amount = "*Amount is Required";
      hasError = true;
    } if (this.transactionDetails.get("transactionId")?.invalid) {
      this.transactionDetails.markAllAsTouched();
      this.transactionDetailsErrorValidation.transactionId = "*Transaction ID is Required";
      hasError = true;
    } if (this.transactionDetails.get("billingDate")?.invalid) {
      this.transactionDetails.markAllAsTouched();
      this.transactionDetailsErrorValidation.billingDate = "*Billing Date is Required";
      hasError = true;
    }
    if (!hasError) {
      this.saveCreditedDetails();
    }
  }

  //Credit balance save function
  saveCreditedDetails() {
    this.spinner.show();
    this.transactionDetails.controls['billingDate'].setValue(this.billingDate);
    this.apiServices.addCreditAmount(this.transactionDetails.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log(data);
        this.spinner.hide();
        this.toaster.success("Credited Details Saved Successfully");
        this.getAllCreditedDetails();
        this.transactionDetails.reset();
        this.creditAddDetails = false;
      }, (err: any) => {
        this.spinner.hide();
        this.toaster.error(err.err.message || "Something went wrong, please try again later")
      });
  }


  // Number only acceptable condition
  OnlyNumbers($event) {
    let regex: RegExp = new RegExp(/^[0-9]{1,}$/g);
    let specialKeys: Array<string> = ["Backspace", "Tab", "End", "Home", "ArrowRight", "ArrowLeft"];
    if (specialKeys.indexOf($event.key) !== -1) {
      return;
    } else {
      if (regex.test($event.key)) {
        return true;
      } else {
        return false;
      }
    }
  }

  //Transaction history get function
  showTransactionHistory(details: TransactionTrackingDetails[]) {
    this.transactionHistory = true;
    this.transactionHistoryDetails = details.sort((a, b) => b.transactionTrackId - a.transactionTrackId);
    this.depositorName = details[0].depositorName;
  }

  //Current status formatted function
  formatStatus(status: string): string {
    switch (status) {
      case 'DEBIT':
        return 'DEBIT';
      case 'CREDIT':
        return 'CREDIT';
      default:
        return status;
    }
  }

  //Billing date formatted function
  showBillingDate(event: any): void {
    const date = new Date(event.target.value);
    const epoch = date.getTime();
    console.log('showBillingDate', epoch);
    this.billingDate = epoch;
  }

  // Remove filter function values
  resetFilters() {
    this.depositorName = '';
    this.depositorNameDetails = [...new Set(this.getAllCreditDetails.map(item => item.depositorName))];
    this.getCreditDetails = this.getAllCreditDetails;
  }

  // Download excel file function
  downloadExcel() {
    if (!this.getCreditDetails || this.getCreditDetails.length === 0) {
      this.toaster.warning('No data available to download!');
      return;
    }

    // Map data for Excel
    const exportData = this.getCreditDetails.map((credit, index) => ({
      'S.No': index + 1,
      'Depositor Name': credit.depositorName || '-',
      'Available Balance': credit.availableBalance || '-',
      'Account Number': credit.accountNo || '-',
      'Date': credit.createdAt
        ? new Date(credit.createdAt).toLocaleDateString('en-GB')
        : '-',
    }));

    // Create worksheet
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    // Create workbook
    const workbook: XLSX.WorkBook = {
      Sheets: { 'Filtered Data': worksheet },
      SheetNames: ['Filtered Data'],
    };

    // Generate Excel file
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    // Save the file
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const fileName = `Filtered_Data_${new Date().toISOString().slice(0, 10)}.xlsx`;
    saveAs(blob, fileName);
  }
}
