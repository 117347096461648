<div class="container">
  <div class="col-12 mt-5">
    <div class="mt-3 border-line">
      <div class="row">
        <div class="col-6 mt-2">
          <p style="margin-left: 10px;">GSTIN: <b>33AABCT1101F1Z7</b></p>
        </div>
        <div class="col-6 mt-2">
          <p class="text-end" style="margin-right: 10px;">Phone No: <b>0452 - 2660669</b></p>
        </div>
      </div>
      <div class="col-12 text-center">
        <h2 class="text-bold" style="margin-top: -16px">AGRO FOOD TRADE CENTRE</h2>
        <p style="font-size: 14px;margin-top: -2px">(Formerly: Tamilnadu Foodgrains Marketing Yard),
          Madurai-Allanganallur Road,Sikkantharchavadi,Madurai- 625018</p>
        <h4 class="text-bold" style="margin-top: -4px">Tax Invoice</h4>
      </div>
    </div>

    <table class="border-1">
      <tr>
        <!-- First Column -->
        <!-- <table> -->
        <td class="align-top col-6">
          <span class="span-font mb-3 ml-2" *ngIf="this.depotDetails?.aftcMembershipType === 'UM'">Utility Member
            :</span><br>
          <span class="span-font mb-3 ml-2" *ngIf="this.depotDetails?.aftcMembershipType === 'SH'">Stackholder
            :</span><br>
          <h5 class="text-bold ml-2 mb-0">{{this.depotDetails?.depotOccupantName}} -
            {{this.depotDetails?.aftcMembershipType}}</h5>
          <span class="ml-2 text-blod span-font">GSTIN : </span>
        </td>
        <!-- </table> -->

        <!-- Second Column -->

        <table class="border-1">
          <td class="col-8 border-start">
            <span class="span-font">Invoice No.</span><br>
            <span class="span-font"><b>{{ this.depositorDetails?.invoiceNo }}</b></span>
          </td>
          <td class="col-4 border-start">
            <span class="span-font">Dated</span><br>
            <span class="span-font"><b>{{ (this.depositorDetails?.billDate) | date: 'dd/MM/yyyy' }}</b></span>
          </td>
          <tr>
            <td colspan="2" class="border-start">
              <span class="span-font"></span><br>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="border-start">
              <span class="span-font">CSU - {{formateInvoiceNumber(this.depositorDetails?.invoiceNo)}}</span><br>
            </td>
          </tr>
        </table>
      </tr>
    </table>

    <table class="table-sm" style="max-height: 530px">
      <thead>
        <tr class="text-center" style="height: 10px">
          <th style="border-top: 1px solid transparent;width: 10px;border-right: 2px black solid">S.No</th>
          <th style="border-top: 1px solid transparent;border-right: 2px black solid">Particulars</th>
          <th style="border-top: 1px solid transparent;border-right: 2px black solid">SAC</th>
          <th class="col-2" style="border-top: 1px solid transparent">Amount (<svg xmlns="http://www.w3.org/2000/svg"
              width="20" height="20" fill="currentColor" class="bi bi-currency-rupee" viewBox="0 0 16 16">
              <path
                d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
            </svg>)</th>
        </tr>
      </thead>
      <tbody>
        <tr class="item" style="height: 10px">
          <td style="border-bottom: 2px solid transparent" class="text-center"><span
              class="span-font text-bold">1</span>
          </td>
          <td style="border-bottom: 2px solid transparent"><span class="text-bold span-font">Cold Storage Unloading &
              Loading Charges Taxable</span>
          </td>
          <td style="border-bottom: 2px solid transparent"><span class="span-font text-bold">9971</span></td>
          <td style="border-bottom: 2px solid transparent" class="font-weight-bolder text-right h5">
            {{this.depositorDetails?.handlingCharge.toFixed(2)}}</td>
        </tr>
        <tr class="item" style="height: 10px">
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border: transparent" class="row ms-2">
            <span class="span-font"> {{this.depositorDetails?.commodityName}} - {{this.depositorDetails?.quantity}}
              {{this.depositorDetails?.packageType}}S
              , {{ (this.depositorDetails?.weight * 0.001) | number: '1.3-3' }} Tons </span><br>
            <span class="span-font"
              *ngIf="this.depositorDetails?.packageType === 'BAG' && this.depositorDetails?.commodityType === 'Chillies'">
              {{this.depositorDetails?.quantity}} {{this.depositorDetails?.packageType}}S * Rs.
              {{this.billingDetails?.chargeAmount}} =
              Rs. {{this.depositorDetails?.handlingCharge.toFixed(2)}}</span>
            <span class="span-font"
              *ngIf="this.depositorDetails?.packageType === 'BAG' && this.depositorDetails?.commodityType != 'Chillies'">
              {{ (this.depositorDetails?.weight * 0.001) | number: '1.3-3' }} Tons * Rs.
              {{this.billingDetails?.chargeAmount}} = Rs. {{this.depositorDetails?.handlingCharge.toFixed(2)}}</span>
            <span class="span-font"
              *ngIf="this.depositorDetails?.packageType === 'BOX'">{{this.depositorDetails?.quantity }}
              Box * Rs. {{this.billingDetails?.chargeAmount}} = Rs. {{this.depositorDetails?.handlingCharge.toFixed(0)}}
            </span>
          </td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
        </tr>
        <tr class="item" style="height: 10px">
          <td style="border-bottom: 2px solid transparent" class="text-center"><span
              class="span-font text-bold">2</span>
          </td>
          <td style="border-bottom: 2px solid transparent;border-top: 2px solid transparent" class="font-weight-bolder">
            <span class="span-font text-bold">{{this.cgstBillDetails?.chargeName}} -
              {{this.cgstBillDetails?.chargeAmount}} %</span>
          </td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent" class="text-right h5"><span
              class="span-font text-bold">{{this.depositorDetails?.cgstAmount}}</span></td>
        </tr>
        <tr class="item" style="height: 10px">
          <td style="border-bottom: 2px solid transparent" class="text-center"><span
              class="span-font text-bold">3</span>
          </td>
          <td style="border-bottom: 2px solid transparent" class="">
            <span class="span-font text-bold">{{this.sgstBillDetails?.chargeName}} -
              {{this.sgstBillDetails?.chargeAmount}} %</span>
          </td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent" class="text-right h5"><span
              class="span-font text-bold">{{this.depositorDetails?.sgstAmount}}</span></td>
        </tr>
        <tr class="item" style="height: 10px">
          <td style="border-bottom: 2px solid transparent" class="text-center"><span
              class="span-font text-bold">4</span>
          </td>
          <td style="border-bottom: 2px solid transparent" class=""><span class="span-font text-bold">Rounded Off</span>
          </td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent" class="text-right h5"><span
              class="span-font text-bold">{{this.depositorDetails?.roundedOff}}</span></td>
        </tr>
        <tr>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
        </tr>
        <tr>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
        </tr>
        <tr>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
        </tr>
        <tr>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
        </tr>
        <tr>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
        </tr>
        <tr>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
        </tr>
        <tr>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
        </tr>
        <tr>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
        </tr>
        <tr>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
        </tr>
        <tr>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
        </tr>
        <tr>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
        </tr>
        <tr>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
        </tr>
        <tr>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
        </tr>
        <tr>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
        </tr>
        <tr>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
        </tr>
        <tr>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
        </tr>
        <tr>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent"></td>
        </tr>
        <tr>
          <td style="border-bottom: 2px solid black"></td>
          <td style="border-bottom: 2px solid black"></td>
          <td style="border-bottom: 2px solid black"></td>
          <td style="border-bottom: 2px solid black"></td>
        </tr>
        <tr>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent" class="text-end">
            <h5 class="text-bold">Total</h5>
          </td>
          <td style="border-bottom: 2px solid transparent"></td>
          <td style="border-bottom: 2px solid transparent" class="text-right h5">
            <h5 class="text-bold">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                class="bi bi-currency-rupee" viewBox="0 0 16 16">
                <path
                  d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
              </svg> {{(this.depositorDetails?.totalAmount.toFixed(2))}}
            </h5>
          </td>
        </tr>
      </tbody>
    </table>
    <table style="border-bottom: 2px solid transparent">
      <div style="border-top: 1px solid transparent;padding: 3px">
        <h4 class="text-bold ms-2">Indian Rupees {{this.totalAmountInWords}} Only</h4>
      </div>
    </table>
    <table style="border-bottom: 2px solid transparent">
      <div class="row">
        <div class="col">
          <h5 class="ms-2">Acc.Name : Agro Food Trade Centre</h5>
        </div>
        <div class="col">
          <h5 class="text-end">Acc. No. : 835120110000006</h5>
        </div>
      </div>
      <div class="row">
        <div class="col ms-2">
          <h5 class="">Bank of India - Milakaranai Branch</h5>
        </div>
        <div class="col">
          <h5 class="text-end">IFSC : BKID0008351</h5>
        </div>
      </div>
    </table>
    <table>
      <div class="row">
        <div class="col-7">
          <div class="row" style="margin-top: 112px;">
            <div class="col-6 ms-1">
              <p class="text-bold mb-0 ml-4">Prepared By</p>
            </div>
            <div class="col-5">
              <p class="text-bold text-end mb-0">Checked By</p>
            </div>
          </div>
        </div>
        <div class="col-5" style="border-left: 1px solid black;">
          <h5 class="text-center text-bold">For AGRO FOOD TRADE CENTRE</h5>
          <div class="row">
            <div class="col-6 ms-5">
              <p class="text-bold mb-0"></p>
            </div>
            <div class="col-" style="margin-top: 80px;">
              <p class="text-bold mb-0 text-end">Authorised Signatory</p>
            </div>
          </div>
        </div>
      </div>
    </table>
  </div>
  <div class="col-2"></div>
</div>