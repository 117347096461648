import { ApiService } from "@services/api.service";
import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { interval, Subject, Subscription, takeUntil } from "rxjs";
import { TollGate } from "@components/aftc-digitalization/aftc-digitalization-models/TollGate";
import { Router } from "@angular/router";
import { PrintDataService } from "@/model/labModal/PrintDataService";
import { ToastrService } from "ngx-toastr";
import { ColdStorageDepositDetails } from "@components/aftc-digitalization/aftc-digitalization-models/coldStorageDepositDetails";
import { ColdStorageBillDetails } from "@components/aftc-digitalization/aftc-digitalization-models/cold-storage-bill-details";
import { depotDetails } from "@/model/depotDetails/depotDetails";

@Component({
  selector: "app-cold-storage-deposit-case-print",
  templateUrl: "./cold-storage-deposit-case-print.component.html",
  styleUrls: ["./cold-storage-deposit-case-print.component.css"]
})
export class ColdStorageDepositCasedPrintComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  currentDate: string;
  depositorDetails: ColdStorageDepositDetails | undefined;
  getAllBillingDetails: ColdStorageBillDetails[] = [];
  billingDetails: ColdStorageBillDetails | undefined;
  cgstBillDetails: ColdStorageBillDetails | undefined;
  sgstBillDetails: ColdStorageBillDetails | undefined;
  totalAmountInWords: string = "";
  depotDetails: depotDetails | undefined;

  constructor(private apiService: ApiService, private datePipe: DatePipe, private toaster: ToastrService,
    private router: Router, private dataService: PrintDataService) {
    this.currentDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
  }

  ngOnInit() {
    const depositorId = sessionStorage.getItem("depositorId");
    this.getDepositorDetailsById(depositorId);
    this.getColdStorageBillDetails();
  }

  ngOnDestroy() {
    sessionStorage.removeItem("depositorId");
  }
  // Get delivery details for deposporter id based
  getDepositorDetailsById(depositorId) {
    this.apiService.getColdStorageDepositDetailsById(depositorId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.depositorDetails = data;
        this.depotDetails = data.depotDetails;
        console.log(this.depositorDetails);
        this.totalAmountInWords = this.numberToWords(Math.floor(this.depositorDetails?.totalAmount));
        setTimeout(() => {
          this.print();
        }, 3000);
      }, (err: any) => {
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  // Billing details based on charge name
  getColdStorageBillDetails() {
    this.apiService.getColdStorageBillDetails('CS_DEPOSIT_REQUEST_HANDLING_CHARGE')
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.getAllBillingDetails = data;
        console.log(this.getAllBillingDetails)
        setTimeout(() => {
          this.FilterBillingDetails()
        }, 1500);
      }, (err: any) => {
        this.toaster.error(err.error.message || "Something went wrong, Please try again later");
      });
  }

  // Billing details get for place of origin values based 
  FilterBillingDetails() {
    const list = this.depositorDetails;
    let value: string = "";
    if (list.packageType === 'BAG' && list.placeOfOrigin === 'TAMILNADU' && list.commodityType === 'ExceptChillies') {
      value = "BAG_TN_EXCEPT_CHILLIES_PER_TON";
    } else if (list.packageType === 'BAG' && list.placeOfOrigin !== 'TAMILNADU' && list.commodityType === 'ExceptChillies') {
      value = "BAG_OTHER_STATES_EXCEPT_CHILLIES_PER_TON";
    } else if (list.packageType === 'BAG' && list.placeOfOrigin === 'TAMILNADU' && list.commodityType === 'Chillies' && list.weight <= 20) {
      value = "BAG_TN_EXCEPT_CHILLIES_UPTO_20_KG";
    } else if (list.packageType === 'BAG' && list.placeOfOrigin === 'TAMILNADU' && list.commodityType === 'Chillies' && list.weight > 20) {
      value = "BAG_TN_EXCEPT_CHILLIES_ABOVE_20_KG";
    } else if (list.packageType === 'BOX') {
      value = "BOX_UNIT_CHARGE";
    }

    this.billingDetails = this.getAllBillingDetails.find(x => x.chargeName === value);
    this.cgstBillDetails = this.getAllBillingDetails.find(x => x.chargeName === "CGST");
    this.sgstBillDetails = this.getAllBillingDetails.find(x => x.chargeName === "SGST");
    if (this.billingDetails) {
      console.log('Billing Details:', this.billingDetails);
      return this.billingDetails;
    } else {
      console.error('No matching billing details found for value:', value);
      return null;
    }
  }

  // For amount letter format
  numberToWords(number: number): string {
    const units: string[] = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
    const teens: string[] = ["", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
    const tens: string[] = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

    function convertThreeDigits(num: number): string {
      let result = "";
      if (num >= 100) {
        result += units[Math.floor(num / 100)] + " Hundred ";
        num %= 100;
      }
      if (num >= 20) {
        result += tens[Math.floor(num / 10)] + " ";
        num %= 10;
      }
      if (num > 0) {
        if (num < 10) {
          result += units[num] + " ";
        } else {
          result += teens[num - 10] + " ";
        }
      }
      return result.trim();
    }

    const crores = Math.floor(number / 10000000);
    const millions = Math.floor(number % 10000000 / 100000);
    const lakhs = Math.floor(number % 100000 / 1000);
    const thousands = Math.floor(number % 1000);
    const hundreds = Math.floor(number % 100);

    let result = "";

    if (crores > 0) {
      result += convertThreeDigits(crores) + " Crore ";
    }

    if (millions > 0) {
      result += convertThreeDigits(millions) + " Lakh ";
    }

    if (lakhs > 0) {
      result += convertThreeDigits(lakhs) + " Thousand ";
    }

    if (thousands > 0) {
      result += convertThreeDigits(thousands) + " ";
    }

    if (result === "") {
      return "zero Only";
    }

    return result;
  }

  // Depot number formatted function
  formateInvoiceNumber(number: string | null | undefined): string {
    if (!number) {
      return '';
    }
    return number.slice(14);
  }


  // Print page navigation function
  print() {
    window.print();
    setTimeout(() => {
      this.router.navigate(["/main/admin-cold-storage-deposit-request-view"]);
    });
  }
}