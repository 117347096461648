import { TollGate } from "@components/aftc-digitalization/aftc-digitalization-models/TollGate";
import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, takeUntil } from "rxjs";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import {
  WarehouseStockDeposit
} from "@/components/aftc-digitalization/aftc-digitalization-models/warehouseStockDeposit";
import { depotDetails } from "@/model/depotDetails/depotDetails";


@Component({
  selector: "app-warehouse-deposit-entry-form",
  templateUrl: "./warehouse-deposit-entry-form.component.html",
  styleUrls: ["./warehouse-deposit-entry-form.component.scss"]
})
export class WarehouseDepositEntryFormComponent implements OnInit {

  getLocalPassDetails: TollGate[] = [];
  getPassDetails: TollGate | undefined;
  depositorDetails: WarehouseStockDeposit | undefined;
  destroy$: Subject<boolean> = new Subject<boolean>();
  userId: number;
  // allMemberDetails: depotDetails[] = [];
  // currentMemberDetails: depotDetails[] = [];

  // dropdownList = [];
  // selectedItem = this.dropdownList;
  // dropdownSettings = {};

  constructor(private apiServices: ApiService, private spinner: NgxSpinnerService,
              private router: Router, private toaster: ToastrService) {
                // this.dropdownSettings = {
                //   singleSelection: true,
                //   idField: 'item_id',
                //   textField: 'item_text',
                //   searchPlaceholderText: 'Search',
                //   noDataAvailablePlaceholderText: 'No data available',
                //   allowSearchFilter: true,
                //   closeDropDownOnSelection: true,
                //   allowRemoteDataSearch: false,
                //   itemsShowLimit: 3,
                //   lazyLoading: false,
                //   selectAllText: '',
                //   unSelectAllText: '',
                // };
  }

  depositForm = new UntypedFormGroup({
    stockDepositId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    depositApplicationNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    depositDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    depositorName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    invoiceNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    declarationDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    endDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    commodity: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    noOfBags: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    weight: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    typeOfVehicle: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    wheels: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehicleNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    amount: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    createdAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    updatedAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    createdBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    updatedBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    currentStage: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    irmReceiptNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    storageType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    serviceType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    billPeriodType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    supplierName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    billDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
  });

  depositFormErrors = {
    depositApplicationNo: "",
    depositorName: "",
    // supplierName: "",
    invoiceNo: "",
    iRMReceiptNo: "",
    depositDate: "",
    declarationDate: "",
    commodity: "",
    noOfBags: "",
    weight: "",
    typeOfVehicle: "",
    wheels: "",
    vehicleNo: "",
    amount: "",
    billPeriodType: ""
  };

  formValidation(from: any) {
    this.depositFormErrors.depositApplicationNo = "";
    this.depositFormErrors.depositorName = "";
    // this.depositFormErrors.supplierName = "";
    this.depositFormErrors.invoiceNo = "";
    this.depositFormErrors.iRMReceiptNo = "";
    this.depositFormErrors.depositDate = "";
    this.depositFormErrors.declarationDate = "";
    this.depositFormErrors.commodity = "";
    this.depositFormErrors.noOfBags = "";
    this.depositFormErrors.weight = "";
    this.depositFormErrors.typeOfVehicle = "";
    this.depositFormErrors.wheels = "";
    this.depositFormErrors.vehicleNo = "";
    this.depositFormErrors.amount = "";
    this.depositFormErrors.billPeriodType = "";
    let hasError = false;

    if (this.depositForm.get("depositApplicationNo").invalid) {
      this.depositFormErrors.depositApplicationNo = "*Deposit Application No is Required";
      hasError = true;
    }
    if (this.depositForm.get("depositorName").invalid) {
      this.depositFormErrors.depositorName = "*Depositor Name is Required";
      hasError = true;
    }
    // if (this.depositForm.get("supplierName").invalid) {
    //   this.depositFormErrors.supplierName = "*Supplier Name is Required";
    //   hasError = true;
    // }
    if (this.depositForm.get("invoiceNo").invalid) {
      this.depositFormErrors.invoiceNo = "*Invoice Number / Self Declaration is Required";
      hasError = true;
    }
    if (this.depositForm.get("irmReceiptNo").invalid) {
      this.depositFormErrors.iRMReceiptNo = "*IRM Receipt Number is Required";
      hasError = true;
    }
    if (this.depositForm.get("declarationDate").invalid) {
      this.depositFormErrors.declarationDate = "*Declaration Date is Required";
      hasError = true;
    }
    if (this.depositForm.get("commodity").invalid) {
      this.depositFormErrors.commodity = "*Commodity is Required";
      hasError = true;
    }
    if (this.depositForm.get("noOfBags").invalid) {
      this.depositFormErrors.noOfBags = "*No of Bags is Required";
      hasError = true;
    }
    if (this.depositForm.get("weight").invalid) {
      this.depositFormErrors.weight = "*Weight is Required";
      hasError = true;
    }
    if (this.depositForm.get("typeOfVehicle").invalid) {
      this.depositFormErrors.typeOfVehicle = "*Type of Vehicle is Required";
      hasError = true;
    }
    if (this.depositForm.get("wheels").invalid) {
      this.depositFormErrors.wheels = "*Wheels Count is Required";
      hasError = true;
    }
    if (this.depositForm.get("vehicleNo").invalid) {
      this.depositFormErrors.vehicleNo = "*Vehicle Number is Required";
      hasError = true;
    }
    if (this.depositForm.get("amount").invalid) {
      this.depositFormErrors.amount = "*Amount is Required";
      hasError = true;
    }
    if (this.depositForm.get("billPeriodType").invalid) {
      this.depositFormErrors.billPeriodType = "*Bill Period Type is Required";
      hasError = true;
    }

    if (!hasError) {
      this.addStockDeposit();
    }
  }

  ngOnInit() {
    const subReport = sessionStorage.getItem("depositId");
    if (!!subReport) {
      this.getDepositorDetailsById(subReport);
    } else {
      if (sessionStorage.getItem("storageType") == "Common Warehouse") {
        this.getCWApplicationNo();
      } else if (sessionStorage.getItem("storageType") == "SLCM") {
        this.getSLCMApplicationNo();
      }
      this.getTollgateDetailsForWarehouseDeposit();
      this.userId = Number(localStorage.getItem('userId'));
      // this.getAllMemberDetails();
    }
    this.initial_data();
  }

  ngOnDestroy() {
    sessionStorage.removeItem("storageType");
    sessionStorage.removeItem("serviceType");
    sessionStorage.removeItem("tollgateSeqNo");
    sessionStorage.removeItem("depositId");
  }

  getCWApplicationNo() {
    this.apiServices.getDepositAppNo()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.depositForm.controls["depositApplicationNo"].setValue(data.depositAppNo);
      }, (err: any) => {
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  getSLCMApplicationNo() {
    this.apiServices.getSLCMDepositAppNo()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.depositForm.controls["depositApplicationNo"].setValue(data.depositAppNo);
      }, (err: any) => {
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  initial_data() {
    const now = new Date();
    const localDateTime = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
    const formattedDateTime = localDateTime.toISOString().slice(0, 16);
    this.depositForm.controls["depositDate"].setValue(formattedDateTime);
  }

  // getAllMemberDetails(){
  //   this.spinner.show();
  //   this.apiServices.getDepotDetails()
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe(data => {
  //       this.spinner.hide();
  //       this.allMemberDetails = data;
  //       this.currentMemberDetails = this.allMemberDetails.sort((a, b) => a.depotOccupantName.localeCompare(b.depotOccupantName));
  //   this.dropdownList = this.currentMemberDetails.map(member => {
  //     var returnValue = {};
  //     if (member.depotNo != '') {
  //       returnValue = { item_id: member.depotDetailsId, item_text: member.depotOccupantName + ' / ' + member.depotNo }
  //     } else {
  //       returnValue = { item_id: member.depotDetailsId, item_text: member.depotOccupantName }
  //     }
  //     return returnValue;
  //   });
  //     },(err: any) => {
  //       this.spinner.hide();
  //       this.toaster.error(err.error.message || "Something went wrong, Please try again later");
  //     })
  // }

  getTollgateDetailsForWarehouseDeposit() {
    this.apiServices.getTollGateById(Number(sessionStorage.getItem('tollgateSeqNo')))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.getPassDetails = data
        this.depositForm.controls["depositorName"].setValue(this.getPassDetails.memberName);
        this.depositForm.controls["commodity"].setValue(this.getPassDetails.commodityName);
        this.depositForm.controls["noOfBags"].setValue(this.getPassDetails.bagsUnits);
        this.depositForm.controls["weight"].setValue(this.getPassDetails.weightMt);
        this.depositForm.controls["typeOfVehicle"].setValue(this.getPassDetails.tollgateVehicleDetails.vehType);
        this.depositForm.controls["wheels"].setValue(this.getPassDetails.tollgateVehicleDetails.wheelCount);
        this.depositForm.controls["vehicleNo"].setValue(this.getPassDetails.tollgateVehicleDetails.vehNumberPlate);
        this.depositForm.controls["amount"].setValue(this.getPassDetails.charges);
        this.depositForm.controls["irmReceiptNo"].setValue(this.getPassDetails.irmNo);
        this.depositForm.controls["storageType"].setValue(sessionStorage.getItem("storageType"));
        this.depositForm.controls["serviceType"].setValue(sessionStorage.getItem("serviceType"));
      }, (err: any) => {
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  getDepositorDetailsById(depositorId) {
    this.apiServices.getDepositDetailsById(depositorId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.depositorDetails = data;
        this.depositForm.controls["depositApplicationNo"].setValue(this.depositorDetails.depositApplicationNo + "A");
        this.depositForm.controls["depositorName"].setValue(this.depositorDetails.depositorName);
        this.depositForm.controls["commodity"].setValue(this.depositorDetails.commodity);
        this.depositForm.controls["noOfBags"].setValue(this.depositorDetails.noOfBags);
        this.depositForm.controls["weight"].setValue(this.depositorDetails.weight);
        this.depositForm.controls["typeOfVehicle"].setValue(this.depositorDetails.typeOfVehicle);
        this.depositForm.controls["wheels"].setValue(this.depositorDetails.wheels);
        this.depositForm.controls["vehicleNo"].setValue(this.depositorDetails.vehicleNo);
        this.depositForm.controls["amount"].setValue(this.depositorDetails.amount);
        this.depositForm.controls["irmReceiptNo"].setValue(this.depositorDetails.irmReceiptNo);
        this.depositForm.controls["storageType"].setValue(this.depositorDetails.storageType);
        this.depositForm.controls["serviceType"].setValue(this.depositorDetails.serviceType);
        this.depositForm.controls["invoiceNo"].setValue(this.depositorDetails.invoiceNo);
        this.depositForm.controls["supplierName"].setValue(this.depositorDetails.supplierName);
        this.depositForm.controls["billPeriodType"].setValue(this.depositorDetails.billPeriodType);
        const milliseconds = this.depositorDetails.declarationDate * 1000;
        const date = new Date(milliseconds);
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}T${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
        this.depositForm.controls["declarationDate"].setValue(formattedDate);
      }, (err: any) => {
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  addStockDeposit() {
    this.spinner.show();
    const entryDate = this.depositForm.get("depositDate").value;
    const epochTimestamp = new Date(entryDate).getTime() / 1000;
    this.depositForm.controls["depositDate"].setValue(epochTimestamp);
    const declarationDate = this.depositForm.get("declarationDate").value;
    const epochStartTimestamp = new Date(declarationDate).getTime() / 1000;
    this.depositForm.controls["declarationDate"].setValue(epochStartTimestamp);
    this.depositForm.controls["createdAt"].setValue(Math.floor(new Date().getTime() / 1000));
    this.depositForm.controls["billDate"].setValue(Math.floor(new Date().getTime() / 1000));
    this.depositForm.controls["createdBy"].setValue(this.userId);
    this.apiServices.addStockDeposit(this.depositForm.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        this.saveAlert(data.stockDepositId);
      }, (err: any) => {
        this.spinner.hide();
      });
  }

  saveAlert(depositId: number) {
    Swal.fire({
      title: "Details Saved Successfully...",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate__animated animate__fadeInDown"
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp"
      }
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.setItem("depositorId", String(depositId));
        this.router.navigate(["/deposit-print"]);
      }
    });
  }

}
