import { ApiService } from "@services/api.service";
import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { interval, Subject, Subscription, takeUntil } from "rxjs";
import { TollGate } from "@components/aftc-digitalization/aftc-digitalization-models/TollGate";
import { Router } from "@angular/router";
import { PrintDataService } from "@/model/labModal/PrintDataService";
import { ToastrService } from "ngx-toastr";
import { ColdStorageDepositDetails } from "@components/aftc-digitalization/aftc-digitalization-models/coldStorageDepositDetails";
import { ColdStorageBillDetails } from "@components/aftc-digitalization/aftc-digitalization-models/cold-storage-bill-details";
import { ColdStorageDeliveryDetails } from "@components/aftc-digitalization/aftc-digitalization-models/coldStorageDeliveryDetails";
import { ColdStorageMaintenance } from "@components/aftc-digitalization/aftc-digitalization-models/coldStorageMaintenance";
import { ColdStorageBuildingDetails, ColdStorageRecord, depotDetails } from "@/model/depotDetails/depotDetails";

@Component({
  selector: "app-cold-storage-delivery-case-print",
  templateUrl: "./cold-storage-delivery-case-print.component.html",
  styleUrls: ["./cold-storage-delivery-case-print.component.css"]
})
export class ColdStorageDeliveryCasedPrintComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  currentDate: string;
  deliveryDetails: ColdStorageDeliveryDetails | undefined;
  getAllBillingDetails: ColdStorageBillDetails[] = [];
  billingDetails: ColdStorageBillDetails | undefined;
  cgstBillDetails: ColdStorageBillDetails | undefined;
  sgstBillDetails: ColdStorageBillDetails | undefined;
  maintenanceDetails: ColdStorageMaintenance | undefined;
  totalAmountInWords: string = "";
  deliveryRequestNumber: string = "";
  depotDetails: depotDetails | undefined;
  getAllColdStorageList: ColdStorageRecord[] = [];
  buildingDetails: any[] = [];
  DateDetails: string = "";
  handlingChargeChillies: number = 0;
  spaceWithOpenSpaceAllowed: boolean = false;

  constructor(private apiService: ApiService, private datePipe: DatePipe, private toaster: ToastrService,
    private router: Router, private dataService: PrintDataService) {
    this.currentDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
  }

  ngOnInit() {
    const depositorId = sessionStorage.getItem("depositorId");
    this.getDepositorDetailsById(depositorId);
    this.getColdStorageBillDetails();
  }

  ngOnDestroy() {
    sessionStorage.removeItem("depositorId");
  }

  // Get delivery details for deposporter id based
  getDepositorDetailsById(depositorId) {
    this.apiService.getColdStorageDeliveryDetailsById(depositorId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.deliveryDetails = data;
        this.deliveryRequestNumber = data.deliverOrderNo;
        this.depotDetails = data.depotDetails;
        if (this.deliveryDetails?.commodityName === 'Chillies') {
          this.handlingChargeChillies = (this.deliveryDetails?.weight * (5 / 20));
        }
        console.log(this.deliveryDetails);
        this.totalAmountInWords = this.numberToWords(Math.floor(this.deliveryDetails?.totalCharges));
        this.getColdStorageDeliveryMaintenanceByApplicationNumber();
        this.getBuildDetails();
        setTimeout(() => {
          this.print();
        }, 3000);
      }, (err: any) => {
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  // Billing details based on charge name
  getColdStorageBillDetails() {
    this.apiService.getColdStorageBillDetails('CS_DELIVERY_REQUEST_CHARGE')
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.getAllBillingDetails = data;
        console.log(this.getAllBillingDetails)
        setTimeout(() => {
          this.FilterBillingDetails()
        }, 1500);
      }, (err: any) => {
        this.toaster.error(err.error.message || "Something went wrong, Please try again later");
      });
  }

  // Delivery application number based on details get function
  getColdStorageDeliveryMaintenanceByApplicationNumber() {
    const jsonData = {
      applicationNumber: this.deliveryRequestNumber
    }
    this.apiService.getColdStorageDeliveryMaintenanceByApplicationNumber(jsonData)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.maintenanceDetails = data;
        console.log(data);
      }, (err: any) => {
        this.toaster.error(err.error.message || "Something went wrong, Please try again later");
      });
  }


  // User depotId based on building details get function
  getBuildDetails() {
    this.apiService.getColdStorageMemberSpaceId(this.depotDetails.depotDetailsId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          this.getAllColdStorageList = data;
          this.buildingDetails = this.processBuildingDetails(data);
          this.DateDetails = this.convertEpochToShortMonthYear(data[0].createdAt);
          if (this.getAllColdStorageList.length >= 0) {
            this.spaceWithOpenSpaceAllowed = true;
          } else {
            this.spaceWithOpenSpaceAllowed = false;
          }
        },
        (error: any) => {
          console.error("Something went wrong", error);
          this.toaster.error(error.error?.msg || "Something went wrong, Please try again later....");
        }
      );

  }

  // Floor and space separate value set single value function
  processBuildingDetails(data: any[]) {
    const floorSpaceMap: { [key: string]: string[] } = {};
    data.forEach((item) => {
      const buildingDetails = item.csBuildingDetails;
      buildingDetails.forEach((detail: any) => {
        const floorNumber = detail.floorNumber;
        const spaceNumber = detail.spaceNumber;
        if (floorNumber) {
          if (!floorSpaceMap[floorNumber]) {
            floorSpaceMap[floorNumber] = [];
          }
          floorSpaceMap[floorNumber].push(spaceNumber);
        }
      });
    });
    const result = Object.entries(floorSpaceMap).map(([floor, spaces]) => {
      return `${floor} - ${spaces.join(",")}`;
    });

    console.log(result);
    return result;
  }

  //Normal number date to name date value change function
  convertEpochToShortMonthYear(epoch: number): string {
    const date = new Date(epoch);
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = months[date.getMonth()];
    const year = date.getFullYear() % 100;
    return `${month} - Dec'${year}`;
  }


  //billing cgst & sgst value get function
  FilterBillingDetails() {
    const list = this.deliveryDetails;
    let value: string = "";
    this.billingDetails = this.getAllBillingDetails.find(x => x.chargeName === value);
    this.cgstBillDetails = this.getAllBillingDetails.find(x => x.chargeName === "CGST");
    this.sgstBillDetails = this.getAllBillingDetails.find(x => x.chargeName === "SGST");
  }

  // For amount letter format
  numberToWords(number: number): string {
    const units: string[] = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
    const teens: string[] = ["", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
    const tens: string[] = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

    function convertThreeDigits(num: number): string {
      let result = "";
      if (num >= 100) {
        result += units[Math.floor(num / 100)] + " Hundred ";
        num %= 100;
      }
      if (num >= 20) {
        result += tens[Math.floor(num / 10)] + " ";
        num %= 10;
      }
      if (num > 0) {
        if (num < 10) {
          result += units[num] + " ";
        } else {
          result += teens[num - 10] + " ";
        }
      }
      return result.trim();
    }

    const crores = Math.floor(number / 10000000);
    const millions = Math.floor(number % 10000000 / 100000);
    const lakhs = Math.floor(number % 100000 / 1000);
    const thousands = Math.floor(number % 1000);
    const hundreds = Math.floor(number % 100);

    let result = "";

    if (crores > 0) {
      result += convertThreeDigits(crores) + " Crore ";
    }

    if (millions > 0) {
      result += convertThreeDigits(millions) + " Lakh ";
    }

    if (lakhs > 0) {
      result += convertThreeDigits(lakhs) + " Thousand ";
    }

    if (thousands > 0) {
      result += convertThreeDigits(thousands) + " ";
    }

    if (result === "") {
      return "zero Only";
    }

    return result;
  }

  // Invoice only number get function
  formateInvoiceNumber(number: string | null | undefined): string {
    if (!number) {
      return '';
    } else if (number.slice(8, 12) == 'CLDT') {
      return number.slice(13)
    } else {
      return number.slice(12);
    }
  }

  // Depot number formatted function
  formateDepositAppNo(number: string | null | undefined): string {
    if (!number) {
      return '';
    }
    return number.slice(14);
  }


  // Print page navigation function
  print() {
    window.print();
    setTimeout(() => {
      this.router.navigate(["/main/admin-cold-storage-delivery-request-view"]);
    });
  }
}
