<div class="container-fluid vh-100 d-flex flex-column align-items-center justify-content-center text-center overflow-hidden" 
  *ngIf="maintenance">
  <div class="row w-100">
    <div class="col">
      <img src="../../../../assets/img/maintenance.jpg" class="img-fluid mx-auto" style="max-height: 70vh; object-fit: contain;" alt="Maintenance">
      <h1 class="mt-4">We'll be back soon!</h1>
      <p>Please bear with us while we perform some maintenance. Thank you for your patience!</p>
    </div>
  </div>
</div>



<div #sabPaisaSdk *ngIf="!this.maintenance"></div>
<div class="section" *ngIf="!this.maintenance">

  <header class="sticky-top" style="background: #37517e">
    <div class="container" style="position: sticky;">
      <div class="row">
        <div class="col-md-2 col-12"></div>
        <div class="col-md-1 col-12">
          <a href="https://dev1.aftc.in"> <img src="../../../../assets/img/final-chamber.png"
              style="width:130px; height:140px" alt=""> </a>
        </div>
        <div class="col-md-6 hidden text-center mt-3">
          <h5 class="text-bold" style="color: white; margin-bottom: 3px;">AGRO FOOD CHAMBER OF COMMERCE & INDUSTRY</h5>
          <h6 class="text-bold" style="color: white; margin-bottom: 3px;">(Agro Food Chamber)</h6>
          <h6 class="" style="color: white; margin-bottom: -3px;">Agro Food Trade Centre Campus</h6>
          <h6 class="mt-1" style="color: white; margin-bottom: -3px;">Sikkanthar Chavadi, Alanganallur Road, MADURAI –
            625
            018.</h6>
          <h6 class="" style="color: white;"><img src="../../../../assets/img/icons/icons8-outgoing-call-100.png"
              style="height: 25px;width: 25px;"> +91 93639 95494
            <span class="ml-2" style="white-space: nowrap;"><img src="../../../../assets/img/icons/icons8-email-64.png"
                style="height: 30px;width: 30px;"> contact@agrofoodchamber.com</span>
          </h6>
        </div>
      </div>
    </div>
  </header>

  <div class="py-2"></div>
  <div class="container-fluid">
    <div class="px-4">
      <div class="row">
        <div class="col-md-2"></div>

        <div class=" col-md-8">
          <div class="card" style="border-top:8px solid #673AB7;padding: 5px 2px;">
            <h3 class="text-center mt-2 textSmall" style="text-shadow: 1px 1px 1px black;"><span>APPLICATION FOR
                MEMBERSHIP</span>
            </h3>
          </div>
          <div class="card second">


            <div class="row mt-3">
              <div class="col-md-1"></div>

              <div class="col-md-10">
                <div class="row">
                  <form [formGroup]="membership" (ngSubmit)="onSubmit()">

                    <div class="row">
                      <div class="col-md-1"></div>
                      <div class="col-md-4 mt-3">
                        <div style="padding: 5px 10px">
                          <label for="Name">Applicant's Name <span class="text-danger">*</span></label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div style="padding: 5px 10px">
                          <input type="text" class="form-control" id="Name" formControlName="applicantName">
                        </div>
                        <span *ngIf="membershipError.applicantName"
                          class="text-danger ms-2">{{membershipError.applicantName}}</span>
                      </div>
                      <div class="col-md-1"></div>
                    </div>

                    <div class="row">
                      <div class="col-md-1"></div>
                      <div class="col-md-4 mt-3">
                        <div style="padding: 5px 10px">
                          <label for="orgType">Type of Organization <i class="bi bi-caret-down-fill"></i> <span
                              class="text-danger">*</span></label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div style="padding: 5px 10px">
                          <select type="text" formControlName="orgType" class="form-control" id="orgType"
                            (change)="onShowFarmers($event)" placeholder="">
                            <option value="" disabled selected hidden>Select a Type of Organization
                            </option>
                            <option value="Proprietor">Proprietor</option>
                            <option value="PartnershipFirm">Partnership Firm</option>
                            <option value="LLP">LLP</option>
                            <option value="Limited Company">Limited Company</option>
                            <option value="Trade Association/Chamber">Trade Association/Chamber</option>
                            <option value="NGO">NGO</option>
                            <option value="Institute">Institute</option>
                            <option value="Farmer">Farmer</option>
                            <option value="FPO">FPO</option>
                            <option value="Self Help Group">Self Help Group</option>
                          </select>
                          <span *ngIf="membershipError.orgType" class="text-danger">{{membershipError.orgType}}</span>
                        </div>
                      </div>
                      <div class="col-md-1"></div>
                    </div>

                    <div class="row">
                      <div class="col-md-1"></div>
                      <div class="col-md-4 mt-3">
                        <div style="padding: 5px 10px">
                          <label for="Address">Postal Address <span class="text-danger">*</span></label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div style="padding: 5px 10px">
                          <input type="text" class="form-control" id="Address" formControlName="postalAddress">
                        </div>
                        <span *ngIf="membershipError.postalAddress"
                          class="text-danger ms-2">{{membershipError.postalAddress}}</span>
                      </div>
                      <div class="col-md-1"></div>
                    </div>

                    <div class="row">
                      <div class="col-md-1"></div>
                      <div class="col-md-4 mt-3">
                        <div style="padding: 5px 10px">
                          <label for="Pincode">Pincode <span class="text-danger">*</span></label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div style="padding: 5px 10px">
                          <input type="tel" class="form-control" id="Pincode" formControlName="pincode"
                            (keypress)="OnlyNumbers($event)" maxlength="6">
                        </div>
                        <span *ngIf="membershipError.pincode"
                          class="text-danger ms-2">{{membershipError.pincode}}</span>
                      </div>
                      <div class="col-md-1"></div>
                    </div>

                    <div class="row">
                      <div class="col-md-1"></div>
                      <div class="col-md-4 mt-3">
                        <div style="padding: 5px 10px">
                          <label for="ContactPerson">Authorised Representative and
                            his / her Designation <span class="text-danger">*</span></label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div style="padding: 5px 10px">
                          <input type="text" class="form-control" id="ContactPerson" formControlName="contactPerson" 
                          (keydown)="onlyAllowedCharacters($event)">
                          
                        </div>
                        <span *ngIf="membershipError.contactPerson"
                          class="text-danger ms-2">{{membershipError.contactPerson}}</span>
                      </div>
                      <div class="col-md-1"></div>
                    </div>

                    <div class="row">
                      <div class="col-md-1"></div>
                      <div class="col-md-4 mt-3">
                        <div style="padding: 5px 10px">
                          <label for="WhatsApp">His / Her WhatsApp No <span class="text-danger">*</span> <br>
                            <span class="" style="font-size: 13px;">(for communication)</span>
                          </label>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div style="padding: 5px 10px">
                          <input type="tel" class="form-control" id="WhatsApp" (keypress)="OnlyNumbers($event)"
                            formControlName="countryCode" readonly>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div style="padding: 5px 10px">
                          <input type="tel" class="form-control" id="WhatsApp" (keypress)="OnlyNumbers($event)"
                            formControlName="whatsappNo" maxlength="10" [readonly]="readOnly">
                        </div>
                        <span *ngIf="membershipError.whatsappNo"
                          class="text-danger ms-2">{{membershipError.whatsappNo}}</span>
                      </div>
                      <div class="col-md-1"></div>
                    </div>

                    <div class="row" *ngIf="onFarmer">
                      <div class="col-md-1"></div>
                      <div class="col-md-4 mt-3">
                        <div style="padding: 5px 10px">
                          <label for="email">His / Her e-mail ID <br><span class="ml-2" style="font-size: 13px;">(for
                              communication)</span> </label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div style="padding: 5px 10px">
                          <input type="text" class="form-control" id="email" formControlName="emailId">
                        </div>
                      </div>
                      <div class="col-md-1"></div>
                    </div>

                    <div class="row">
                      <div class="col-md-1"></div>
                      <div class="col-md-4 mt-4">
                        <div style="padding: 5px 10px">
                          <label for="">Select a Membership <span class="text-danger">*</span><br>
                            <p style="font-size:10px">(Select Any One)</p>
                          </label>
                        </div>
                      </div>
                      <div class="col-md-7 mt-4">
                        <div style="padding: 5px 10px">
                          <div class="">
                            <input type="radio" value="Long Term Membership" formControlName="memberPeriod" /><span
                              class="ml-2">Long Term Membership <br><span class="ml-2"
                                style="font-size: 13px;">(Rs.2,000 – for the period of 5 years ending
                                31.03.2029)</span></span>
                          </div>
                          <div class="mt-2">
                            <input type="radio" value="Short Term Membership" formControlName="memberPeriod" /><span
                              class="ml-2">Annual Membership<br><span class="ml-2" style="font-size: 13px;">
                                (Rs.500 - for the period ending 31.03.2025)</span></span><br>
                          </div>
                          <div class="mt-2">
                            <input type="radio" value="Test Membership" formControlName="memberPeriod" /><span
                              class="ml-2">Test Membership <span class="ml-2" style="font-size: 13px;">(
                                Rs.1)</span></span><br>
                          </div>
                        </div>
                        <span *ngIf="membershipError.memberPeriod"
                          class="text-danger ms-2">{{membershipError.memberPeriod}}</span>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-3 mb-1"></div>
                      <div class="col-md-6 mb-1 text-center">
                        <button type="submit" class="btn btn-primary" id="submit" *ngIf="onShow">Proceed For Payment
                        </button>
                      </div>
                      <div class="col-md-3 mb-5 mt-2"></div>
                    </div>

                  </form>

                </div>
              </div>
              <div class="col-md-1"></div>
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner>
  <div class="loaders">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
</ngx-spinner>

<div class="footer">
  <footer class="text-center text-white"
    style="background: linear-gradient(90deg, rgba(245,245,220,1) 0%, rgba(208,255,148,1) 100%) !important;">
    <div class="text-center text-white" style="background: #37517e">
      <span>Designed & Developed by <a class="text-primary fw-bolder" href="https://varnikcloud.com/"
          target="_blank">VarNik Systems</a>, Trichy @Copyrights - 2024. All Rights Reserved</span>
    </div>
  </footer>
</div>