export interface depotDetails {
    depotDetailsId: number;
    depotNo: string;
    depotOccupantName: string;
    modelName: string;
    brandName: string;
    vehicleRegNumber: string;
    aftcMembershipType: string;
    serviceType: string;
    createdAt: number;
    updatedAt: number;
    records: string;
    phoneNumber: string
    userName: string
    password: string
    coldStorageRecords: ColdStorageRecord[]
    slDepotNo: string;
    initialDepositAmount: number;
}


export interface ColdStorageRecord {
  storageRecordId: number
  depotId: number
  csBuildingId: number
  rentingType: string
  createdAt: number
  createdBy: string
  csBuildingDetails: ColdStorageBuildingDetails[]
  isBooked: boolean
  isActive: boolean
  }
  
  export interface ColdStorageBuildingDetails {
    csBuildingId: number
    floorNumber: string
    spaceNumber: string
    spaceSize: string
    createdAt: number
    isActive: boolean
  }

  export interface  ColdStorageGetDetails{
    csBuildingId: number,
    floorNumber: string,
    spaceNumber: string,
    isBooked: boolean
  }