<div class="container-fulid">
  <div class="row">
    <div class="col-md-8 justify-content-start">
      <div class="div mb-2 mt-3">
        <h3>Depositor Details</h3>
      </div>
    </div>
    <!-- <div class="col-md-4 justify-content-end">
      <div class="div mb-2 mt-3">
        <input type="search" class="rounded-pill form-control" placeholder="Search" [(ngModel)]="searchText">
      </div>
    </div> -->
  </div>
  <div class="row">
    <div class="row d-flex justify-content-end">
      <div class="col-lg col-md-4 mt-2">
        <div class="form-floating">
          <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
            (change)="updateDetails($event,'name')" [(ngModel)]="depositorName">
            <option selected disabled value="">Select Depositor Name</option>
            <option value="all">All</option>
            <option *ngFor="let name of this.depositorNameDetails">{{name}}</option>
          </select>
          <label for="floatingSelect">Depositor Name</label>
        </div>
      </div>


      <div class="col-lg col-md-4 mt-2">
        <div class="form-floating">
          <select class="form-select" aria-label="Floating label select example"
            (change)="updateDetails($event,'commodity')" [(ngModel)]="commodityName">
            <option selected disabled value="">Select Commodity Name</option>
            <option value="all">All</option>
            <option *ngFor="let commodity of this.CommodityNameDetails">{{commodity}}</option>
          </select>
          <label for="floatingSelect">Commodity Name</label>
        </div>
      </div>

      <div class="col-lg col-md-4 mt-2">
        <div class="form-floating">
          <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
            (change)="updateDetails($event,'date')" [(ngModel)]="depositorCreatedDate">
            <option selected disabled value="">Select Delivery Request Date</option>
            <option value="all">All</option>
            <option *ngFor="let date of this.depositorCreatedDateDetails">{{date}}</option>
          </select>
          <label for="floatingSelect">Delivery Request Date</label>
        </div>
      </div>

      <div class="col-lg col-md-4 mt-2">
        <div class="form-floating">
          <input type="search" class="form-control" placeholder="search" [(ngModel)]="searchText">
          <label class="text-gray">Search</label>
        </div>
      </div>
      <div class="col-lg col-md-4 mt-4 text-end">
        <button type="button" class="btn btn-outline-danger d-flex align-items-center"
          matTooltip="Click to clear all filters" (click)="resetReviewFilter()">Clear Filter<i
            class="fa fa-filter me-2 ms-1 mt-1"></i></button>
      </div>
    </div>
  </div>

  <div class="container-fluid mt-4">
    <div class="table-style table-responsive table-bordered">
      <table class="table table-head-fixed text-nowrap order-table text-center">
        <thead>
          <tr class="sticky-top">
            <th>No</th>
            <th>Depositor Application No</th>
            <th>Depositor Name</th>
            <th>Commodity Name</th>
            <th>Depositor Created Date</th>
          </tr>
        </thead>
        <tbody *ngIf="(depositorDetails | filter:searchText).length > 0; else noDataFound">
          <tr *ngFor="let aftc of depositorDetails | filter: searchText;let i = index">
            <td>{{i+1}}</td>
            <td>{{aftc?.depositApplicationNo}}</td>
            <td>{{aftc?.depositorName}}</td>
            <td>{{aftc?.commodity}}</td>
            <td>{{(aftc?.createdAt) | date : "dd-MM-yyyy / hh:mm:ss aa"}}</td>
          </tr>
        </tbody>
        <ng-template #noDataFound>
          <tr class="text-center ubuntu-bold h6 text-danger">
            <td colspan="12">Data Not Found</td>
          </tr>
        </ng-template>
      </table>
    </div>
  </div>
</div>

<ngx-spinner>
  <div id="page">
    <div id="container">
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div class="fw-bolder" id="h3"><img src="../assets/img/AFTC logo.png" class="logo" alt="Sample image"></div>
    </div>
  </div>
</ngx-spinner>