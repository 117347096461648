<div class="card card-outline card-primary">
    <div class="card-header text-center">
        <a [routerLink]="['/']" class="h1"><b>AFTC</b> Digitization</a>
    </div>
    <div class="card-body">
        <p class="login-box-msg">Sign in to start your session</p>

        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="input-group">
              <input
                  formControlName="username" id="username" [(ngModel)]="username"
                  type="text"
                  class="form-control"
                  placeholder="Username" maxlength="20"
              />
              <div class="input-group-append">
                  <div class="input-group-text">
                      <span class="fas fa-user"></span>
                  </div>
              </div>
          </div>
          <span *ngIf="loginErrors.username" class="text-danger">{{loginErrors.username}}</span>
          <div class="input-group mt-2">
              <input
                  formControlName="password" id="password" [(ngModel)]="password"
                  type="password"
                  class="form-control"
                  placeholder="Password" minlength="8" maxlength="30"
              />
              <div class="input-group-append">
                  <div class="input-group-text">
                      <i class="toggle-password fa fa-lock-open" (click)="togglePasswordVisibility()" [ngClass]="{'fa-lock-open': isPasswordVisible, 'fa-lock': !isPasswordVisible}" aria-hidden="true"></i>
                  </div>
              </div>
          </div>
          <span *ngIf="loginErrors.password" class="text-danger">{{loginErrors.password}}</span>
          <div class="row mt-3">

              <!-- /.col -->
              <div class="btn-block mb-4">
                  <pf-button
                      [type]="'submit'"
                      [block]="true"
                      [loading]="isAuthLoading"
                      [disabled]="isFacebookLoading || isGoogleLoading"
                  >
                      LogIn
                  </pf-button>
              </div>
              <!-- /.col -->
          </div>
      </form>

    </div>
    <!-- /.login-card-body -->
</div>
<ngx-spinner>
    <div id="page">
      <div id="container">
        <div id="ring"></div>
        <div id="ring"></div>
        <div id="ring"></div>
        <div id="ring"></div>
        <div class="fw-bolder" id="h3"><img src="../assets/img/AFTC logo.png" class="logo" alt="Sample image"></div>
      </div>
    </div>
  </ngx-spinner>