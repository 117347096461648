import {Component, OnDestroy, OnInit} from "@angular/core";
import {ApiService} from "@services/api.service";
import {NgxSpinnerService} from "ngx-spinner";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {Subject, takeUntil} from "rxjs";
import {StockDepositDetails} from "@/model/depotDetails/StockDepositDetails";
import {DeliveryDetails} from "@/model/delivery/DeliveryDetails";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-delivery-request-view-details',
  templateUrl: "./delivery-request-view-details.component.html",
  styleUrls: ["./delivery-request-view-details.component.scss"]
})

export class DeliveryRequestViewDetailsComponent implements OnInit, OnDestroy {
  searchText;
  destroy$: Subject<boolean> = new Subject<boolean>();
  deliveryRequestDetails: DeliveryDetails[] = [];
  filteredData: DeliveryDetails[] = [];
  selectedFilters = {
    depositorName: '',
    commodityName: '',
    godownNo: '',
    stackNo: '',
  };
  filters = [
    {key: 'depositorName', label: 'Depositor Name', options: []},
    {key: 'commodityName', label: 'Commodity Name', options: []},
    {key: 'godownNo', label: 'Godown Number', options: []},
    {key: 'stackNo', label: 'Stack Number', options: []},
  ];

  constructor(
    private apiServices: ApiService,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService
  ) {
  }

  ngOnInit() {
    this.getAllDeliveryRequests();
  }

  getAllDeliveryRequests() {
    this.spinner.show();
    this.apiServices
      .getAllDeliveryRequest()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.spinner.hide();
          this.deliveryRequestDetails = data;
          this.filteredData = [...this.deliveryRequestDetails];
          console.log(this.deliveryRequestDetails);
          this.initializeFilters();
        },
        error: (err) => {
          this.spinner.hide();
          const errorMsg = err?.error?.message || 'Error fetching delivery requests';
          this.toaster.error(errorMsg);
        },
      });
  }

  initializeFilters() {
    this.filters.forEach((filter) => {
      filter.options = Array.from(
        new Set(
          this.deliveryRequestDetails
            .map((item) => item[filter.key])
            .filter((val) => val && val.trim())
        )
      );
    });
  }

  applyFilters() {
    this.filteredData = this.deliveryRequestDetails.filter((item) =>
      Object.keys(this.selectedFilters).every((key) => {
        const selectedValue = this.selectedFilters[key];
        return selectedValue === '' || selectedValue === 'all' || item[key] === selectedValue;
      })
    );
  }

  resetFilters() {
    this.selectedFilters = {depositorName: '', commodityName: '', godownNo: '', stackNo: ''};
    this.filteredData = [...this.deliveryRequestDetails];
  }

  downloadExcel() {
    if (!this.filteredData || this.filteredData.length === 0) {
      this.toaster.warning('No data available to download!');
      return;
    }

    // Map data for Excel
    const exportData = this.filteredData.map((req, index) => ({
      'S.No': index + 1,
      'Delivery Order No': req.deliverOrderNo || '-',
      'Depositor Name': req.depositorName || '-',
      'Commodity': req.commodityName || '-',
      'Weight': req.weighing || '-',
      'Delivery Date': req.deliveryDate
        ? new Date(req.deliveryDate * 1000).toLocaleDateString('en-GB')
        : '-',
      'Bags': req.bagsOf || '-',
      'Vehicle No': req.vehicleNo || '-',
      'IRM No': req.irmReceiptNo || '-',
      'Godown No': req.godownNo || '-',
      'Stack No': req.stackNo || '-',
      'Status': req.currentStage || '-',
    }));

    // Create worksheet
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    // Create workbook
    const workbook: XLSX.WorkBook = {
      Sheets: { 'Filtered Data': worksheet },
      SheetNames: ['Filtered Data'],
    };

    // Generate Excel file
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    // Save the file
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const fileName = `Filtered_Data_${new Date().toISOString().slice(0, 10)}.xlsx`;
    saveAs(blob, fileName);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }


  updateDeliveryStatus(stockDeliveryId: number) {
    const updateStatusJson = {
      stockDeliveryId: stockDeliveryId,
      currentStage: 'Out for delivery'
    }

    console.log(updateStatusJson)
    this.spinner.show();
    this.apiServices
      .updateColdStorageDeliveryStatus(updateStatusJson)
      .pipe(takeUntil(this.destroy$))
      .subscribe((updateData) => {
        console.log('update delivery status:', updateData);
        this.spinner.hide();
        this.toaster.success('Delivery status updated successfully');
        this.getAllDeliveryRequests();
      }, error => {
        this.spinner.hide();
        console.error("Error:", error);
        const errorMessage = error?.error?.message || "Something went wrong, please try again";
        this.toaster.error(errorMessage);
      });

  }

}
