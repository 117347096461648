<div class="col-md-12">
  <h3 class="ml-2">Cold Storage Delivery Entry</h3>
</div>
<div class="container-fluid">
  <form [formGroup]="deliveryForm">
    <div class="row mt-2">
      <!-- <div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control textCap" placeholder="Delivery Order No" disabled
                 formControlName="deliverOrderNo">
          <label>Delivery Order No<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="deliveryFormErrors.deliverOrderNo" class="text-danger">{{deliveryFormErrors.deliverOrderNo}}</span>
      </div> -->
      <div class="col-md-4 mt-2 mb-2">
        <div class="form-floating">
          <select type="text" class="form-select" formControlName="depositApplicationNumber"
            (change)="selectedFormNumber($event)">
            <option value="" selected hidden>Select Deposit Application Number</option>
            <option *ngFor="let number of listOfDepositApplicationNumber" value="{{number}}">{{number}}</option>
          </select>
          <label class="form-label">Deposit Application Number </label>
          <span *ngIf="deliveryFormErrors.depositApplicationNumber"
            class="text-danger">{{deliveryFormErrors.depositApplicationNumber}}</span>
        </div>
      </div>

      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="number" class="form-control" placeholder="Available Balance" formControlName="totalBags"
            readonly>
          <label class="form-label">Total Bags</label>
        </div>
      </div>

      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="number" class="form-control" placeholder="Available Balance" formControlName="availableBalance"
            readonly>
          <label class="form-label">Available Balance</label>
        </div>
      </div>

      <div class="row" *ngIf="isShowFormDetails">
        <div class="col-md-3 mt-2">
          <div class="form-floating">
            <input type="text" class="form-control textCap" placeholder="commodity Name" formControlName="commodityName"
              readonly>
            <!-- <select type="text" class="form-select" formControlName="commodityName">
              <option value="" selected hidden>Select Commodity Name</option>
              <option *ngFor="let com of commodityNames" value="{{com}}">{{com}}</option>
            </select> -->
            <label class="form-label">Commodity Name</label>
          </div>
          <span *ngIf="deliveryFormErrors.commodity" class="text-danger">{{deliveryFormErrors.commodity}}</span>
        </div>

        <div class="col-md-3 mt-2">
          <div class="form-floating">
            <input type="text" class="form-control" placeholder="Package Type" formControlName="packageType"
              readonly>
            <label class="form-label">Package Type</label>
          </div>
        </div>

        <div class="col-md-3 mt-2">
          <div class="form-floating">
            <input type="text" class="form-control" placeholder="Bags of" formControlName="quantity"
              (input)="checkBagsCount($event)">
            <label class="form-label">Quantity of<span style="color:red;">*</span></label>
          </div>
          <span *ngIf="deliveryFormErrors.quantity" class="text-danger">{{deliveryFormErrors.quantity}}</span>
        </div>
        <div class="col-md-3 mt-2">
          <div class="form-floating">
            <input type="text" class="form-control" placeholder="Weight In (Kg)" formControlName="weight">
            <label class="form-label">Weight In (Kg) <span style="color:red;">*</span></label>
          </div>
          <span *ngIf="deliveryFormErrors.weight" class="text-danger">{{deliveryFormErrors.weight}}</span>
        </div>

        <!-- <div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control textCap" placeholder="Warehouse Receipt No"
                 formControlName="warehouseReceiptNo">
          <label>Warehouse Receipt No<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="deliveryFormErrors.warehouseReceiptNo"
              class="text-danger">{{deliveryFormErrors.warehouseReceiptNo}}</span>
      </div> -->

        <div class="col-md-3 mt-2">
          <div class="form-floating">
            <input type="date" class="form-control" placeholder="Date Of Deposit" formControlName="date" (input)="showCalculation()">
            <label class="form-label">Date Of Deposit<span style="color:red;">*</span></label>
          </div>
          <span *ngIf="deliveryFormErrors.date" class="text-danger">{{deliveryFormErrors.date}}</span>
        </div>
        <div class="col-md-3 mt-2">
          <div class="form-floating">
            <input type="text" class="form-control" placeholder="Deposited by" formControlName="depositorName">
            <label class="form-label">Deposited by<span style="color:red;">*</span></label>
          </div>
          <span *ngIf="deliveryFormErrors.depositorName" class="text-danger">{{deliveryFormErrors.depositorName}}</span>
        </div>
        <div class="col-md-3 mt-2">
          <div class="form-floating">
            <input type="text" class="form-control" placeholder="IRM Receipt No" formControlName="irmReceiptNo">
            <label>IRM Receipt No<span style="color:red;">*</span></label>
          </div>
          <span *ngIf="deliveryFormErrors.irmReceiptNo" class="text-danger">{{deliveryFormErrors.irmReceiptNo}}</span>
        </div>

        <div class="col-md-3 mt-2">
          <div class="form-floating">
            <input type="datetime-local" class="form-control" placeholder="Date Of Deposit"
              formControlName="deliveryDate" (input)="showCalculation()">
            <label class="form-label">Date Of Delivery<span style="color:red;">*</span></label>
          </div>
          <span *ngIf="deliveryFormErrors.deliveryDate" class="text-danger">{{deliveryFormErrors.deliveryDate}}</span>
        </div>

        <div class="col-lg-3  col-md-3 mt-2">
          <div class="form-floating">
            <input type="text" class="form-control" placeholder="Vehicle No" formControlName="vehicleNo">
            <label class="">Vehicle No<span style="color:red;">*</span></label>
          </div>
          <span *ngIf="deliveryFormErrors.vehicleNo" class="text-danger">{{deliveryFormErrors.vehicleNo}}</span>
        </div>

        <div class="col-lg-3  col-md-3 mt-2">
          <div class="form-floating">
            <input type="text" readonly class="form-control" placeholder="Toll Gate Amount" formControlName="amount">
            <label>Toll Gate Amount</label>
          </div>
        </div>

        <div class="col-lg-3  col-md-3 mt-2">
          <div class="form-floating">
            <input type="text" readonly class="form-control" placeholder="Total Payable Amount"
              formControlName="totalCharges">
            <label>Total Payable Amount <span *ngIf="this.showTaxValue">(Inc Tax)</span> <span style="color:red;">*</span></label>
          </div>
          <span *ngIf="deliveryFormErrors.totalCharges" class="text-danger">{{deliveryFormErrors.totalCharges}}</span>
        </div>

      </div>
      <!-- <hr style="width:100%; text-align:left;  border-width:3px; border-color: black;">
    <div class="row mt-2">
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Enter godown number" formControlName="godownNo">
          <label>Godown No<span class="text-danger">*</span></label>
        </div>
        <span *ngIf="deliveryFormErrors.godownNo" class="text-danger">{{deliveryFormErrors.godownNo}}</span>
      </div>
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Enter stack no" formControlName="stackNo">
          <label>Stack No<span class="text-danger">*</span></label>
        </div>
        <span *ngIf="deliveryFormErrors.stackNo" class="text-danger">{{deliveryFormErrors.stackNo}}</span>
      </div>
    </div> -->
    </div>
    <div class="row justify-content-center mt-4 mb-5 ml-2 mr-2" *ngIf="isShowCalculation">
      <button type="submit" class="btn btn-primary col-md-2" (click)="formValidation(deliveryForm)">Charge
        Calculation</button>
    </div>

    <div class="row justify-content-center mt-4 mb-5 ml-2 mr-2" *ngIf="isShowSave">
      <button type="submit" class="btn btn-success col-md-1" (click)="formValidation(deliveryForm)">Save</button>
    </div>
  </form>
</div>

<ngx-spinner>
  <div id="page">
    <div id="container">
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div class="fw-bolder" id="h3"><img src="../assets/img/AFTC logo.png" class="logo" alt="Sample image"></div>
    </div>
  </div>
</ngx-spinner>