<div class="container-fluid">
  <div class="card ms-3 border border-dark">
    <div class="row ms-3">
      <div class="row">
        <h5 class="mt-2"><b>GSTIN: 33AABCT1101F1Z7</b></h5>
      </div>

      <div class="row">
        <div class="col-2">
          <img src="../../../../assets/img/AFTC%20logo.png" class="alt" style="width: 130px;height: 130px;">
        </div>
        <div class="col-10 mt-5">
          <div class="row">
            <div class="col">
              <h3 class="text-center" style="font-family: 'Guest Check', cursive;"><b>AGROFOOD TRADE CENTRE</b></h3>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <h5 class="text-center">Sikkandarchavadi, Alanganallur Road, Madurai-625 018. 0452-2660669</h5>
            </div>
          </div>
        </div>
      </div>

      <h5 class="text-center"><b>DELIVERY ORDER - COLD STORAGE</b></h5>
      <div class="row mt-2">
        <div class="col-2">
          <p class="text-left">Delivery Order No :</p>
        </div>
        <div class="col-3">
          <p class="text-center"><b>{{getDetails?.deliverOrderNo}}</b></p>
        </div>
        <div class="col-3"></div>
        <div class="col-3">
          <p class="text-right">Date: <b>{{currentDate}}</b></p>
        </div>
      </div>

      <div class="row ">
        <div class="col-2">
          <p class="">Please deliver</p>
        </div>
        <div class="col-1">
          <p class="underline text-center"><b>{{getDetails?.quantity}}</b></p>
        </div>
        <div class="col-1">
          <p class=""> Bags of</p>
        </div>
        <div class="col-3">
          <p class="underline text-center"><b>{{getDetails?.commodityName}}</b></p>
        </div>
        <div class="col-2" style="margin-right: -45px">
          <p class="">weight</p>
        </div>
        <div class="col-1">
          <p class="underline text-center"><b>{{getDetails?.weight}}</b></p>
        </div>
        <div class="col-2">
          <p class="">M.Tons covered</p>
        </div>
      </div>

      <div class="row ">
        <div class="col-4" style="margin-right: -45px">
          <p class="">under Warehouse Receipt No. CSU</p>
        </div>
        <div class="col-3">
          <p class="underline text-center"><b>{{getDetails?.warehouseReceiptNo}}</b></p>
        </div>
        <div class="col-1">
          <p class="">Dated</p>
        </div>
        <div class="col-2">
          <p class="underline text-center"><b>{{getDetails?.deliveryDate | date : 'dd-MM-yyyy' }} </b></p>
        </div>
        <div class="col-2">
          <p class="">Deposited by</p>
        </div>
      </div>

      <div class="row ">
        <div class="col-1">
          <p class="">M/s </p>
        </div>
        <div class="col-11">
          <p class="underline"><b>{{getDetails?.depositorName}}</b></p>
        </div>
      </div>

      <div class="row ">
        <div class="col-2">
          <p>IRM Receipt No </p>
        </div>
        <div class="col-3">
          <p class="underline text-center"><b>{{getDetails?.irmReceiptNo}}</b></p>
        </div>
        <div class="col-1">
          <p class="">Date</p>
        </div>
        <div class="col-2">
          <p class="underline text-center"><b>{{currentDate}} </b></p>
        </div>
        <div class="col-2" style="margin-right: -40px">
          <p class="">Vehicle No:</p>
        </div>
        <div class="col-2">
          <p class="underline text-center"><b>{{getDetails?.vehicleNo}}</b></p>
        </div>
      </div>

      <div class="row mt-5 text-end">
        <div class="col-11">
          <span class="text-end"><b>Signature of the Receiver/</b></span><br>
          <span class="text-end"><b>Authorized Representative</b></span>
        </div>
      </div>
    </div>
    <hr class="border border-dark">

    <div class="row ms-3">
      <div class="row">
        <h5 class="text-center"><b>For Yard Use</b></h5>
      </div>

      <div class="row">
        <h6 class="text-center"><b>1. At Office</b></h6>
      </div>

      <div class="row ">
        <div class="col-3">
          <p class="">Storage Charges Invoice No. </p>
        </div>
        <div class="col-3">
          <p class="underline"><b>&nbsp;</b></p>
        </div>
        <div class="col-1" style="margin-right: -30px">
          <p class="">Date</p>
        </div>
        <div class="col-2">
          <p class="underline"><b>&nbsp;</b></p>
        </div>
        <div class="col-2" style="margin-right: -40px">
          <p class="">Amount As</p>
        </div>
        <div class="col-1">
          <p class="underline"><b>&nbsp;</b></p>
        </div>
        <div class="row mt-5">
          <div class="col-1"></div>
          <div class="col-3">
            <p class=""><b>Accountant</b></p>
          </div>
          <div class="col-5">
            <p class="text-end"><b>Manager</b></p>
          </div>
        </div>
      </div>
    </div>
    <hr class="border border-dark">

    <div class="row ms-3">
      <div class="row">
        <h6 class="text-center"><b>2. At Cold Storage</b></h6>
      </div>
      <h6 class="">To Cold Storage Assistant:</h6>

      <div class="row ">
        <div class="col-2">
          <p class="">Please deliver</p>
        </div>
        <div class="col-1">
          <p class="underline text-center"><b>{{getDetails?.quantity}}</b></p>
        </div>
        <div class="col-1">
          <p class=""> Bags of</p>
        </div>
        <div class="col-2">
          <p class="underline text-center"><b>{{getDetails?.commodityName}}</b></p>
        </div>
        <div class="col-2" style="margin-right: -30px">
          <p class="">weight</p>
        </div>
        <div class="col-2">
          <p class="underline text-center"><b>{{getDetails?.weight}}</b></p>
        </div>
        <div class="col-2">
          <p class="">M. Tons from</p>
        </div>
      </div>

      <div class="row">
        <div class="col-2">
          <p class="text-left">Godown No</p>
        </div>
        <div class="col-2">
          <p class="underline text-center"><b>{{getDetails?.godownNo}}</b></p>
        </div>
        <div class="col-2">
          <p class="text-right">Stack No</p>
        </div>
        <div class="col-2">
          <p class="underline text-center"><b>{{getDetails?.stackNo}}</b></p>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <img *ngIf="getDetails?.billPeriodType == 'Day wise'" src="assets/img/day%20wise.png" style="height: 140px">
          <img *ngIf="getDetails?.billPeriodType == 'Month wise'" src="assets/img/month%20wise.png"
               style="height: 140px">
        </div>
        <div class="col-7 ms-4 mt-5 text-end">
          <p class="text-end mt-5"><b>Quality Control Manager</b></p>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-2" style="margin-right: -25px">
          <p class="">Delivered</p>
        </div>
        <div class="col-1">
          <p class="text-decoration-underline"><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
          </p>
        </div>
        <div class="col-1">
          <p class=""> Bags of</p>
        </div>
        <div class="col-3">
          <p class="text-decoration-underline"><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
          </p>
        </div>
        <div class="col-2" style="margin-right: -30px">
          <p class="">weight</p>
        </div>
        <div class="col-2">
          <p class="text-decoration-underline"><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
          </p>
        </div>
        <div class="col-1">
          <p class="">from</p>
        </div>
      </div>

      <div class="row ">
        <div class="col-2" style="margin-right: -25px">
          <p class="">Godown No</p>
        </div>
        <div class="col-2">
          <p class="text-decoration-underline"><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
          </p>
        </div>
        <div class="col-2" style="margin-right: -30px">
          <p class="">Stack No</p>
        </div>
        <div class="col-2">
          <p class="text-decoration-underline"><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
          </p>
        </div>
        <div class="col-4">
          <p class=""><b>Entered in:</b> Depositor's Register</p>
        </div>
      </div>

      <div class="row ">
        <div class="col-3">
          <p class="">Page No........... and Stock Register Page No.</p>
        </div>
        <div class="col-3">
          <p class="text-decoration-underline"><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
          </p>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-1"></div>
        <div class="col-3">
          <p class="mb-0"><b>Cold Storage Supervisor</b></p>
        </div>
        <div class="col-5">
          <p class="text-end mb-0"><b>Godown Assistant</b></p>
        </div>
      </div>
    </div>

    <hr class="border border-dark">

    <div class="row ms-3">
      <h6 class="">3. Acknowledgement:</h6>
      <div class="row ">
        <div class="col-1">
          <p class="">Received </p>
        </div>
        <div class="col-1">
          <p class="underline text-center"><b>{{getDetails?.quantity}}</b></p>
        </div>
        <div class="col-1">
          <p class=""> Bags of</p>
        </div>
        <div class="col-3">
          <p class="underline text-center"><b>{{getDetails?.commodityName}}</b></p>
        </div>
        <div class="col-2" style="margin-right: -45px">
          <p class="">weight</p>
        </div>
        <div class="col-1">
          <p class="underline text-center"><b>{{getDetails?.weight}}</b></p>
        </div>
        <div class="col-3">
          <p class="">M.Tons in good condition.</p>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-4"></div>
        <div class="col-7 ms-4 text-end">
          <span class="text-end"><b>Signature of the Receiver/</b></span><br>
          <span class="text-end"><b>Authorized Representative</b></span>
        </div>
      </div>

    </div>
  </div>
</div>
