import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from "@angular/common";
import QRCode from 'qrcode-generator';
import { ColdStorageDeliveryDetails } from "@components/aftc-digitalization/aftc-digitalization-models/coldStorageDeliveryDetails";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

@Component({
    selector: "app-delivery-request-view",
    templateUrl: "./delivery-request-view.component.html",
    styleUrls: ["./delivery-request-view.component.scss"]
})
export class DeliveryRequestViewComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    searchText;
    getAllDeliveryRequestDetails: ColdStorageDeliveryDetails[] = [];
    getDeliveryDetails: ColdStorageDeliveryDetails[] = [];
    depositorNameDetails: string[] = [];
    CommodityNameDetails: string[] = [];
    deliveryRequestDateDetails: string[] = [];
    currentStatusDetails: string[] = [];
    depositorName: string = "";
    commodityName: string = "";
    deliveryRequestDate: string = "";
    currentStatus: string = "";
    qrCodeShowPopup: boolean = false;
    qrCodeImage: string = "";
    qrCodeDetails: ColdStorageDeliveryDetails;
    addDeliveryPopup: boolean = false;
    department: string = "";
    irmNumberEntryShowPopup: boolean = false;
    irmNumber: string = "";
    stockDeliveryId: number = 0;
    isTouched: boolean = false;
    constructor(private apiServices: ApiService, private router: Router,
        private toaster: ToastrService, private spinner: NgxSpinnerService, private datePipe: DatePipe) {
    }

    ngOnInit(): void {
        this.getAllDeliveryDetails();
        this.department = localStorage.getItem('department');
    }

    //All Delivery Details get function
    getAllDeliveryDetails() {
        this.spinner.show();
        this.apiServices.getColdStorageDeliveryRequest()
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                console.log(data);
                this.spinner.hide();
                this.getAllDeliveryRequestDetails = data.sort((a, b) => b.stockDeliveryId - a.stockDeliveryId);
                this.getDeliveryDetails = this.getAllDeliveryRequestDetails;
                this.depositorNameDetails = [...new Set(this.getDeliveryDetails.map(item => item.depositorName))];
                this.CommodityNameDetails = [...new Set(this.getDeliveryDetails.map(item => item.commodityName))];
                this.currentStatusDetails = [...new Set(this.getDeliveryDetails.map(item => item.currentStage))];
                this.deliveryRequestDateDetails = [...new Set(this.getDeliveryDetails.map(item => this.datePipe.transform(item.createdAt, 'dd/MM/yyyy')))];
            }, (error: any) => {
                this.spinner.hide();
                console.log("Something Went wrong");
                this.toaster.error(error.error.msg || "Something went wrong, Please try again later....")
            });
    }




    //Filter select value set function
    updateDetails(event: any, filterType: string) {
        const value = event.target.value;

        switch (filterType) {
            case 'name':
                this.depositorName = value !== 'Select Depositor Name' ? value : 'all';
                break;
            case 'commodity':
                this.commodityName = value !== 'Select Commodity Name' ? value : 'all';
                break;
            case 'date':
                this.deliveryRequestDate = value !== 'Select Delivery Request Date' ? value : 'all';
                break;
            case 'date':
                this.currentStatus = value !== 'Select Delivery Status' ? value : 'all';
                break;

            default:
                break;
        }

        this.applyFilter();
    }

    //Apply filter function
    applyFilter() {
        // Start with the full dataset
        let filteredData = [...this.getAllDeliveryRequestDetails];

        // Apply depositor name filter
        if (this.depositorName && this.depositorName !== 'all') {
            filteredData = filteredData.filter(item => item.depositorName === this.depositorName);
        }

        // Update available options for commodities and dates based on depositor filter
        this.CommodityNameDetails = [...new Set(filteredData.map(item => item.commodityName))];
        this.deliveryRequestDateDetails = [...new Set(filteredData.map(item =>
            this.datePipe.transform(item.createdAt, 'dd/MM/yyyy')
        ))];
        this.currentStatusDetails = [...new Set(filteredData.map(item => item.currentStage))]

        // Apply commodity filter
        if (this.commodityName && this.commodityName !== 'all') {
            filteredData = filteredData.filter(item => item.commodityName === this.commodityName);
        }

        // Update available options for dates based on commodity filter
        this.deliveryRequestDateDetails = [...new Set(filteredData.map(item =>
            this.datePipe.transform(item.createdAt, 'dd/MM/yyyy')
        ))];
        this.currentStatusDetails = [...new Set(filteredData.map(item => item.currentStage))]

        // Apply delivery date filter
        if (this.deliveryRequestDate && this.deliveryRequestDate !== 'all') {
            filteredData = filteredData.filter(item =>
                this.datePipe.transform(item.createdAt, 'dd/MM/yyyy') === this.deliveryRequestDate
            );
        }

        if (this.currentStatus && this.currentStatus !== 'all') {
            filteredData = filteredData.filter(item => item.currentStage === this.currentStatus);
        }


        this.getDeliveryDetails = filteredData;
    }

    //Formatted by current status function
    formatStatus(status: string): string {
        switch (status) {
            case 'REQUESTED':
                return 'Requested';
            case 'ADMIN_APPROVED':
                return 'Admin Approved';
            case 'QCM_APPROVED':
                return 'QCM Approved';
            case 'FINAL_APPROVED':
                return 'Final Approved';
            default:
                return status;
        }
    }

    //Current status update function
    deliveryStatusUpdate(status: string, id: number, stockId: number) {
        this.stockDeliveryId = stockId;
        if (status === 'ADMIN_APPROVED') {
            this.irmNumber = "";
            this.irmNumberEntryShowPopup = true;
        } else {
            this.spinner.show();
            this.irmNumberEntryShowPopup = false;
            const jsonDate = {
                stockDeliveryId: this.stockDeliveryId,
                currentStage: status,
                irmReceiptNo: "",
            }
            this.apiServices.updateIrmNumberBasedDeliveryStatusUpdate(jsonDate)
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    this.spinner.hide();
                    this.toaster.success("Delivery Status Updated Successfully");
                    this.getAllDeliveryDetails();
                }, (error: any) => {
                    this.spinner.hide();
                    this.toaster.error(error.message || "Something went wrong, please try again later...");
                })
        }
    }

    // Front office admin entry based admin approve update function
    submitIrmNumber() {
        if (!this.irmNumber.trim()) {
            this.isTouched = true;
            return;
        } else {
            this.spinner.show();
            const jsonDate = {
                stockDeliveryId: this.stockDeliveryId,
                irmReceiptNo: this.irmNumber,
                currentStage: 'ADMIN_APPROVED',
            }
            console.log(jsonDate);
            this.apiServices.updateIrmNumberBasedDeliveryStatusUpdate(jsonDate)
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    this.spinner.hide();
                    this.toaster.success("Delivery Status Updated Successfully");
                    this.getAllDeliveryDetails();
                    this.irmNumberEntryShowPopup = false;
                }, (error: any) => {
                    this.spinner.hide();
                    this.toaster.error(error.message || "Something went wrong, please try again later...");
                })
        }
    }


    //Clear the filter function value
    resetReviewFilter() {
        this.searchText = '';
        this.depositorName = '';
        this.commodityName = '';
        this.deliveryRequestDate = '';

        this.getDeliveryDetails = [...this.getAllDeliveryRequestDetails];
        this.depositorNameDetails = [...new Set(this.getDeliveryDetails.map(item => item.depositorName))];
        this.CommodityNameDetails = [...new Set(this.getDeliveryDetails.map(item => item.commodityName))];
        this.deliveryRequestDateDetails = [...new Set(this.getDeliveryDetails.map(item =>
            this.datePipe.transform(item.createdAt, 'dd/MM/yyyy')
        ))];
    }

    // Open QR code popup & qr code generator function
    showQRCode(details: ColdStorageDeliveryDetails) {
        this.qrCodeShowPopup = true;
        this.qrCodeDetails = details;
        console.log(details)
        const qr = QRCode(0, 'M');
        qr.addData(String(details.deliverOrderNo));
        qr.make();
        this.qrCodeImage = qr.createDataURL()
    }

    // Excel sheet download function
    downloadExcel() {
        if (!this.getDeliveryDetails || this.getDeliveryDetails.length === 0) {
            this.toaster.warning('No data available to download!');
            return;
        }

        // Map data for Excel
        const exportData = this.getDeliveryDetails.map((stock, index) => ({
            'S.No': index + 1,
            'Depositor Name': stock.depositorName || '-',
            'Commodity': stock.commodityName || '-',
            'No of Quantity': stock.quantity || '-',
            'Weight': stock.weight || '-',
            'Date': stock.createdAt
                ? new Date(stock.createdAt).toLocaleDateString('en-GB')
                : '-',
            'Service Type': stock.serviceType || '-',
            'Current Status': stock.currentStage || '-',
        }));

        // Create worksheet
        const worksheet = XLSX.utils.json_to_sheet(exportData);

        // Create workbook
        const workbook: XLSX.WorkBook = {
            Sheets: { 'Filtered Data': worksheet },
            SheetNames: ['Filtered Data'],
        };

        // Generate Excel file
        const excelBuffer: any = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        // Save the file
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        const fileName = `Filtered_Data_${new Date().toISOString().slice(0, 10)}.xlsx`;
        saveAs(blob, fileName);
    }

    print(id: number) {
        sessionStorage.setItem("depositorId", String(id));
        this.router.navigate(['/cold-storage-delivery-case-print']);
    }
}
