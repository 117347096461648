import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from "@angular/common";
import QRCode from 'qrcode-generator';
import { ColdStorageDepositDetails } from "@components/aftc-digitalization/aftc-digitalization-models/coldStorageDepositDetails";
import { ColdStorageBuildingDetails, ColdStorageRecord } from "@/model/depotDetails/depotDetails";
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { ColdStorageCreditedDetails } from "@components/aftc-digitalization/aftc-digitalization-models/cold-storage-credited-details";
import { CommodityDetails } from "@/model/commodityDetails/CommodityDetails";
import { element } from "protractor";

@Component({
    selector: "app-deposit-request-view",
    templateUrl: "./deposit-request-view.component.html",
    styleUrls: ["./deposit-request-view.component.scss"]
})
export class DepositRequestViewComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    searchText;
    getAllDepositRequestDetails: ColdStorageDepositDetails[] = [];
    getDepositDetails: ColdStorageDepositDetails[] = [];
    depositorNameDetails: string[] = [];
    CommodityNameDetails: string[] = [];
    depositRequestDateDetails: string[] = [];
    currentStatusDetails: string[] = [];
    depositorName: string = "";
    commodityName: string = "";
    depositRequestDate: string = "";
    currentStatus: string = "";
    qrCodeShowPopup: boolean = false;
    qrCodeImage: string = "";
    qrCodeDetails: ColdStorageDepositDetails;
    addDepositorPopup: boolean = false;
    coldStorageList: ColdStorageBuildingDetails[] = [];
    openSpaceColdStorageList: ColdStorageBuildingDetails[] = [];
    getAllColdStorageList: ColdStorageRecord[] = [];
    buildingDetails: ColdStorageBuildingDetails[] = [];
    currentMemberSpaceDetails: ColdStorageDepositDetails;
    totalNumberOfBags: number = 0;
    totalNumberOfWights: number = 0
    department: string = "";
    fullFloorList: any[] = [];
    rentingTypeList: any[] = [];
    irmNumberEntryShowPopup: boolean = false;
    irmNumber: string = "";
    stockDepositId: number = 0;
    isTouched: boolean = false;
    balanceDetails: ColdStorageCreditedDetails;
    depotId: number = 0;
    currentAvailableBalance: number = 0;
    currentWeight: string = "";
    currentQuantity: number = 0;
    isShowCalculation: boolean = true;
    isShowSave: boolean = false;
    currentTotalAmount: number = 0;
    allCurrentQuantity: number = 0;
    allCurrentWeight: any;
    isOpenSpace: boolean = false;
    dynamicSpaceLists: any[] = [];
    dynamicBuildingDetails: ColdStorageRecord[] = [];
    commodityDetails: CommodityDetails[] = [];
    constructor(private apiServices: ApiService, private router: Router,
        private toaster: ToastrService, private spinner: NgxSpinnerService, private datePipe: DatePipe) {
    }

    maintenanceForm = new UntypedFormGroup({
        maintenanceList: new UntypedFormArray([])
    });

    depositForm = new UntypedFormGroup({
        stockDepositId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        depositDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        depositorName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        invoiceNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        billDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        commodityName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        quantity: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        weight: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        typeOfVehicle: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        wheels: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        vehicleNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        amount: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        createdAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        updatedAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        createdBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        updatedBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        currentStage: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        irmReceiptNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        storageType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        serviceType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        billPeriodType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        packageType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        commodityType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        cgst: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        sgst: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        roundedOff: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        totalAmount: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        placeOfOrigin: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        cgstAmount: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        sgstAmount: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        handlingCharge: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        bearingNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        bearingDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        depositMemberLoginId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        depotId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        depositRequestNumber: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        depotDetails: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    });


    public maintenanceFormError = {
        depositorName: '',
        commodityName: '',
        quantity: '',
        weight: '',
        floorNumber: '',
        spaceNumber: '',
        rentingType: '',
    }

    ngOnInit(): void {
        this.getAllDepositDetails();
        this.getCommodityDetails();
        this.department = localStorage.getItem('department');
    }

    // Getter for FormArray
    get maintenanceList(): UntypedFormArray {
        return this.maintenanceForm.get('maintenanceList') as UntypedFormArray;
    }

    // All deposit  request details get function
    getAllDepositDetails() {
        this.spinner.show();
        this.apiServices.getColdStorageDepositorDetails()
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                console.log(data);
                this.spinner.hide();
                this.getAllDepositRequestDetails = data.sort((a, b) => b.stockDepositId - a.stockDepositId);
                this.getDepositDetails = this.getAllDepositRequestDetails;
                this.depositorNameDetails = [...new Set(this.getDepositDetails.map(item => item.depositorName))];
                this.CommodityNameDetails = [...new Set(this.getDepositDetails.map(item => item.commodityName))];
                this.currentStatusDetails = [...new Set(this.getDepositDetails.map(item => item.currentStage))];
                this.depositRequestDateDetails = [...new Set(this.getDepositDetails.map(item => this.datePipe.transform(item.createdAt, 'dd/MM/yyyy')))];
            }, (error: any) => {
                this.spinner.hide();
                console.log("Something Went wrong");
                this.toaster.error(error.error.msg || "Something went wrong, Please try again later....")
            });
    }

    // onSubmit() {
    //     this.formValidation(form);
    // }


    // Space allocation validation function
    formValidation(from: any) {
        this.maintenanceFormError = {
            depositorName: '',
            commodityName: '',
            quantity: '',
            weight: '',
            floorNumber: '',
            spaceNumber: '',
            rentingType: '',
        };

        let hasError = false;
        this.maintenanceList.controls.forEach((formGroup: UntypedFormGroup) => {
            if (!formGroup.controls.depositorName.valid) {
                this.maintenanceFormError.depositorName = "*Depositor Name is required";
                hasError = true;
            }
            if (!formGroup.controls.commodityName.valid) {
                this.maintenanceFormError.commodityName = "*Commodity Name is required";
                hasError = true;
            }
            if (!formGroup.controls.quantity.valid) {
                this.maintenanceFormError.quantity = "*No of Quantity is required";
                hasError = true;
            }
            if (!formGroup.controls.weight.valid) {
                this.maintenanceFormError.weight = "*Weight In Kg is required";
                hasError = true;
            }
            if (!formGroup.controls.floorNumber.valid) {
                this.maintenanceFormError.floorNumber = "*Floor Number is required";
                hasError = true;
            }
            if (!formGroup.controls.spaceNumber.valid) {
                this.maintenanceFormError.spaceNumber = "*Space Number is required";
                hasError = true;
            }
            if (!formGroup.controls.rentingType.valid) {
                this.maintenanceFormError.rentingType = "*Renting Type is required";
                hasError = true;
            }
        });
        // const totalEnteredBags = this.maintenanceList.controls.reduce((sum, group) => {
        //     return sum + (+group.get('quantity')?.value || 0);
        // }, 0);

        // if (totalEnteredBags > this.totalNumberOfBags) {
        //     this.maintenanceFormError.quantity = `Total bags (${totalEnteredBags}) exceed the limit of ${this.totalNumberOfBags}.`;
        //     hasError = true;
        // } else if (totalEnteredBags < this.totalNumberOfBags) {
        //     this.maintenanceFormError.quantity = `Total bags (${totalEnteredBags}) must match the required total of ${this.totalNumberOfBags}.`;
        //     hasError = true;
        // }
        if (!hasError) {
            if (this.isShowSave) {
                if (this.depositForm.get('totalAmount').value <= this.currentAvailableBalance) {
                    if (this.totalNumberOfBags != this.allCurrentQuantity) {
                        this.updateColdStorageDepositDetails();
                    } else if (this.totalNumberOfBags == this.allCurrentQuantity) {
                        this.saveDepositMaintenance();
                    }
                } else {
                    this.toaster.error("Insufficient available balance");
                }
            } else if (this.isShowCalculation) {
                this.saveColdStorageHandlingCharge();
            }
        }
    }


    // Add more main form function
    addNewMaintenanceForm(deposit: any) {
        this.allCurrentQuantity += (deposit?.quantity);
        this.allCurrentWeight += (deposit?.weight);
        const createdAtValue = Math.floor(new Date().getTime() / 1000) * 1000;
        const newFormGroup = new UntypedFormGroup({
            stockMaintainId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
            depositorName: new UntypedFormControl(deposit?.depositorName, [Validators.required, Validators.nullValidator]),
            commodityName: new UntypedFormControl(deposit?.commodityName, [Validators.required, Validators.nullValidator]),
            maintenanceType: new UntypedFormControl("DEPOSIT", [Validators.required, Validators.nullValidator]),
            date: new UntypedFormControl(deposit?.billDate, [Validators.required, Validators.nullValidator]),
            depositApplicationNumber: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
            quantity: new UntypedFormControl(deposit?.quantity, [Validators.required, Validators.nullValidator]),
            weight: new UntypedFormControl(deposit?.weight, [Validators.required, Validators.nullValidator]),
            deliveryNumber: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
            outWeight: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
            closingPackage: new UntypedFormControl(deposit?.quantity, [Validators.required, Validators.nullValidator]),
            closingWeight: new UntypedFormControl(deposit?.weight, [Validators.required, Validators.nullValidator]),
            createdAt: new UntypedFormControl(createdAtValue, [Validators.required, Validators.nullValidator]),
            createdBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
            comments: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
            floorNumber: new UntypedFormControl({ value: "", disabled: true }, [Validators.required, Validators.nullValidator]),
            spaceNumber: new UntypedFormControl({ value: "", disabled: true }, [Validators.required, Validators.nullValidator]),
            depotId: new UntypedFormControl(deposit?.depotId, [Validators.required, Validators.nullValidator]),
            depositRequestNumber: new UntypedFormControl(deposit?.depositRequestNumber, [Validators.required, Validators.nullValidator]),
            rentingType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
            commodityId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
            spaceType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
            deliveryRequestNumber: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
            depositorId: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
            packageType: new UntypedFormControl(deposit?.packageType, [Validators.required, Validators.nullValidator]),
        });

        this.maintenanceList.push(newFormGroup);
        this.isShowCalculation = true;
        this.isShowSave = false;
    }


    // Remove form details function
    removeForm(index: number) {
        if (index < 1) {
            this.toaster.error("Cannot remove the last record");
            return;
        } else {
            this.maintenanceList.removeAt(index);
            this.updateTotalBagsAndWeights();
            this.isShowCalculation = true;
            this.isShowSave = false;
        }
    }

    // Helper function to recalculate total bags and weights
    updateTotalBagsAndWeights() {
        const totalBags = this.maintenanceList.controls.reduce((sum, group) => {
            return sum + (+group.get('quantity')?.value || 0);
        }, 0);

        const totalWeight = this.maintenanceList.controls.reduce((sum, group) => {
            return sum + (+group.get('weight')?.value || 0);
        }, 0);

        this.allCurrentQuantity = totalBags;
        this.allCurrentWeight = totalWeight.toFixed(2);
    }

    // Current select filter value set function
    updateDetails(event: any, filterType: string) {
        const value = event.target.value;

        switch (filterType) {
            case 'name':
                this.depositorName = value !== 'Select Depositor Name' ? value : 'all';
                break;
            case 'commodity':
                this.commodityName = value !== 'Select Commodity Name' ? value : 'all';
                break;
            case 'date':
                this.depositRequestDate = value !== 'Select Deposit Request Date' ? value : 'all';
                break;
            case 'date':
                this.currentStatus = value !== 'Select Deposi Status' ? value : 'all';
                break;

            default:
                break;
        }

        this.applyFilter();
    }

    // Filter function was select value based details set
    applyFilter() {
        // Start with the full dataset
        let filteredData = [...this.getAllDepositRequestDetails];

        // Apply depositor name filter
        if (this.depositorName && this.depositorName !== 'all') {
            filteredData = filteredData.filter(item => item.depositorName === this.depositorName);
        }

        // Update available options for commodities and dates based on depositor filter
        this.CommodityNameDetails = [...new Set(filteredData.map(item => item.commodityName))];
        this.depositRequestDateDetails = [...new Set(filteredData.map(item =>
            this.datePipe.transform(item.createdAt, 'dd/MM/yyyy')
        ))];
        this.currentStatusDetails = [...new Set(filteredData.map(item => item.currentStage))]

        // Apply commodity filter
        if (this.commodityName && this.commodityName !== 'all') {
            filteredData = filteredData.filter(item => item.commodityName === this.commodityName);
        }

        // Update available options for dates based on commodity filter
        this.depositRequestDateDetails = [...new Set(filteredData.map(item =>
            this.datePipe.transform(item.createdAt, 'dd/MM/yyyy')
        ))];
        this.currentStatusDetails = [...new Set(filteredData.map(item => item.currentStage))]

        // Apply delivery date filter
        if (this.depositRequestDate && this.depositRequestDate !== 'all') {
            filteredData = filteredData.filter(item =>
                this.datePipe.transform(item.createdAt, 'dd/MM/yyyy') === this.depositRequestDate
            );
        }

        if (this.currentStatus && this.currentStatus !== 'all') {
            filteredData = filteredData.filter(item => item.currentStage === this.currentStatus);
        }


        this.getDepositDetails = filteredData;
    }

    // Remove filter function values
    resetReviewFilter() {
        this.searchText = '';
        this.depositorName = '';
        this.commodityName = '';
        this.depositRequestDate = '';

        this.getDepositDetails = [...this.getAllDepositRequestDetails];
        this.depositorNameDetails = [...new Set(this.getDepositDetails.map(item => item.depositorName))];
        this.CommodityNameDetails = [...new Set(this.getDepositDetails.map(item => item.commodityName))];
        this.depositRequestDateDetails = [...new Set(this.getDepositDetails.map(item =>
            this.datePipe.transform(item.createdAt, 'dd/MM/yyyy')
        ))];
    }

    // Open QR code popup & qr code generator function
    showQRCode(details: ColdStorageDepositDetails) {
        this.qrCodeShowPopup = true;
        this.qrCodeDetails = details;
        console.log(details)
        const qr = QRCode(0, 'M');
        qr.addData(String(details?.depositRequestNumber));
        qr.make();
        this.qrCodeImage = qr.createDataURL()
    }


    // Current customer based on space details get function 
    getMemberSpaceDetails(deposit: ColdStorageDepositDetails) {
        this.spinner.show();
        this.totalNumberOfBags = 0;
        this.totalNumberOfWights = 0;
        this.currentTotalAmount = 0;
        this.currentAvailableBalance = 0;
        this.allCurrentQuantity = 0;
        this.allCurrentWeight = "";
        this.buildingDetails = [];
        this.maintenanceList.clear();
        this.currentMemberSpaceDetails = deposit;
        this.depositForm.setValue(deposit);
        this.depotId = deposit?.depotId;
        this.totalNumberOfBags = this.currentMemberSpaceDetails.quantity;
        this.totalNumberOfWights = this.currentMemberSpaceDetails.weight;
        this.addNewMaintenanceForm(deposit);
        this.getColdStorageAvailableBalance();
        this.currentTotalAmount = deposit?.totalAmount;
        console.log(this.maintenanceList.value);
        this.spinner.show();
        this.apiServices.getColdStorageMemberSpaceId(this.depotId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (data) => {
                    this.spinner.hide();
                    this.addDepositorPopup = true;
                    console.log(data);
                    this.getAllColdStorageList = data;
                    const uniqueRentingType = Array.from(
                        new Set(this.getAllColdStorageList.map((detail: any) => detail.rentingType))
                    );
                    this.rentingTypeList = uniqueRentingType
                    // this.buildingDetails = data.flatMap(item => item.csBuildingDetails);
                    data.forEach(element =>
                        this.buildingDetails.push(...element.csBuildingDetails)
                    )
                    console.log(this.buildingDetails)
                    this.coldStorageList = this.buildingDetails;
                    const uniqueFloorNumbers = Array.from(
                        new Set(this.buildingDetails.map((detail: any) => detail.floorNumber))
                    );
                    this.fullFloorList = uniqueFloorNumbers;
                    if (this.getAllColdStorageList.length <= 0) {
                        this.getColdStorageAvailabilityInfo();
                    }
                },
                (error: any) => {
                    this.spinner.hide();
                    console.error("Something went wrong", error);
                    this.toaster.error(error.error?.msg || "Something went wrong, Please try again later....");
                }
            );
    }


    //Current available space details get function
    getColdStorageAvailabilityInfo() {
        this.apiServices.getColdStorageAvailabilityInfo()
            .pipe(takeUntil(this.destroy$))
            .subscribe(res => {
                console.log(res);
                //@ts-ignore
                this.openSpaceColdStorageList = res.filter(item => item.isBooked === false);
                const values = new Set(this.openSpaceColdStorageList.map(x => x.floorNumber));
                const listOfFloorList = Array.from(values);
                this.fullFloorList.push(...listOfFloorList);
                console.log(this.fullFloorList);
                this.rentingTypeList.push(["OPEN_SPACE"])
                console.log(this.openSpaceColdStorageList);
            }, (error: any) => {
                this.toaster.error(error.error.msg || "Something went wrong , please try again")
            });
    }

    // Get all commodity details
    getCommodityDetails() {
        this.spinner.show();
        this.apiServices.getCommodityDetails()
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                this.spinner.hide();
                this.commodityDetails = data;
            }, (err: any) => {
                this.spinner.hide();
                this.toaster.error(err.error.message || "Commodity details not getting, Please try again later");
            });
    }

    selectRentingType(event: any, index: number) {
        const value = event.target.value;
        const controlGroup = this.maintenanceList.at(index);

        if (value === 'OPEN_SPACE') {
            this.dynamicSpaceLists[index] = this.openSpaceColdStorageList;
            this.fullFloorList = Array.from(new Set(this.openSpaceColdStorageList.map(x => x.floorNumber)));
        } else {
            this.dynamicSpaceLists[index] = this.coldStorageList.filter((item: any) => item.isActive);
            this.fullFloorList = Array.from(new Set(this.coldStorageList.map(x => x.floorNumber)));
        }

        // Enable floorNumber field
        controlGroup.get('floorNumber')?.enable();
        controlGroup.get('spaceNumber')?.disable();
        controlGroup.get('spaceNumber')?.setValue('');
    }

    selectFloorNumber(event: any, index: number) {
        const selectedFloor = event.target.value;
        const rentingType = this.maintenanceList.at(index).get('rentingType')?.value;

        if (rentingType === 'OPEN_SPACE') {
            this.dynamicSpaceLists[index] = this.openSpaceColdStorageList.filter(
                (item: any) => item.floorNumber === selectedFloor
            );
        } else {
            this.dynamicSpaceLists[index] = this.coldStorageList.filter(
                (item: any) => item.floorNumber === selectedFloor
            );
        }

        // Enable spaceNumber field
        this.maintenanceList.at(index).get('spaceNumber')?.enable();
    }


    // In Available space details api call function
    getOpenSpace(event: any) {
        const isChecked = (event.target as HTMLInputElement).checked;
        console.log('Toggle State:', isChecked ? 'Yes' : 'No');

        if (isChecked) {
            this.getColdStorageAvailabilityInfo();
        } else {
            this.coldStorageList = [];
            this.fullFloorList = [];
            this.getMemberSpaceDetails(this.currentMemberSpaceDetails);
        }
    }


    // Update deposit request function
    updateColdStorageDepositDetails() {
        this.spinner.show();
        this.depositForm.patchValue({
            quantity: this.allCurrentQuantity,
            weight: this.allCurrentWeight,
        });
        this.apiServices.updateColdStorageDepositAdmin(this.depositForm.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                console.log(data);
                this.saveDepositMaintenance();
            }, (err: any) => {
                this.spinner.hide();
                this.toaster.error(err.error?.msg || "Something went wrong, Please try again later....");
            });
    }

    // Save deposit maintenance details function

    saveDepositMaintenance() {
        const commodityDetails = this.commodityDetails.find(item => item.commodityName === this.currentMemberSpaceDetails.commodityName
            && item.unit == 'Cold Storage' && item.normalRent !== 0.0);
        this.maintenanceList.controls.forEach((group: UntypedFormGroup) => {
            const floorNumber = group.get('floorNumber')?.value;
            const spaceNumber = group.get('spaceNumber')?.value;
            const fullSpaceNumber = floorNumber + '-' + spaceNumber;
            group.get('spaceNumber')?.setValue(fullSpaceNumber);
            group.get('closingPackage')?.setValue(group.get('quantity')?.value);
            group.get('closingWeight')?.setValue(group.get('weight')?.value);
            group.get('commodityId')?.setValue(commodityDetails.commId);
            group.get('spaceType')?.setValue(group.get('rentingType')?.value);
        });
        console.log("saveDepositMaintenance", this.maintenanceList.value);
        this.spinner.show();
        this.apiServices.saveColdStorageMaintenance(this.maintenanceList.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.spinner.hide();
                this.toaster.success("Deposit Maintenance Requests Saved Successfully");
                this.addDepositorPopup = false;
                this.maintenanceList.reset();
                this.getAllDepositDetails();
            }, (error: any) => {
                this.spinner.hide();
                console.error("Something went wrong", error);
                this.toaster.error(error.error?.msg || "Something went wrong, Please try again later....");
            });
    }

    // Current status format value set function
    formatStatus(status: string): string {
        switch (status) {
            case 'REQUESTED':
                return 'Requested';
            case 'ADMIN_APPROVED':
                return 'Admin Approved';
            case 'QCM_APPROVED':
                return 'QCM Approved';
            case 'FINAL_APPROVED':
                return 'Final Approved';
            default:
                return status;
        }
    }

    //Current bags and wight calculation function
    checkBagsCount(event: any, index: number) {
        const enteredBags = +event.target.value;
        const totalBagsAllowed = this.totalNumberOfBags;
        const cumulativeBags = this.maintenanceList.controls.reduce((sum, group, idx) => {
            return idx === index
                ? sum
                : sum + (+group.get('quantity')?.value || 0);
        }, 0) + enteredBags;

        // if (cumulativeBags > totalBagsAllowed) {
        //     this.maintenanceFormError.quantity = `Total bags (${cumulativeBags}) exceed the limit of ${totalBagsAllowed}.`;
        // } else if (cumulativeBags < totalBagsAllowed) {
        //     this.maintenanceFormError.quantity = `Total bags (${cumulativeBags}) must match the required total of ${totalBagsAllowed}.`;
        // } else {
        //     this.maintenanceFormError.quantity = "";
        // }
        this.isShowCalculation = true;
        this.isShowSave = false;

        const weightPerBag = this.totalNumberOfWights / totalBagsAllowed;
        const calculatedWeight = weightPerBag * enteredBags;
        const currentGroup = this.maintenanceList.at(index) as UntypedFormGroup;
        currentGroup.get('weight')?.setValue(calculatedWeight.toFixed(2));
        this.allCurrentQuantity = cumulativeBags;
        this.allCurrentWeight = this.maintenanceList.controls.reduce((sum, group, idx) => {
            return idx === index
                ? sum
                : sum + (+group.get('weight')?.value);
        }, 0) + calculatedWeight;
    }


    //Current status update function
    depositStatusUpdate(status: string, id: number, stockId: number) {
        this.stockDepositId = stockId;
        if (status === 'ADMIN_APPROVED') {
            this.irmNumber = "";
            this.irmNumberEntryShowPopup = true;
        } else {
            this.spinner.show();
            this.irmNumberEntryShowPopup = false;
            const jsonDate = {
                stockDepositId: this.stockDepositId,
                currentStage: status,
                irmReceiptNo: "",
            }
            this.apiServices.updateIrmNumberBasedStatusUpdate(jsonDate)
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    this.spinner.hide();
                    this.toaster.success("Deposit Status Updated Successfully");
                    this.getAllDepositDetails();
                }, (error: any) => {
                    this.spinner.hide();
                    this.toaster.error(error.message || "Something went wrong, please try again later...");
                })
        }
    }

    // Front office admin entry based admin approve update function
    submitIrmNumber() {
        if (!this.irmNumber.trim()) {
            this.isTouched = true;
            return;
        } else {
            this.spinner.show();
            const jsonDate = {
                stockDepositId: this.stockDepositId,
                irmReceiptNo: this.irmNumber,
                currentStage: 'ADMIN_APPROVED',
            }
            console.log(jsonDate);
            this.apiServices.updateIrmNumberBasedStatusUpdate(jsonDate)
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    this.spinner.hide();
                    this.toaster.success("Deposit Status Updated Successfully");
                    this.getAllDepositDetails();
                    this.irmNumberEntryShowPopup = false;
                }, (error: any) => {
                    this.spinner.hide();
                    this.toaster.error(error.message || "Something went wrong, please try again later...");
                })
        }
    }


    // Depositor available balance details get function
    getColdStorageAvailableBalance() {
        this.apiServices.getColdStorageAvailabilityBalances(this.depotId)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.balanceDetails = data;
                this.currentAvailableBalance = this.balanceDetails?.availableBalance;
            }, (error: any) => {
                this.toaster.error(error.error.message || "Something went wrong, Please try again later");
            })
    }

    //Check quantity value function
    checkQuantityValue(event: any) {
        const quantity = event.target.value;
        if (quantity != this.currentQuantity) {
            this.isShowCalculation = true;
            this.isShowSave = false;
        } else {
            this.isShowCalculation = false;
            this.isShowSave = true;
        }
    }

    //Check weight value function
    checkWightValue(event: any) {
        const wight = event.target.value;
        if (wight != this.currentWeight) {
            this.isShowCalculation = true;
            this.isShowSave = false;
        } else {
            this.isShowCalculation = false;
            this.isShowSave = true;
        }
    }

    //Save depositor handling charges function
    saveColdStorageHandlingCharge() {
        let commodityType: string = "";
        if (this.depositForm.get('commodityName').value === 'Chillies') {
            commodityType = "Chillies";
        } else {
            commodityType = "ExceptChillies"
        }
        this.depositForm.controls['commodityType'].setValue(commodityType)
        const JsonData = {
            commodityType: commodityType,
            packageType: this.depositForm.get('packageType').value,
            quantity: this.allCurrentQuantity,
            weight: this.allCurrentWeight,
            placeOfOrigin: this.depositForm.get('placeOfOrigin').value
        }
        this.currentWeight = JsonData.weight;
        this.currentQuantity = JsonData.quantity;
        this.apiServices.saveColdStorageHandlingCharge(JsonData)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.spinner.hide();
                this.depositForm.controls['cgst'].setValue(data.cgst);
                this.depositForm.controls['sgst'].setValue(data.sgst);
                this.depositForm.controls['cgstAmount'].setValue(data.cgstAmount);
                this.depositForm.controls['sgstAmount'].setValue(data.sgstAmount);
                this.depositForm.controls['roundedOff'].setValue(data.roundedOff);
                this.depositForm.controls['handlingCharge'].setValue(data.handlingCharge);
                this.depositForm.controls['totalAmount'].setValue(data.totalAmount);
                this.currentTotalAmount = data.totalAmount;
                this.isShowCalculation = false;
                this.isShowSave = true;
            }, (error: any) => {
                this.spinner.hide();
                this.toaster.error(error.error.message || "Something went wrong, Please try again later");
            })
    }

    // Excel sheet download function
    downloadExcel() {
        if (!this.getDepositDetails || this.getDepositDetails.length === 0) {
            this.toaster.warning('No data available to download!');
            return;
        }

        // Map data for Excel
        const exportData = this.getDepositDetails.map((stock, index) => ({
            'S.No': index + 1,
            'Depositor Name': stock.depositorName || '-',
            'Commodity Name': stock.commodityName || '-',
            'No of Quantity': stock.quantity || '-',
            'Weight': stock.weight || '-',
            'Date': stock.createdAt
                ? new Date(stock.createdAt).toLocaleDateString('en-GB')
                : '-',
            'Service Type': stock.serviceType || '-',
            'Current Status': stock.currentStage || '-',
        }));

        // Create worksheet
        const worksheet = XLSX.utils.json_to_sheet(exportData);

        // Create workbook
        const workbook: XLSX.WorkBook = {
            Sheets: { 'Filtered Data': worksheet },
            SheetNames: ['Filtered Data'],
        };

        // Generate Excel file
        const excelBuffer: any = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        // Save the file
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        const fileName = `Filtered_Data_${new Date().toISOString().slice(0, 10)}.xlsx`;
        saveAs(blob, fileName);
    }

    print(id: number) {
        sessionStorage.setItem("depositorId", String(id));
        this.router.navigate(['/cold-storage-deposit-case-print']);
    }

}
