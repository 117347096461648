<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="row mt-3 mb-3">
        <div *ngFor="let filter of filters" class="col-lg-3 col-md-4 col-12 mt-2">
          <div class="form-floating">
            <select class="form-select" [(ngModel)]="selectedFilters[filter.key]" (change)="applyFilters()"
              [attr.id]="filter.key" [attr.aria-label]="filter.label">
              <option selected disabled value="">{{ filter.label }}</option>
              <option value="all">All</option>
              <option *ngFor="let option of filter.options" [value]="option">{{ option }}</option>
            </select>
            <label [attr.for]="filter.key" class="text-gray font-weight-lighter">{{ filter.label }}</label>
          </div>
        </div>
        <div class="col-lg col-md-4 mt-2">
          <div class="form-floating">
            <input type="search" class="form-control" id="floatingInput" placeholder="Search" [(ngModel)]="searchText">
            <label for="floatingInput">Search</label>
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-end align-items-end">
        <div class="col-lg-2 col-md-4 mt-2 d-flex justify-content-end align-items-end">
          <button class="btn btn-danger mt-2" (click)="resetFilters()">Clear Filters <img class="img-fluid" alt="clear"
              src="assets/img/icons/clear-alt.png"></button>
        </div>
        <div class="col-lg-2 col-md-4 mt-2 d-flex justify-content-end align-items-end">
          <button class="btn btn-success mt-2" (click)="downloadExcel()">Download Excel <img class="img-fluid"
              alt="clear" src="assets/img/icons/file-excel.png"></button>
        </div>
      </div>
    </div>
    <div class="container-fluid mt-3">
      <div class="table-style table-responsive table-bordered">
        <table class="table table-head-fixed text-nowrap order-table text-center">
          <thead>
            <tr class="sticky-top">
              <th>S.No</th>
              <th>Depositor Name</th>
              <th>Commodity Name</th>
              <th>Date</th>
              <th>Maintenance Type</th>
              <th>Renting type</th>
              <th>Closing Package</th>
              <th>Closing Weight</th>
              <th>Deposit Application Number</th>
            </tr>
          </thead>
          <tbody *ngIf="tableStockListData.length; else noDataTemplate">
            <tr *ngFor="let stock of tableStockListData | filter: searchText; let i = index;">
              <td>{{ i + 1 }}</td>
              <td>{{ stock.depositorName || '-' }}</td>
              <td>{{ stock.commodityName || '-' }}</td>
              <td>{{ stock.date ? (stock.date | date: 'dd-MM-yyyy') : '-' }}</td>
              <td><span class="badge" [ngClass]="{'badge-gradient-green' : stock.maintenanceType === 'DEPOSIT',  
               'badge-gradient-red' : stock.maintenanceType == 'DELIVERY'}">{{formatStatus(stock.maintenanceType) ||
                  '-' }}</span></td>
              <td>{{ stock.rentingType || '-' }}</td>
              <td>{{ stock.closingPackage || '-' }}</td>
              <td>{{ stock.closingWeight || '-' }}</td>
              <td>{{stock?.depositApplicationNumber || '-' }}</td>
            </tr>
          </tbody>
          <ng-template #noDataTemplate>
            <tr class="text-center text-danger">
              <td colspan="12">Data Not Found</td>
            </tr>
          </ng-template>
        </table>
      </div>
    </div>
  </div>
</section>