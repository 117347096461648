import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import {
  ColdStorageDepositDetails
} from "@components/aftc-digitalization/aftc-digitalization-models/coldStorageDepositDetails";


@Component({
  selector: "app-cold-storage-deposit-print",
  templateUrl: "./cold-storage-deposit-print.component.html",
  styleUrls: ["./cold-storage-deposit-print.component.scss"]
})
export class ColdStorageDepositPrintComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  depositorDetails: ColdStorageDepositDetails | undefined;
  currentDate: string;

  constructor(private apiServices: ApiService, private spinner: NgxSpinnerService,
    private toaster: ToastrService, private datePipe: DatePipe, private router: Router) {
    this.currentDate = this.datePipe.transform(new Date(), "dd/MM/yyyy");
  }

  ngOnInit() {
    const depositorId = sessionStorage.getItem("depositorId");
    this.getDepositorDetailsById(depositorId);
  }

  ngOnDestroy() {
    sessionStorage.removeItem("depositorId");
  }

  getDepositorDetailsById(depositorId) {
    this.apiServices.getColdStorageDepositDetailsById(depositorId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.depositorDetails = data;
        setTimeout(() => {
          this.print();
        }, 3000);
      }, (err: any) => {
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  getFormattedDate(timestamp: number): string {
    const date = new Date(timestamp * 1000);
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  print() {
    window.print();
    setTimeout(() => {
      this.router.navigate(["/main/frontOffice"]);
    });
  }
}
