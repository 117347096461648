<div class="container-fluid">
  <div class="row">
    <div class="col-md-8 justify-content-start">
      <div class="div mb-2 mt-3">
        <h3>Delivery Request View Details</h3>
      </div>
    </div>
  </div>

  <div class="row d-flex justify-content-end">
    <div class="col-lg col-md-4 mt-2">
      <div class="form-floating">
        <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
          (change)="updateDetails($event,'name')" [(ngModel)]="depositorName">
          <option selected disabled value="">Select Depositor Name</option>
          <option value="all">All</option>
          <option *ngFor="let name of this.depositorNameDetails">{{name}}</option>
        </select>
        <label for="floatingSelect">Depositor Name</label>
      </div>
    </div>


    <div class="col-lg col-md-4 mt-2">
      <div class="form-floating">
        <select class="form-select" aria-label="Floating label select example"
          (change)="updateDetails($event,'commodity')" [(ngModel)]="commodityName">
          <option selected disabled value="">Select Commodity Name</option>
          <option value="all">All</option>
          <option *ngFor="let commodity of this.CommodityNameDetails">{{commodity}}</option>
        </select>
        <label for="floatingSelect">Commodity Name</label>
      </div>
    </div>

    <div class="col-lg col-md-4 mt-2">
      <div class="form-floating">
        <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
          (change)="updateDetails($event,'date')" [(ngModel)]="deliveryRequestDate">
          <option selected disabled value="">Select Delivery Request Date</option>
          <option value="all">All</option>
          <option *ngFor="let date of this.deliveryRequestDateDetails">{{date}}</option>
        </select>
        <label for="floatingSelect">Delivery Request Date</label>
      </div>
    </div>

    <div class="col-lg col-md-4 mt-2">
      <div class="form-floating">
        <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
          (change)="updateDetails($event,'status')" [(ngModel)]="currentStatus">
          <option selected disabled value="">Select Delivery Status</option>
          <option value="all">All</option>
          <option *ngFor="let status of this.currentStatusDetails">{{status}}</option>
        </select>
        <label for="floatingSelect">Delivery Status</label>
      </div>
    </div>

    <div class="col-lg col-md-4 mt-2">
      <div class="form-floating">
        <input type="search" class="form-control" placeholder="search" [(ngModel)]="searchText">
        <label class="text-gray">Search</label>
      </div>
    </div>
  </div>

  <div class="row d-flex justify-content-end align-items-end">
    <div class="col-lg-2 col-md-4 mt-2 d-flex justify-content-end align-items-end">
      <button class="btn btn-danger mt-2" (click)="resetReviewFilter()">Clear Filters <img class="img-fluid" alt="clear"
          src="assets/img/icons/clear-alt.png"></button>
    </div>
    <div class="col-lg-2 col-md-4 mt-2 d-flex justify-content-end align-items-end">
      <button class="btn btn-success mt-2" (click)="downloadExcel()">Download Excel <img class="img-fluid" alt="clear"
          src="assets/img/icons/file-excel.png"></button>
    </div>
  </div>

  <div class="py-3"></div>

  <div class="container-fluid">
    <div class="table-style table-responsive table-bordered">
      <table class="table table-head-fixed text-nowrap order-table text-center">
        <thead>
          <tr class="sticky-top">
            <th>No</th>
            <th>Depositor Name</th>
            <th>Commodity Name</th>
            <th>Quantity</th>
            <th>Wight</th>
            <th>Date & Time</th>
            <th>Current Status</th>
            <th *ngIf="this.department != 'COOLING STORAGE'">QR Code</th>
            <th>Status Update</th>
            <th *ngIf="this.department != 'COOLING STORAGE'">Print</th>
          </tr>
        </thead>
        <tbody *ngIf="(getDeliveryDetails | filter:searchText).length > 0; else noDataFound">
          <tr *ngFor="let aftc of getDeliveryDetails | filter: searchText;let i = index">
            <td>{{i+1}}</td>
            <td>{{aftc?.depositorName || '-' }}</td>
            <td>{{aftc?.commodityName || '-' }}</td>
            <td>{{aftc?.quantity || '-' }}</td>
            <td>{{aftc?.weight || '-' }}</td>
            <td>{{aftc?.createdAt | date : "dd-MM-yyy / hh:mm:ss aa"}}</td>
            <td><span class="badge" [ngClass]="{'badge-gradient-red': aftc?.currentStage === 'REQUESTED',
  'badge-gradient-info': aftc?.currentStage === 'ADMIN_APPROVED','badge-gradient-yellow': aftc?.currentStage === 'QCM_APPROVED',
  'badge-gradient-green': aftc?.currentStage === 'FINAL_APPROVED'}">{{ formatStatus(aftc?.currentStage)}}</span></td>
            <td *ngIf="this.department != 'COOLING STORAGE'"><i class="fa fa-eye" (click)="showQRCode(aftc)"></i></td>
            <td>
              <button type="button" matTooltip="Click to Approval for Cold Storage" class="btn btn-success"
                *ngIf="aftc?.currentStage === 'REQUESTED' && this.department != 'COOLING STORAGE'"
                (click)="deliveryStatusUpdate('ADMIN_APPROVED',aftc?.deliveryRequestNumber,aftc?.stockDeliveryId)">Approval</button>
              <button type="button" matTooltip="Click to Approval for Cold Storage" class="btn btn-success"
                *ngIf="aftc?.currentStage === 'ADMIN_APPROVED' && this.department === 'COOLING STORAGE'"
                (click)="deliveryStatusUpdate('QCM_APPROVED',aftc?.deliveryRequestNumber,aftc?.stockDeliveryId)">Delivery
                Approval</button>
              <button type="button" matTooltip="Click to Approval for out of delivery" class="btn btn-success"
                *ngIf="aftc?.currentStage === 'QCM_APPROVED' && this.department != 'COOLING STORAGE'"
                (click)="deliveryStatusUpdate('FINAL_APPROVED',aftc?.deliveryRequestNumber,aftc?.stockDeliveryId)">Final
                Approval</button>
            </td>
            <td *ngIf="this.department != 'COOLING STORAGE'">
              <button type="button" *ngIf="aftc?.currentStage === 'FINAL_APPROVED'" matTooltip="Click to print"
                class="btn btn-white" (click)="print(aftc?.stockDeliveryId)">
                <img src="../../../../../assets/img/icons/invoice.png" style="height: 20px;"></button>
            </td>
          </tr>
        </tbody>
        <ng-template #noDataFound>
          <tr class="text-center ubuntu-bold h6 text-danger">
            <td colspan="12">Data Not Found</td>
          </tr>
        </ng-template>
      </table>
    </div>
  </div>
</div>

<div class="modal fade show" tabindex="-1" [style.display]="qrCodeShowPopup ? 'block' : 'none'"
  style="background-color: rgba(87,86,86,0.64)">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">QR Code</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="qrCodeShowPopup = false"></button>
      </div>
      <div class="modal-body text-center">
        <img [src]="this.qrCodeImage" alt="QR Code" class="qrCode">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
          (click)="qrCodeShowPopup = false">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade show" tabindex="-1" [style.display]="irmNumberEntryShowPopup ? 'block' : 'none'"
  style="background-color: rgba(87,86,86,0.64)">
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">IRM Number Entry Screen</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="irmNumberEntryShowPopup = false"></button>
      </div>
      <div class="modal-body">
        <div class="row mb-3">
          <div class="col-12">
            <div class="form-floating">
              <input type="text" class="form-control" id="irmNumber" [(ngModel)]="irmNumber"
                placeholder="Enter IRM Number">
              <label for="irmNumber">IRM Number</label>
            </div>
            <span class="text-danger ms-1" *ngIf="isTouched && !irmNumber.trim()">* IRM Number is required.</span>
          </div>
        </div>
        <div class="row">
          <div class="text-center">
            <button type="button" class="btn btn-success" (click)="submitIrmNumber()">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner>
  <div id="page">
    <div id="container">
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div class="fw-bolder" id="h3"><img src="../assets/img/AFTC logo.png" class="logo" alt="Sample image"></div>
    </div>
  </div>
</ngx-spinner>