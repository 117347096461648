<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="row mt-3 mb-3">
        <div *ngFor="let filter of filters" class="col-lg col-md-4 col-12 mt-2">
          <div class="form-floating">
            <select class="form-select"
                    [(ngModel)]="selectedFilters[filter.key]"
                    (change)="applyFilters()"
                    [attr.id]="filter.key"
                    [attr.aria-label]="filter.label">
              <option selected disabled value="">{{ filter.label }}</option>
              <option value="all">All</option>
              <option *ngFor="let option of filter.options" [value]="option">{{ option }}</option>
            </select>
            <label [attr.for]="filter.key" class="text-gray font-weight-lighter">{{ filter.label }}</label>
          </div>
        </div>

        <div class="col-lg col-md-4 mt-2">
          <div class="form-floating">
            <input type="search" class="form-control" id="floatingInput" placeholder="Search" [(ngModel)]="searchText">
            <label for="floatingInput">Search</label>
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-end align-items-end">
        <div class="col-lg-2 col-md-4 mt-2 d-flex justify-content-end align-items-end">
          <button class="btn btn-danger mt-2" (click)="resetFilters()">Clear Filters <img
            class="img-fluid" alt="clear" src="assets/img/icons/clear-alt.png"></button>
        </div>
        <div class="col-lg-2 col-md-4 mt-2 d-flex justify-content-end align-items-end">
          <button class="btn btn-success mt-2" (click)="downloadExcel()">Download Excel <img
            class="img-fluid" alt="clear" src="assets/img/icons/file-excel.png"></button>
        </div>
      </div>
    </div>
    <div class="container-fluid mt-3">
      <div class="table-style table-responsive table-bordered">
        <table class="table table-head-fixed text-nowrap order-table text-center">
          <thead>
          <tr class="sticky-top">
            <th>S.No</th>
            <th>Delivery Order No</th>
            <th>Depositor Name</th>
            <th>Commodity</th>
            <th>Weight</th>
            <th>Delivery Date</th>
            <th>Bags</th>
            <th>Vehicle No</th>
            <th>IRM No</th>
            <th>Godown No</th>
            <th>Stack No</th>
            <th>Status</th>
            <th>Update</th>
          </tr>
          </thead>
          <tbody *ngIf="filteredData.length; else noDataTemplate">
          <tr *ngFor="let req of filteredData | filter: searchText; let i = index;">
            <td>{{ i + 1 }}</td>
            <td>{{ req.deliverOrderNo || '-' }}</td>
            <td>{{ req.depositorName || '-' }}</td>
            <td>{{ req.commodityName || '-' }}</td>
            <td>{{ req.weighing || '-' }}</td>
            <td>{{ req.deliveryDate ? (req.deliveryDate * 1000 | date: 'dd-MM-yyyy') : '-' }}</td>
            <td>{{ req.bagsOf || '-' }}</td>
            <td>{{ req.vehicleNo || '-' }}</td>
            <td>{{ req.irmReceiptNo || '-' }}</td>
            <td>{{ req.godownNo || '-' }}</td>
            <td>{{ req.stackNo || '-' }}</td>
            <td>{{req.currentStage || '-'}}</td>
            <td>
              <button class="btn btn-success" [disabled]="req.currentStage != 'Approved'"
                      (click)="updateDeliveryStatus(req.stockDeliveryId)">Out for Delivery
              </button>
            </td>
          </tr>
          </tbody>
          <ng-template #noDataTemplate>
            <tr class="text-center text-danger">
              <td colspan="12">Data Not Found</td>
            </tr>
          </ng-template>
        </table>
      </div>
    </div>
  </div>
</section>
