<div class="container-fluid">
  <div class="row">
    <div class="col-md-8 justify-content-start">
      <div class="div mb-2 mt-3">
        <h3>Depositor Request View Details</h3>
      </div>
    </div>
  </div>

  <div class="row d-flex justify-content-end">
    <div class="col-lg col-md-4 mt-2">
      <div class="form-floating">
        <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
          (change)="updateDetails($event,'name')" [(ngModel)]="depositorName">
          <option selected disabled value="">Select Depositor Name</option>
          <option value="all">All</option>
          <option *ngFor="let name of this.depositorNameDetails">{{name}}</option>
        </select>
        <label for="floatingSelect">Depositor Name</label>
      </div>
    </div>


    <div class="col-lg col-md-4 mt-2">
      <div class="form-floating">
        <select class="form-select" aria-label="Floating label select example"
          (change)="updateDetails($event,'commodity')" [(ngModel)]="commodityName">
          <option selected disabled value="">Select Commodity Name</option>
          <option value="all">All</option>
          <option *ngFor="let commodity of this.CommodityNameDetails">{{commodity}}</option>
        </select>
        <label for="floatingSelect">Commodity Name</label>
      </div>
    </div>

    <div class="col-lg col-md-4 mt-2">
      <div class="form-floating">
        <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
          (change)="updateDetails($event,'date')" [(ngModel)]="depositRequestDate">
          <option selected disabled value="">Select Deposit Request Date</option>
          <option value="all">All</option>
          <option *ngFor="let date of this.depositRequestDateDetails">{{date}}</option>
        </select>
        <label for="floatingSelect">Depositor Request Date</label>
      </div>
    </div>

    <div class="col-lg col-md-4 mt-2">
      <div class="form-floating">
        <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
          (change)="updateDetails($event,'status')" [(ngModel)]="currentStatus">
          <option selected disabled value="">Select Deposit Status</option>
          <option value="all">All</option>
          <option *ngFor="let status of this.currentStatusDetails">{{status}}</option>
        </select>
        <label for="floatingSelect">Depositor Status</label>
      </div>
    </div>

    <div class="col-lg col-md-4 mt-2">
      <div class="form-floating">
        <input type="search" class="form-control" placeholder="search" [(ngModel)]="searchText">
        <label class="text-gray">Search</label>
      </div>
    </div>
  </div>
  <div class="col-lg col-md-4 mt-4 text-end">
    <!-- <button type="button" class="btn btn-outline-danger d-flex align-items-center"
        matTooltip="Click to clear all filters" (click)="resetReviewFilter()">Clear Filter<i
          class="fa fa-filter me-2 ms-1 mt-1"></i></button> -->
  </div>
  <div class="row d-flex justify-content-end align-items-end">
    <div class="col-lg-2 col-md-4 mt-2 d-flex justify-content-end align-items-end">
      <button class="btn btn-danger mt-2" (click)="resetReviewFilter()">Clear Filters <img class="img-fluid" alt="clear"
          src="assets/img/icons/clear-alt.png"></button>
    </div>
    <div class="col-lg-2 col-md-4 mt-2 d-flex justify-content-end align-items-end">
      <button class="btn btn-success mt-2" (click)="downloadExcel()">Download Excel <img class="img-fluid" alt="clear"
          src="assets/img/icons/file-excel.png"></button>
    </div>
  </div>


  <div class="py-2"></div>

  <div class="container-fluid">
    <div class="table-style table-responsive table-bordered">
      <table class="table table-head-fixed text-nowrap order-table text-center">
        <thead>
          <tr class="sticky-top">
            <th>No</th>
            <th>Depositor Name</th>
            <th>Commodity Name</th>
            <th>Quantity</th>
            <th>Wight</th>
            <th>Date & Time</th>
            <th>Current Status</th>
            <th *ngIf="this.department != 'COOLING STORAGE'">QR Code</th>
            <th *ngIf="this.department != 'COOLING STORAGE'">Status Update</th>
            <th *ngIf="this.department === 'COOLING STORAGE'">Space Entry</th>
            <th *ngIf="this.department != 'COOLING STORAGE'">Print</th>
          </tr>
        </thead>
        <tbody *ngIf="(getDepositDetails | filter:searchText).length > 0; else noDataFound">
          <tr *ngFor="let aftc of getDepositDetails | filter: searchText;let i = index">
            <td>{{i+1}}</td>
            <td>{{aftc?.depositorName || '-' }}</td>
            <td>{{aftc?.commodityName || '-' }}</td>
            <td>{{aftc?.quantity || '-' }}</td>
            <td>{{aftc?.weight || '-' }}</td>
            <td>{{aftc?.createdAt | date : "dd-MM-yyy / hh:mm:ss aa"}}</td>
            <td><span class="badge" [ngClass]="{'badge-gradient-red': aftc?.currentStage === 'REQUESTED',
  'badge-gradient-info': aftc?.currentStage === 'ADMIN_APPROVED','badge-gradient-yellow': aftc?.currentStage === 'QCM_APPROVED',
  'badge-gradient-green': aftc?.currentStage === 'FINAL_APPROVED'}">{{ formatStatus(aftc?.currentStage)}}</span></td>
            <td *ngIf="this.department != 'COOLING STORAGE'"><i class="fa fa-eye" (click)="showQRCode(aftc)"></i></td>
            <td *ngIf="this.department != 'COOLING STORAGE'">
              <button type="button" matTooltip="Click to approval for cold storage" class="btn btn-success"
                *ngIf="aftc?.currentStage === 'REQUESTED'"
                (click)="depositStatusUpdate('ADMIN_APPROVED',aftc?.depositRequestNumber,aftc?.stockDepositId)">Approval</button>
              <button type="button" matTooltip="Click to approve for final approval." class="btn btn-success"
                *ngIf="aftc?.currentStage === 'QCM_APPROVED'"
                (click)="depositStatusUpdate('FINAL_APPROVED',aftc?.depositRequestNumber,aftc?.stockDepositId)">Final
                Approval</button>
            </td>
            <td *ngIf="this.department === 'COOLING STORAGE'">
              <i class="fa fa-pen" matTooltip="Click to deposit space allocation" (click)="getMemberSpaceDetails(aftc)"
                *ngIf="aftc?.currentStage == 'ADMIN_APPROVED'; else noAction"></i>
              <ng-template #noAction>
                <span> -- </span>
              </ng-template>
            </td>
            <td *ngIf="this.department != 'COOLING STORAGE'">
              <button type="button" *ngIf="aftc?.currentStage === 'FINAL_APPROVED'" matTooltip="Click to print"
                class="btn btn-white" (click)="print(aftc?.stockDepositId)">
                <img src="../../../../../assets/img/icons/invoice.png" style="height: 20px;"></button>
            </td>
          </tr>
        </tbody>
        <ng-template #noDataFound>
          <tr class="text-center ubuntu-bold h6 text-danger">
            <td colspan="12">Data Not Found</td>
          </tr>
        </ng-template>
      </table>
    </div>
  </div>
</div>

<div class="modal fade show" tabindex="-1" [style.display]="qrCodeShowPopup ? 'block' : 'none'"
  style="background-color: rgba(87,86,86,0.64)">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">QR Code</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="qrCodeShowPopup = false"></button>
      </div>
      <div class="modal-body text-center">
        <img [src]="this.qrCodeImage" alt="QR Code" class="qrCode">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
          (click)="qrCodeShowPopup = false">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade show" tabindex="-1" [style.display]="irmNumberEntryShowPopup ? 'block' : 'none'"
  style="background-color: rgba(87,86,86,0.64)">
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">IRM Number Entry Screen</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="irmNumberEntryShowPopup = false"></button>
      </div>
      <div class="modal-body">
        <div class="row mb-3">
          <div class="col-12">
            <div class="form-floating">
              <input type="text" class="form-control" id="irmNumber" [(ngModel)]="irmNumber"
                placeholder="Enter IRM Number">
              <label for="irmNumber">IRM Number</label>
            </div>
            <span class="text-danger ms-1" *ngIf="isTouched && !irmNumber.trim()">* IRM Number is required.</span>
          </div>
        </div>
        <div class="row">
          <div class="text-center">
            <button type="button" class="btn btn-success" (click)="submitIrmNumber()">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade show" tabindex="-1" [style.display]="addDepositorPopup ? 'block' : 'none'"
  style="background-color: rgba(87,86,86,0.64)">
  <div class="modal-dialog modal-xl modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add Deposit Space Allocate</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="addDepositorPopup = false"></button>
      </div>
      <div class="modal-body">
        <!-- Add More Button -->
        <div class="row g-3 align-items-center justify-content-between mb-3">
          <!-- Total Number Of Quantity -->
          <div class="col-lg-3 col-md-3 col-sm-6">
            <div class="form-floating">
              <input type="text" class="form-control" readonly [value]="this.totalNumberOfBags">
              <label for="text">Total Number Of Quantity</label>
            </div>
          </div>

          <!-- Total Number Of Weight -->
          <div class="col-lg-3 col-md-3 col-sm-6">
            <div class="form-floating">
              <input type="text" class="form-control" readonly [value]="this.totalNumberOfWights">
              <label for="text">Total Number Of Weight In (Kg)</label>
            </div>
          </div>

          <!-- Available Amount -->

          <div class="col-lg-2 col-md-3 col-sm-6">
            <div class="form-floating">
              <input type="text" class="form-control" readonly placeholder="Available Amount"
                [value]="currentAvailableBalance | currency:'INR':'symbol':'1.2-2'">
              <label>Available Amount</label>
            </div>
          </div>

          <!-- Total Paid Amount -->
          <div class="col-lg-2 col-md-3 col-sm-6">
            <div class="form-floating">
              <input type="text" class="form-control" readonly placeholder="Total Paid Amount"
                [value]="currentTotalAmount | currency:'INR':'symbol':'1.2-2'">
              <label>Total Paid Amount</label>
            </div>
          </div>

          <!-- Add More Button -->
          <div class="col-lg-2 col-md-3 col-sm-6 text-end">
            <button type="button" class="btn btn-primary w-100"
              (click)="addNewMaintenanceForm(this.currentMemberSpaceDetails)">
              Add More <i class="fa fa-plus"></i>
            </button>
          </div>

        </div>


        <div class="row mb-3">
          <div class="col-12 d-flex justify-content-between align-items-center">
            <h3 class="mb-0">Do you want to get open space details?</h3>
            <div>
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="openSpaceToggle" [(ngModel)]="isOpenSpace"
                  [ngClass]="{'bg-success': isOpenSpace, 'bg-light': !isOpenSpace}" (change)="getOpenSpace($event)">
                <label class="form-check-label" for="openSpaceToggle">{{ isOpenSpace ? 'Yes' : 'No' }}</label>
              </div>
            </div>
          </div>
        </div>

        <hr>
        <form [formGroup]="maintenanceForm">
          <!-- Dynamic Forms from FormArray -->
          <div formArrayName="maintenanceList" *ngFor="let formGroup of maintenanceList.controls; let i = index">
            <div [formGroupName]="i">
              <div class="row mb-3">
                <div class="col-12 d-flex justify-content-between align-items-center">
                  <h3 class="mb-0">Space Allocate {{i + 1}}</h3>
                  <button type="button" class="btn btn-danger" (click)="removeForm(i)">Remove <i
                      class="fas fa-trash"></i></button>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="form-floating">
                    <input type="text" class="form-control" formControlName="depositorName" readonly>
                    <label for="floatingInput">Depositor Name <span class="text-danger">*</span></label>
                  </div>
                  <span class="text-danger"
                    *ngIf="this.maintenanceFormError.depositorName">{{this.maintenanceFormError.depositorName}}</span>
                </div>

                <div class="col-lg-4 col-md-6 col-12 mb-2">
                  <div class="form-floating">
                    <input type="text" class="form-control" formControlName="commodityName" readonly>
                    <label for="floatingInput">Commodity Name <span class="text-danger">*</span></label>
                  </div>
                  <span class="text-danger"
                    *ngIf="this.maintenanceFormError.commodityName">{{this.maintenanceFormError.commodityName}}</span>
                </div>

                <div class="col-lg-4 col-md-6 col-12 mb-2">
                  <div class="form-floating">
                    <select class="form-control" formControlName="rentingType" (change)="selectRentingType($event,i)">
                      <option selected disabled value="">Select Renting Type</option>
                      <option *ngFor="let rent of rentingTypeList">{{rent}}</option>
                    </select>
                    <label for="floatingInput">Renting Type <span class="text-danger">*</span></label>
                  </div>
                  <span class="text-danger"
                    *ngIf="this.maintenanceFormError.rentingType">{{this.maintenanceFormError.rentingType}}</span>
                </div>

                <div class="col-lg-4 col-md-6 col-12 mb-2">
                  <div class="form-floating">
                    <select class="form-control" formControlName="floorNumber" (change)="selectFloorNumber($event,i)">
                      <option selected disabled value="">Select Floor Number</option>
                      <option *ngFor="let floor of fullFloorList">{{floor}}</option>
                    </select>
                    <label for="floatingInput">Floor Number <span class="text-danger">*</span></label>
                  </div>
                  <span class="text-danger"
                    *ngIf="this.maintenanceFormError.floorNumber">{{this.maintenanceFormError.floorNumber}}</span>
                </div>

                <div class="col-lg-4 col-md-6 col-12 mb-2">
                  <div class="form-floating">
                    <select class="form-control" formControlName="spaceNumber">
                      <option selected disabled value="">Select Space Number</option>
                      <option *ngFor="let space of dynamicSpaceLists[i]">{{ space.spaceNumber }}</option>
                    </select>
                    <label for="floatingInput">Space Number <span class="text-danger">*</span></label>
                  </div>
                  <span class="text-danger" *ngIf="this.maintenanceFormError.spaceNumber">{{
                    this.maintenanceFormError.spaceNumber }}</span>
                </div>

                <div class="col-lg-4 col-md-6 col-12 mb-2">
                  <div class="form-floating">
                    <input type="number" class="form-control" formControlName="quantity"
                      (input)="checkBagsCount($event,i)">
                    <label for="floatingInput">Quantity <span class="text-danger">*</span></label>
                  </div>
                  <span class="text-danger"
                    *ngIf="this.maintenanceFormError.quantity">{{this.maintenanceFormError.quantity}}</span>
                </div>

                <div class="col-lg-4 col-md-6 col-12 mb-2">
                  <div class="form-floating">
                    <input type="number" class="form-control" formControlName="weight">
                    <label for="floatingInput">Weight In (Kg)<span class="text-danger">*</span></label>
                  </div>
                  <span class="text-danger"
                    *ngIf="this.maintenanceFormError.weight">{{this.maintenanceFormError.weight}}</span>
                </div>
              </div>
              <hr>
            </div>
          </div>

          <!-- Submit Button -->
          <div class="row justify-content-center mt-4 mb-5 ml-2 mr-2" *ngIf="isShowCalculation">
            <button type="submit" class="btn btn-primary col-md-2" (click)="formValidation(maintenanceForm)">Charge
              Calculation</button>
          </div>

          <div class="row justify-content-center mt-4 mb-5 ml-2 mr-2" *ngIf="isShowSave">
            <button type="submit" class="btn btn-success col-md-1"
              (click)="formValidation(maintenanceForm)">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<ngx-spinner>
  <div id="page">
    <div id="container">
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div class="fw-bolder" id="h3"><img src="../assets/img/AFTC logo.png" class="logo" alt="Sample image"></div>
    </div>
  </div>
</ngx-spinner>