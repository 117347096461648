import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from "@angular/common";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { CommodityDetails } from "@/model/commodityDetails/CommodityDetails";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";

@Component({
    selector: "app-commodity-details-view",
    templateUrl: "./commodity-details-view.component.html",
    styleUrls: ["./commodity-details-view.component.scss"]
})
export class CommodityDetailsViewComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    searchText;
    addCommodityPopup: boolean = false;
    getAllCommodityDetailsList: CommodityDetails[] = [];
    commodityDetails: CommodityDetails[] = [];
    CommodityNameDetails: string[] = [];
    commodityName: string = "";

    constructor(private apiServices: ApiService, private router: Router,
        private toaster: ToastrService, private spinner: NgxSpinnerService, private datePipe: DatePipe) {
    }

    newFormGroup = new UntypedFormGroup({
        commId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        commodityName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        normalRent: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        specialConcessionRent: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        unit: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        inclusiveGst: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        taxValue: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        createdAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        updateAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    })

    ngOnInit(): void {
        this.getAllCommodityDetails();
    }

    // Get all Commodity Details function
    getAllCommodityDetails() {
        this.spinner.show();
        this.apiServices.getCommodityDetails()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data: any) => {
                this.spinner.hide();
                this.getAllCommodityDetailsList = data.sort((a, b) => {
                    const idA = a.commodityId;
                    const idB = b.commodityId;
                    return idB - idA;}).filter(element => element.unit === 'Cold Storage');
                console.log(this.getAllCommodityDetailsList);
                this.commodityDetails = this.getAllCommodityDetailsList;
                this.CommodityNameDetails = [...new Set(this.getAllCommodityDetailsList.map(item => item.commodityName))];
            }, (error) => {
                this.spinner.hide();
                this.toaster.error(error.error.message || "Something went wrong, please try again..");
            });
    }


    // Clear filter function
    resetReviewFilter() {
        this.searchText = '';
        this.commodityName = '';

        this.commodityDetails = [...this.getAllCommodityDetailsList];
        this.CommodityNameDetails = [...new Set(this.commodityDetails.map(item => item.commodityName))];
    }


    // Update select current filter value set function
    updateDetails(event: any, filterType: string) {
        const value = event.target.value;

        switch (filterType) {
            case 'commodity':
                this.commodityName = value !== 'Select Commodity Name' ? value : 'all';
                break;
            case 'date':


            default:
                break;
        }

        this.applyFilter();
    }


    // Apply filter function
    applyFilter() {
        let filteredData = [...this.getAllCommodityDetailsList];
        if (this.commodityName && this.commodityName !== 'all') {
            filteredData = filteredData.filter(item => item.commodityName === this.commodityName);
        }

        this.commodityDetails = filteredData;
    }


    // Show edit pop up & current value set function
    showEntryPopUp(aftc: CommodityDetails) {
        this.addCommodityPopup = true;
        let data: CommodityDetails;
        this.commodityDetails.forEach((item, index) => {
            if (item.commId === aftc.commId) {
                data = aftc;
            }
        });
        this.newFormGroup.patchValue(data);
        console.log(this.newFormGroup.value);
    }

    //update commodity tax values function
    onSubmit() {
        this.spinner.show();
        const jsonData = {
            commodityId: this.newFormGroup.get('commId').value,
            specialConcessionRent: this.newFormGroup.get('specialConcessionRent').value,
            normalRent: this.newFormGroup.get('normalRent').value,
        }

        this.apiServices.updateCommodityTax(jsonData)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data: any) => {
                this.spinner.hide();
                this.toaster.success('Commodity details updated successfully');
                this.getAllCommodityDetails();
                this.addCommodityPopup = false;
            }, (error) => {
                this.spinner.hide();
                this.toaster.error(error.error.message || "Something went wrong, please try again..");
            });
    }

    // Excel download function
    downloadExcel() {
        if (!this.commodityDetails || this.commodityDetails.length === 0) {
            this.toaster.warning('No data available to download!');
            return;
        }

        // Map data for Excel
        const exportData = this.commodityDetails.map((stock, index) => ({
            'S.No': index + 1,
            'Commodity': stock.commodityName || '-',
            'Tax value': stock.taxValue || '-',
            'Normal Fees': stock.normalRent || '-',
            'Special Concession Fees': stock.specialConcessionRent || '-',
            'Created Date': stock.createdAt
                ? new Date(stock.createdAt).toLocaleDateString('en-GB')
                : '-',
        }));

        // Create worksheet
        const worksheet = XLSX.utils.json_to_sheet(exportData);

        // Create workbook
        const workbook: XLSX.WorkBook = {
            Sheets: { 'Filtered Data': worksheet },
            SheetNames: ['Filtered Data'],
        };

        // Generate Excel file
        const excelBuffer: any = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        // Save the file
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        const fileName = `Filtered_Data_${new Date().toISOString().slice(0, 10)}.xlsx`;
        saveAs(blob, fileName);
    }

}
