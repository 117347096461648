import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { TollGate } from "@components/aftc-digitalization/aftc-digitalization-models/TollGate";

@Component({
  selector: "app-print-view",
  templateUrl: "./print-view.component.html",
  styleUrls: ["./print-view.component.scss"]
})
export class PrintViewComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  currentTollGate: TollGate | undefined;
  currentDate: string;
  entryTime: string;
  exitTime: string;
  billDate: string = "";
  billNo: string;
  memberName: string;
  vehicleNo: string;
  vehicleType: string;
  wheelCount: string;
  driverName: string;
  mobileNumber: string;

  constructor(private apiService: ApiService, private datePipe: DatePipe,
              private spinner: NgxSpinnerService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        console.log("User Code :", params.code);
        this.getTollGate(params.code);
      });
  }

  ngOnDestroy(): void {
    localStorage.removeItem("reportQr");
  }

  getTollGate(code: string) {
    this.apiService.getTollGateByVehicleQr(code)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        console.log("All Toll Gate :", data);
        this.currentTollGate = data;
        this.billDate = this.datePipe.transform(new Date(data.vehEntryDateTime * 1000), "dd/MM/yyyy");
        this.billNo = this.currentTollGate.irmNo;
        this.memberName = this.currentTollGate.memberName;
        this.vehicleNo = this.currentTollGate.tollgateVehicleDetails.vehNumberPlate;
        this.vehicleType = this.currentTollGate.tollgateVehicleDetails.vehType;
        this.wheelCount = String(this.currentTollGate.tollgateVehicleDetails.wheelCount);
        this.driverName = this.currentTollGate.tollgateVehicleDetails.driverName;
        this.mobileNumber = this.currentTollGate.tollgateVehicleDetails.driverPhNo;

        const entryDate = new Date(data.vehEntryDateTime * 1000);
        const entryHours = entryDate.getHours().toString().padStart(2, "0");
        const entryMinutes = entryDate.getMinutes().toString().padStart(2, "0");
        const formattedEntryTime = `${entryHours}:${entryMinutes}`;
        this.entryTime = formattedEntryTime;

        if (data.vehExitDateTime != 0) {
          const exitDate = new Date(data.vehExitDateTime * 1000);
          const exitHours = exitDate.getHours().toString().padStart(2, "0");
          const exitMinutes = exitDate.getMinutes().toString().padStart(2, "0");
          const formattedExitTime = `${exitHours}:${exitMinutes}`;

          this.exitTime = formattedExitTime;
        }
      }, (err: any) => {
        console.log("Something went wrong :", err);
      });
  }
}
