<div class="container-fluid">
  <div class="row">
    <div class="col-md-8 justify-content-start">
      <div class="div mb-2 mt-3">
        <h3>Commodity Details</h3>
      </div>
    </div>
  </div>

  <div class="row d-flex align-items-center justify-content-between">

    <!-- Commodity Name Dropdown (Start) -->
    <div class="col-lg-3 col-md-4 col-sm-6">
      <div class="form-floating">
        <select class="form-select" aria-label="Floating label select example"
          (change)="updateDetails($event,'commodity')" [(ngModel)]="commodityName">
          <option selected disabled value="">Select Commodity Name</option>
          <option value="all">All</option>
          <option *ngFor="let commodity of this.CommodityNameDetails">{{commodity}}</option>
        </select>
        <label for="floatingSelect">Commodity Name</label>
      </div>
    </div> 
  
    <!-- Search Input (End) -->
      <div class="col-lg-3 col-md-4 col-sm-6">
      <div class="form-floating">
        <input type="search" class="form-control" placeholder="Search" [(ngModel)]="searchText">
        <label class="text-gray">Search</label>
      </div>
    </div>

      <div class="col-lg-2 col-md-4 mt-2 d-flex justify-content-end align-items-end">
        <button class="btn btn-danger mt-2" (click)="resetReviewFilter()">Clear Filters <img class="img-fluid" alt="clear"
            src="assets/img/icons/clear-alt.png"></button>
      </div>
      <div class="col-lg-2 col-md-4 mt-2">
        <button class="btn btn-success mt-2" (click)="downloadExcel()">Download Excel <img class="img-fluid" alt="clear"
            src="assets/img/icons/file-excel.png"></button>
      </div>
    </div>
  


  <div class="py-2"></div>

  <div class="container-fluid">
    <div class="table-style table-responsive table-bordered">
      <table class="table table-head-fixed text-nowrap order-table text-center">
        <thead>
          <tr class="sticky-top">
            <th>No</th>
            <th>Commodity Name</th>
            <th>Tax Value</th>
            <th>Normal Rent value</th>
            <th>Spacial Concession Fees</th>
            <th>Add Fees Amount</th>
          </tr>
        </thead>
        <tbody *ngIf="(commodityDetails | filter:searchText).length > 0; else noDataFound">
          <tr *ngFor="let aftc of commodityDetails | filter: searchText;let i = index">
            <td>{{i+1}}</td>
            <td>{{aftc?.commodityName || '-'}}</td>
            <td>{{aftc?.taxValue || '-'}}</td>
            <td>{{aftc?.normalRent || '-'}}</td>
            <td>{{aftc?.specialConcessionRent || '-'}}</td>
            <td><span *ngIf="aftc?.specialConcessionRent == 0 && aftc?.normalRent == 0"><i class="fas fa-pen" (click)="showEntryPopUp(aftc)"></i>
            </span></td>
          </tr>
        </tbody>
        <ng-template #noDataFound>
          <tr class="text-center ubuntu-bold h6 text-danger">
            <td colspan="12">Data Not Found</td>
          </tr>
        </ng-template>
      </table>
    </div>
  </div>
</div>


<div class="modal fade show" tabindex="-1" [style.display]="addCommodityPopup ? 'block' : 'none'"
  style="background-color: rgba(87,86,86,0.64)">
  <div class="modal-dialog modal-xl modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-bold">Add Commodity Fees</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="addCommodityPopup = false"></button>
      </div>
      <div class="modal-body">

        <form [formGroup]="newFormGroup" (ngSubmit)="onSubmit()">
          
              <div class="row">
                <div class="col-lg-4 col-md-6 col-12 mb-2">
                  <div class="form-floating">
                    <input type="text" class="form-control" formControlName="commodityName" readonly>
                    <label for="floatingInput">Commodity Name </label>
                  </div>
                </div>

              <div class="col-lg-4 col-md-6 col-12 mb-2">
                <div class="form-floating">
                  <input type="number" class="form-control" formControlName="normalRent" min="0">
                  <label for="floatingInput">Normal Rent Fees </label>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 mb-2">
              <div class="form-floating">
                <input type="number" class="form-control" formControlName="specialConcessionRent" min="0">
                <label for="floatingInput">Spacial Concession Fees </label>
            </div>
          </div>

            </div>

          <div class="row">
            <div class="col-lg-12 col-md-12 col-12 text-center mt-3">
              <button type="submit" class="btn btn-success">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<ngx-spinner>
  <div id="page">
    <div id="container">
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div class="fw-bolder" id="h3"><img src="../assets/img/AFTC logo.png" class="logo" alt="Sample image"></div>
    </div>
  </div>
</ngx-spinner>