<div class="col-md-12">
  <h3 class="ml-2">Cold Storage Deposit Entry</h3>
</div>
<div class="container-fluid">
  <form [formGroup]="depositForm">
    <div class="row mt-2">
      <!-- <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control textCap" placeholder="Deposit Application No" disabled
                 formControlName="depositApplicationNo">
          <label>Deposit Application No<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.depositApplicationNo"
              class="text-danger">{{depositFormErrors.depositApplicationNo}}</span>
      </div> -->
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Depositor Name" formControlName="depositorName">
          <label class="">Depositor Name<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.depositorName" class="text-danger">{{depositFormErrors.depositorName}}</span>
      </div>
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Invoice No" formControlName="invoiceNo">
          <label class="">Invoice No<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.invoiceNo" class="text-danger">{{depositFormErrors.invoiceNo}}</span>
      </div>
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="date" class="form-control textCap" placeholder="Bill Date" formControlName="billDate">
          <label>Bill Date<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.billDate" class="text-danger">{{depositFormErrors.billDate}}</span>
      </div>

      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <select class="form-select" placeholder="Commodity" formControlName="commodityName"
            (change)="selectCommodity($event)">
            <option value="" selected hidden>Select Commodity Name</option>
            <option *ngFor="let com of commodityNames" value="{{com}}">{{com}}</option>
          </select>
          <label>Commodity Name<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.commodityName" class="text-danger">{{depositFormErrors.commodityName}}</span>
      </div>

      <div class="col-lg-3 col-md-3 mt-2">
        <div class="form-floating">
          <select class="form-control" formControlName="placeOfOrigin" (change)="showCalculation()">
            <option value="" selected disabled>Select Place of Origin</option>
            <option value="TAMILNADU">Tamil Nadu</option>
            <option value="EXCEPT_TAMILNADU">Other State</option>
          </select>
          <label class="">Place of Origin<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.placeOfOrigin" class="text-danger">{{depositFormErrors.placeOfOrigin}}</span>
      </div>


      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <select class="form-control" formControlName="packageType" (change)="showCalculation()">
            <option value="" selected disabled>Select Packing Type</option>
            <option value="BOX">Box</option>
            <option value="BAG">Bag</option>
          </select>
          <label class="">Packing Type<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.packageType" class="text-danger">{{depositFormErrors.packageType}}</span>
      </div>

      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="No.of Quantity" formControlName="quantity"
            (input)="checkQuantityValue($event)">
          <label class="">No.of Quantity<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.quantity" class="text-danger">{{depositFormErrors.quantity}}</span>
      </div>
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Weight In Kg" formControlName="weight"
            (input)="checkWightValue($event)">
          <label class="">Weight In Kg<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.weight" class="text-danger">{{depositFormErrors.weight}}</span>
      </div>
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Type Of Vehicle" formControlName="typeOfVehicle">
          <label class="">Type Of Vehicle<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.typeOfVehicle" class="text-danger">{{depositFormErrors.typeOfVehicle}}</span>
      </div>

      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Wheels" formControlName="wheels">
          <label>Wheels<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.wheels" class="text-danger">{{depositFormErrors.wheels}}</span>
      </div>
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Vehicle No" formControlName="vehicleNo">
          <label class="">Vehicle No<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.vehicleNo" class="text-danger">{{depositFormErrors.vehicleNo}}</span>
      </div>
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="IRM Receipt No" formControlName="irmReceiptNo">
          <label class="">IRM Receipt No<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.iRMReceiptNo" class="text-danger">{{depositFormErrors.iRMReceiptNo}}</span>
      </div>

      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" readonly class="form-control" placeholder="Toll Gate Amount" formControlName="amount">
          <label>Toll Gate Amount<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.amount" class="text-danger">{{depositFormErrors.amount}}</span>
      </div>

      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" readonly class="form-control" placeholder="Available Amount"
            formControlName="availableBalance">
          <label>Available Amount<span style="color:red;">*</span></label>
        </div>
      </div>

      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" readonly class="form-control" placeholder="Total Payable Amount"
            formControlName="totalAmount">
          <label>Total Payable Amount <span *ngIf="this.showTaxValue">(Inc Tax)</span> <span
              style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.totalAmount" class="text-danger">{{depositFormErrors.totalAmount}}</span>
      </div>

      <!-- <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" readonly class="form-control" placeholder="Bearing No" formControlName="bearingNo">
          <label>Bearing No<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.bearingNo" class="text-danger">{{depositFormErrors.bearingNo}}</span>
      </div> -->

      <!-- <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="date" class="form-control textCap" placeholder="Bearing Date"
                 formControlName="bearingDate">
          <label>Bearing Date<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.bearingDate" class="text-danger">{{depositFormErrors.bearingDate}}</span>
      </div> -->

      <!-- <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <select type="text" class="form-select" formControlName="billPeriodType">
            <option disabled selected hidden value="">Select bill period</option>
            <option value="Day wise">Day wise</option>
            <option value="Month wise">Month wise</option>
          </select>
          <label>Bill Period Type<span style="color:red;">*</span></label>
          <span *ngIf="depositFormErrors.billPeriodType" class="text-danger">{{depositFormErrors.billPeriodType}}</span>
        </div>
      </div> -->
    </div>

    <div class="row justify-content-center mt-4 mb-5 ml-2 mr-2" *ngIf="isShowCalculation">
      <button type="submit" class="btn btn-primary col-md-2" (click)="formValidation(depositForm)">Charge
        Calculation</button>
    </div>

    <div class="row justify-content-center mt-4 mb-5 ml-2 mr-2" *ngIf="isShowSave">
      <button type="submit" class="btn btn-success col-md-1" (click)="formValidation(depositForm)">Save</button>
    </div>
  </form>
</div>
<ngx-spinner>
  <div id="page">
    <div id="container">
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div class="fw-bolder" id="h3"><img src="../assets/img/AFTC logo.png" class="logo" alt="Sample image"></div>
    </div>
  </div>
</ngx-spinner>