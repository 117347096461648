import {
  Component,
  OnInit,
  OnDestroy,
  Renderer2,
  HostBinding,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { AppService } from '@services/app.service';
import { LoginResponseModel } from '@/model/login-response/login-response-model';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '@services/api.service';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  isPasswordVisible: any;
  userDetails: LoginResponseModel;
  destroy$: Subject<boolean> = new Subject<boolean>();

  togglePasswordVisibility() {
    const passwordShow = document.getElementById(
      'password'
    ) as HTMLInputElement;
    if (passwordShow.type == 'password') {
      passwordShow.type = 'text';
    } else {
      passwordShow.type = 'password';
    }
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  @HostBinding('class') class = 'login-box';
  public loginForm: UntypedFormGroup;
  public isAuthLoading = false;
  public isGoogleLoading = false;
  public isFacebookLoading = false;
  public loginErrors = {
    username: '',
    password: '',
  };
  username: string;
  password: string;

  constructor(
    private renderer: Renderer2,
    private appService: AppService,
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.renderer.addClass(document.querySelector('app-root'), 'login-page');
    this.loginForm = new UntypedFormGroup({
      username: new UntypedFormControl(null, Validators.required),
      password: new UntypedFormControl(null, Validators.required),
    });
  }

  // async loginByAuth() {
  //   this.appService.loginByAuth(this.loginForm);
  // }

  async loginByGoogle() {
    this.isGoogleLoading = true;
    await this.appService.loginByGoogle();
    this.isGoogleLoading = false;
  }

  async loginByFacebook() {
    this.isFacebookLoading = true;
    await this.appService.loginByFacebook();
    this.isFacebookLoading = false;
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.querySelector('app-root'), 'login-page');
  }

  onSubmit() {

    this.formValidation();
  }

  formValidation() {
    this.loginErrors.username = '';
    this.loginErrors.password = '';
    let hasError = false;

    if (this.loginForm.get('username')?.invalid) {
      this.loginErrors.username = "Username is Required";
      hasError = true;
    }

    if (this.loginForm.get("password")?.invalid) {
      if (this.loginForm.get("password").errors.required) {
        this.loginErrors.password = "Password is Required";
        hasError = true;
      }
    }

    if (!hasError) {
      this.loginByAuth();
    }
  }

  loginByAuth() {
    this.spinner.show();
    this.apiService.loginByAuth(this.loginForm.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          if (data != null) {
            this.spinner.hide();
            this.userDetails = data;
            localStorage.setItem(
              "department",
              String(
                this.userDetails.loginDetails.employeeDetails.deptDetails.deptId
                  .departmentName
              )
            );
            localStorage.setItem("isLoggedIn", "true");
            localStorage.setItem(
              "authHeader",
              String(this.userDetails.authHeader)
            );
            localStorage.setItem(
              "role",
              String(this.userDetails.loginDetails.role)
            );
            localStorage.setItem(
              "grantType",
              String(this.userDetails.loginDetails.grantType)
            );
            localStorage.setItem(
              "userName",
              String(this.userDetails.loginDetails.employeeDetails.emName)
            );
            localStorage.setItem(
              "loginId",
              String(this.userDetails.loginDetails.loginId)
            );
            localStorage.setItem(
              "userId",
              String(this.userDetails.loginDetails.loginSeq)
            );

            this.router.navigate(["/main/dash"]);

            this.toastr.success("Login Successfully");
          }
        },
        (error) => {
          this.spinner.hide();
          this.toastr.error(error.error.message || "User Name or Password is Wrong");
        }
      );
  }
}
