import { TollGate } from "@components/aftc-digitalization/aftc-digitalization-models/TollGate";
import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";
import { MemberDetails } from "@/model/memberDetails/MemberDetails";
import { DatePipe } from "@angular/common";


@Component({
  selector: "app-front-office",
  templateUrl: "./front-office.component.html",
  styleUrls: ["./front-office.component.scss"]
})
export class FrontOfficeComponent implements OnInit {
  searchText;
  getLocalPassDetails: TollGate[] = [];
  getPassDetails: TollGate[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  serviceType = false;
  getDateValue: string[] = [];
  tollgateSeq: number = 0;
  storageField = false;
  serviceField = false;
  storageType: string;
  todayDate: string
  getDateList: any = [];

  constructor(private apiServices: ApiService, private router: Router,
    private toaster: ToastrService, private spinner: NgxSpinnerService,
    private datePipe: DatePipe) {
    this.todayDate = this.datePipe.transform(new Date().getTime(), "dd-MM-yyyy")
  }

  gateForm = new UntypedFormGroup({
    serviceType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    storageType: new UntypedFormControl("", [Validators.required, Validators.nullValidator])
  });

  ngOnInit(): void {
    // this.getAllPassDetails();
    this.getTollgateEntryDate();
  }

  getAllPassDetails() {
    this.spinner.show();
    this.apiServices.getAllTollGate()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        this.getLocalPassDetails = data.filter(item => item.serviceType != "Depot" && item.irmNo != 'Deleted');
        this.getPassDetails = this.getLocalPassDetails.sort((a, b) => {
          const idA = a.tollgateSeq;
          const idB = b.tollgateSeq;
          return idB - idA;
        });
      }, (err: any) => {
        this.spinner.hide();
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  // Get the toll gate entry date list
  getTollgateEntryDate() {
    this.spinner.show();
    this.apiServices.getTollgateEntryDateList()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.getDateList = data
          .map((item) => ({
            ...item,
            parsedDate: new Date(item.date.split('-')[2], +item.date.split('-')[1] - 1, +item.date.split('-')[0])
          }))
          .sort((a, b) => {
            const aTime = a.parsedDate.getTime();
            const bTime = b.parsedDate.getTime();
            return bTime - aTime;
          });

        this.getDateValue = this.getDateList;
        this.spinner.hide();

        if (this.getDateList.length > 0) {
          this.getAllTollGateByDate(this.getDateList[0].date);
        } else {
          console.log("No dates available.");
        }

        console.log("Sorted Date List", this.getDateList);
      }, (err: any) => {
        this.spinner.hide();
        this.toaster.error(err.error.message || "Something went wrong, Please try again later");
      });
  }

  showDetails(event: any) {
    const value = event.target.value;
    this.getAllTollGateByDate(value);
  }


  // Tollgate entry date based details get
  getAllTollGateByDate(date: string) {
    this.spinner.show();
    this.apiServices.getAllTollGateByDate(date)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        this.getLocalPassDetails = data.filter(item => item.irmNo != "Deleted");
        this.getPassDetails = this.getLocalPassDetails.sort((a, b) => (b.tollgateSeq - a.tollgateSeq));
      }, (error: any) => {
        this.spinner.hide();
        this.toaster.error(error.error.message || "Something went wrong, Please try again later");
      })
  }


  navigatePrint() {
    this.router.navigate(["toll-gate-print"]);
  }

  serviceClose() {
    if (this.gateForm.get("storageType").value != "") {
      if (this.gateForm.get("serviceType").invalid) {
        this.toaster.error("Please select service type");
      } else {
        if (this.gateForm.get("serviceType").value == "Deposit") {
          this.serviceType = false;
          sessionStorage.setItem("tollgateSeqNo", String(this.tollgateSeq));
          sessionStorage.setItem("serviceType", this.gateForm.get("serviceType").value);

          switch (this.storageType) {
            case "Common Warehouse": {
              this.router.navigate(["/main/depositEntry"]);
              break;
            }
            case "SLCM": {
              this.router.navigate(["/main/depositEntry"]);
              break;
            }
            case "Pre Processing": {
              this.router.navigate(["/main/preprocessing-deposit"]);
              break;
            }
            case "Cold Storage": {
              this.router.navigate(["/main/cold-storage-deposit"]);
              break;
            }
            case "Packing": {
              this.router.navigate(["/main/packing-deposit"]);
              break;
            }
            case "Solar Tunnel Dryer": {
              this.router.navigate(["/main/solar-tunnel-dryer-deposit"]);
              break;
            }
          }
        } else if (this.gateForm.get("serviceType").value == "Delivery") {
          this.serviceType = false;
          sessionStorage.setItem("tollgateSeqNo", String(this.tollgateSeq));
          sessionStorage.setItem("serviceType", this.gateForm.get("serviceType").value);

          switch (this.storageType) {
            case "Common Warehouse": {
              this.router.navigate(["/main/deliveryEntry"]);
              break;
            }
            case "SLCM": {
              this.router.navigate(["/main/deliveryEntry"]);
              break;
            }
            case "Pre Processing": {
              this.router.navigate(["/main/preprocessing-delivery"]);
              break;
            }
            case "Cold Storage": {
              this.router.navigate(["/main/cold-storage-delivery"]);
              break;
            }
            case "Packing": {
              this.router.navigate(["/main/packing-delivery"]);
              break;
            }
            case "Solar Tunnel Dryer": {
              this.router.navigate(["/main/solar-tunnel-dryer-delivery"]);
              break;
            }
          }
        }
      }
    }
  }

  storageClose() {
    const StorageType = this.gateForm.get("storageType").value;
    if (StorageType.invalid) {
      this.toaster.error("Please select storage type");
    } else if (StorageType != "") {
      sessionStorage.setItem("storageType", this.gateForm.get("storageType").value);
      this.storageType = this.gateForm.get("storageType").value;
      if (StorageType === 'Pre Processing' || StorageType === 'Packing' || StorageType === 'Solar Tunnel Dryer') {
        this.gateForm.controls["serviceType"].setValue("Deposit");
        this.serviceClose();
      } else {
        this.storageField = false;
        this.serviceField = true;
      }
    }
  }

  cancel() {
    this.serviceType = false;
    this.storageField = false;
    this.serviceField = false;
  }

  report(item: TollGate) {
    this.tollgateSeq = item.tollgateSeq;
    if (item.storageType != "") {
      sessionStorage.setItem("storageType", item.storageType);
      this.gateForm.controls["storageType"].setValue(item.storageType);
      this.storageType = item.storageType;
      this.serviceType = true;
      this.storageField = false;
      this.serviceField = true;
    } else {
      this.serviceType = true;
      this.storageField = true;
    }
  }

  checkWarehouse(item: TollGate): boolean {
    if (item.reportStorageType == "Common Warehouse" && item.reportServiceType == "Deposit" && !item.reportAppNo.endsWith("A")) {
      return true;
    } else {
      return false;
    }
  }

  checkStorageType(item: TollGate): boolean {
    if (!!item.reportStorageType) {
      return true;
    } else {
      return false;
    }
  }

  subReportPopup(item: TollGate) {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to add sub report for this " + item.reportAppNo,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#30d66a",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.setItem("depositId", String(item.reportMapId));
        this.router.navigate(["/main/depositEntry"]);
      }
    });
  }

  navigateReceipt(item: TollGate) {
    switch (item.reportStorageType) {
      case "Common Warehouse": {
        if (item.reportServiceType == "Deposit") {
          sessionStorage.setItem("depositorId", String(item.reportMapId));
          this.router.navigate(["/deposit-print"]);
        } else if (item.reportServiceType == "Delivery") {
          sessionStorage.setItem("deliveryId", String(item.reportMapId));
          this.router.navigate(["/deliveryPrint"]);
        }
        break;
      }
      case "SLCM": {
        if (item.reportServiceType == "Deposit") {
          sessionStorage.setItem("depositorId", String(item.reportMapId));
          this.router.navigate(["/deposit-print"]);
        } else if (item.reportServiceType == "Delivery") {
          sessionStorage.setItem("deliveryId", String(item.reportMapId));
          this.router.navigate(["/deliveryPrint"]);
        }
        break;
      }
      case "Cold Storage": {
        if (item.reportServiceType == "Deposit") {
          sessionStorage.setItem("depositorId", String(item.reportMapId));
          this.router.navigate(["/cold-storage-deposit-receipt"]);
        } else if (item.reportServiceType == "Delivery") {
          sessionStorage.setItem("deliveryId", String(item.reportMapId));
          this.router.navigate(["/cold-storage-delivery-receipt"]);
        }
        break;
      }
      case "Pre Processing": {
        if (item.reportServiceType == "Deposit") {
          sessionStorage.setItem("depositorId", String(item.reportMapId));
          this.router.navigate(["/preprocessing-deposit-receipt"]);
        } else if (item.reportServiceType == "Delivery") {

        }
        break;
      }
      case "Packing": {
        if (item.reportServiceType == "Deposit") {
          sessionStorage.setItem("depositorId", String(item.reportMapId));
          this.router.navigate(["/packing-deposit-receipt"]);
        } else if (item.reportServiceType == "Delivery") {

        }
        break;
      }
      case "Solar Tunnel Dryer": {
        if (item.reportServiceType == "Deposit") {
          sessionStorage.setItem("depositorId", String(item.reportMapId));
          this.router.navigate(["/solar-tunnel-dryer-deposit-receipt"]);
        } else if (item.reportServiceType == "Delivery") {
          sessionStorage.setItem("deliveryId", String(item.reportMapId));
          this.router.navigate(["/solar-tunnel-dryer-delivery-receipt"]);
        }
        break;
      }
    }
  }

}
