import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, takeUntil } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { ConfigService } from "@/config.service";
import { AftcMembershipDetails } from "@/model/membershipDetails/aftc-membershipDetails";
import { PaymentInitModal } from "pg-test-project";
import * as React from "react";
import { createRoot } from "react-dom/client";
import * as uuid from 'uuid';
import { DatePipe } from "@angular/common";


@Component({
  selector: "app-basic-aftc-testing-membership-form",
  templateUrl: "./basic-aftc-testing-membership-form.component.html",
  styleUrls: ["./basic-aftc-testing-membership-form.component.scss"]
})
export class BasicAftcTestingMembershipForm implements OnInit {
  @ViewChild("sabPaisaSdk", { static: true }) containerRef!: ElementRef;
  destroy$: Subject<boolean> = new Subject<boolean>();
  fileList: File[] = [];
  formData = new FormData();
  memberOfAftc: string;
  burron: false;
  emailIdOTP = false;
  email: string;
  memeberId: number;
  firstValue: string = '';
  secondValue: string = '';
  thirdValue: string = '';
  fourthValue: string = '';
  onFarmer: boolean = true;
  memberId: number = 0;
  getMemberDetails: AftcMembershipDetails;
  allMembershipDetails: AftcMembershipDetails[] = [];
  onShow: boolean = true;
  readOnly: boolean = false;
  cureetDtate = new Date();
  reactRoot: any = React.createRef();
  maintenance: boolean = true;

  constructor(private apiServices: ApiService, private spinner: NgxSpinnerService,
    private router: Router, private toastr: ToastrService, private datePipe: DatePipe,
    private configService: ConfigService, private route: ActivatedRoute,) {

  }
  termscondition: boolean = false;


  membership = new UntypedFormGroup({
    applicantName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    applicantRole: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    organizationName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    orgType: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    postalAddress: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    phoneNo: new UntypedFormControl('', [Validators.required, Validators.nullValidator, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    contactPerson: new UntypedFormControl('', [Validators.required, Validators.nullValidator,Validators.pattern('^[A-Za-z ]*$')]),
    emailId: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    whatsappNo: new UntypedFormControl('', [Validators.required, Validators.nullValidator, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    udyamRegistrationNo: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    udyamCertificatePath: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    gstNo: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    gstCertificatePath: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    panNo: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    panImagePath: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    aadhaarNo: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    aadhaarImagePath: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    businessType: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    businessName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    memberOfAftc: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    annualTurnoverGroup: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    forum: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    acknowledgement: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    status: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    city: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    memberPeriod: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    countryCode: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    paymentStatus: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    admissionFee: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createdAt: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    updateAt: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    pincode: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
  });

  public membershipError = {
    applicantName: '',
    postalAddress: ' ',
    memberPeriod: ' ',
    whatsappNo: ' ',
    emailId: '',
    orgType: '',
    contactPerson: '',
    pincode: '',
  };

  onPhoneNumber(event: any) {
    const value = event.target.value
    this.membership.get("whatsappNo").setValue(value)
  }


  membershipValidation() {
    this.membershipError.applicantName = "";
    this.membershipError.memberPeriod = "";
    this.membershipError.whatsappNo = "";
    this.membershipError.postalAddress = "";
    this.membershipError.orgType = "";
    this.membershipError.contactPerson = "";
    this.membershipError.pincode = "";


    let hasError = false

    if (this.membership.get("applicantName")?.invalid) {
      this.membershipError.applicantName = "* Applicant's Name";
      hasError = true
    }
    if (this.membership.get("memberPeriod")?.invalid) {
      this.membershipError.memberPeriod = "* Select a Member Period Type";
      hasError = true
    }
    if (this.membership.get("postalAddress")?.invalid) {
      this.membershipError.postalAddress = "* Enter a Postal Address";
      hasError = true
    }
    if (this.membership.get("whatsappNo")?.invalid) {
      if (this.membership.get('whatsappNo').errors.required) {
        this.membershipError.whatsappNo = "* Enter a Whatsapp Number";
      }
      if (this.membership.get('whatsappNo').errors.pattern) {
        this.membershipError.whatsappNo = "* Please, Enter 10 digit Valid Whatsapp Number.";
      }
      hasError = true;
    }
    if (this.membership.get("orgType")?.invalid) {
      this.membershipError.orgType = "* Select the Type of Organization";
      hasError = true
    }
    if (this.membership.get("contactPerson")?.invalid) {
      this.membershipError.contactPerson = "* Enter a Authorised Representative Name";
      hasError = true
    }
    if (this.membership.get("pincode")?.invalid) {
      this.membershipError.pincode = "* Enter a Pincode"
    }
    if (!hasError) {
      this.saveMembershipDetails()
    }
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const ulrParam = params["id"];

      const encResponse = params["encResponse"];

      if (ulrParam != null && encResponse == undefined) {
        this.memberId = ulrParam;
        this.getAllMembership();
        this.readOnly = true;
      } else if (ulrParam != null && encResponse != null) {
        let data1 = {
          encrytedData: encResponse,
          membershipDetailsId: Number(localStorage.getItem("userId")),
          isSendLink: true,
        };
        this.spinner.show();
        this.apiServices.updateTestMembership(data1)
          .subscribe((data) => {
            this.spinner.hide()
            if (data.paymentStatus == "SUCCESS") {
              this.alertShow();
            } else {
              this.errorAlertShow()
            }
            localStorage.removeItem("userId")
          }, (error: any) => {
            this.spinner.hide()
            console.log("Something Went wrong");
            this.toastr.error("Something went wrong , Please try again later....")
          })
      } else {
        const date = this.datePipe.transform(this.cureetDtate, 'yyyy-MM-dd');
        const dateFItler = "2025-04-01";
        if (dateFItler <= date) {
          this.membership.controls['admissionFee'].setValue('Admission Fee')
        } else {
          this.membership.get('admissionFee').disable()
        }

        this.membership.controls['countryCode'].setValue("+91 (India)")
        if (encResponse != null) {
          let data1 = {
            encrytedData: encResponse,
            membershipDetailsId: Number(localStorage.getItem("userId")),
            isSendLink: true,
          };
          this.spinner.show();
          this.apiServices.updateTestMembership(data1)
            .subscribe((data) => {
              this.spinner.hide()
              if (data.paymentStatus == "SUCCESS") {
                this.alertShow();
              } else {
                this.errorAlertShow()
              }
              localStorage.removeItem("userId")
            }, (error: any) => {
              this.spinner.hide();
              console.log("Something Went wrong");
              this.toastr.error("Something went wrong , Please try again later....")
            })
        }
      }
    });

  }


  getMemberDetailsId() {
    this.apiServices.getMemberDetailsid(this.memberId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.getMemberDetails = data;
        this.membership.controls['applicantName'].setValue(this.getMemberDetails.applicantName)
        this.membership.controls['memberPeriod'].setValue(this.getMemberDetails.memberPeriod)
        this.membership.controls['phoneNo'].setValue(this.getMemberDetails.phoneNo)
        this.membership.controls['whatsappNo'].setValue(this.getMemberDetails.whatsappNo)
        this.membership.controls['contactPerson'].setValue(this.getMemberDetails.contactPerson)
        this.membership.controls['postalAddress'].setValue(this.getMemberDetails.postalAddress)
        this.membership.controls['emailId'].setValue(this.getMemberDetails.emailId)
        this.membership.controls['orgType'].setValue(this.getMemberDetails.orgType)
        this.membership.controls['admissionFee'].setValue(this.getMemberDetails.admissionFee)
        this.membership.controls['pincode'].setValue(this.getMemberDetails.pincode)
        this.membership.controls['countryCode'].setValue("+91 (India)");
        if (this.getMemberDetails.emailId == 'contact@agrofoodchamber.com') {
          this.onFarmer = false;
        }
        for (let test of this.allMembershipDetails) {
          if (test.whatsappNo == this.getMemberDetails.whatsappNo && test.orgType == this.getMemberDetails.orgType) {
            if (test.paymentStatus == 'SUCCESS') {
              this.onShow = false;
            } else if (test.paymentStatus == 'FAILED') {
              this.onShow = true;
            }
          }
        }
      }, (error: any) => {
        console.log("Something Went wrong");
        this.toastr.error("Something went wrong , Please try again later....")
      })
  }

  getAllMembership() {
    this.apiServices.getAllMembershipDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.allMembershipDetails = data;
        this.getMemberDetailsId();
      }, (error: any) => {
        console.log("Something Went wrong");
        this.toastr.error("Something went wrong , Please try again later....")
      })
  }

  onSubmit() {
    this.membershipValidation();
  }

  onShowFarmers(event: any) {
    const value = event.target.value;
    if (value == 'Farmer' || value == 'Self Help Group') {
      this.onFarmer = false;
    } else {
      this.onFarmer = true;
    }
  }

  OnlyNumbers($event) {
    let regex: RegExp = new RegExp(/^[0-9]{1,}$/g);
    let specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowRight', 'ArrowLeft'];
    if (specialKeys.indexOf($event.key) !== -1) {
      return;
    } else {
      if (regex.test($event.key)) {
        return true;
      } else {
        return false;
      }
    }
  }

  onlyAllowedCharacters(event: KeyboardEvent): boolean {
    let allowedChars: RegExp = /^[A-Za-z .,]*$/;
    let specialKeys: Array<string> = [
      'Backspace', 'Tab', 'End', 'Home', 'ArrowRight', 'ArrowLeft', 'Delete', 'Enter'
    ];
    if (specialKeys.indexOf(event.key) !== -1) {
      return true;
    } else {
      if (allowedChars.test(event.key)) {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
    }
  }

  saveMembershipDetails() {
    this.spinner.show()
    if (this.membership.get('orgType').value == 'Farmer' || this.membership.get('orgType').value == 'Self Help Group' || this.membership.get('emailId').value == '') {
      this.membership.controls['emailId'].setValue("contact@agrofoodchamber.com")
    }
    const date = this.datePipe.transform(this.cureetDtate, 'dd-MM-yyy');
    this.membership.controls['createdAt'].setValue(date)
    this.membership.controls['countryCode'].setValue("91")
    this.membership.controls['status'].setValue('Approved');
    const formData = new FormData();
    const jsonData = this.membership.value;
    formData.append('membershipDetails', new Blob([JSON.stringify(jsonData)], { type: 'application/json' }));
    this.apiServices.saveTestMembership(formData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.memeberId = data.membershipDetailsId;
        localStorage.setItem('userId', this.memeberId.toString());
        this.spinner.hide();
        this.showPayment(data)
      }, (error: any) => {
        this.spinner.hide();
        console.log("Something Went wrong");
        this.toastr.error("Something went wrong , Please try again later....")
      })
  }

  showPayment(data: AftcMembershipDetails) {
    this.memeberId = data.membershipDetailsId;
    if (data.memberPeriod == 'Short Term Membership') {
      if (this.membership.get('admissionFee').value == 'Admission Fee') {
        var amount = '1500'
      } else {
        var amount = '500'
      }
      let config: any = {
        env: "prod",
        clientTxnId: uuid.v4(),
        payerName: data.applicantName,
        payerEmail: data.emailId,
        payerMobile: data.whatsappNo,
        payerAddress: data.postalAddress,
        amount: amount,
        isOpen: false,
        clientCode: this.configService.getConfig().CLIENT_ID,
        transUserName: this.configService.getConfig().PG_USERNAME,
        transUserPassword: this.configService.getConfig().PG_PASSWORD,
        authkey: this.configService.getConfig().PG_AUTH_KEY,
        authiv: this.configService.getConfig().PG_AUTH_VALUE,
        sabPaisaUrl: this.configService.getConfig().PG_BASE_URL,
        callbackUrl: 'https://aftc.varnik.cloud/#/basic-chamber-registration',
        udf20: data.membershipDetailsId,
      }
      const isOpen = true
      this.render(isOpen, config)
    } else if (data.memberPeriod == "Long Term Membership") {
      if (this.membership.get('admissionFee').value == 'Admission Fee') {
        var amount = '3000'
      } else {
        var amount = '2000'
      }
      let config: any = {
        env: "prod",
        clientTxnId: uuid.v4(),
        payerName: data.applicantName,
        payerEmail: data.emailId,
        payerMobile: data.whatsappNo,
        payerAddress: data.postalAddress,
        amount: amount,
        isOpen: false,
        clientCode: this.configService.getConfig().CLIENT_ID,
        transUserName: this.configService.getConfig().PG_USERNAME,
        transUserPassword: this.configService.getConfig().PG_PASSWORD,
        authkey: this.configService.getConfig().PG_AUTH_KEY,
        authiv: this.configService.getConfig().PG_AUTH_VALUE,
        sabPaisaUrl: this.configService.getConfig().PG_BASE_URL,
        callbackUrl: 'https://aftc.varnik.cloud/#/basic-chamber-registration',
        udf20: data.membershipDetailsId,
      }
      const isOpen = true
      this.render(isOpen, config)
    } 
    else {
      let config: any = {
        env: "prod",
        clientTxnId: uuid.v4(),
        payerName: data.applicantName,
        payerEmail: data.emailId,
        payerMobile: data.whatsappNo,
        payerAddress: data.postalAddress,
        amount: '1',
        isOpen: false,
        clientCode: this.configService.getConfig().CLIENT_ID,
        transUserName: this.configService.getConfig().PG_USERNAME,
        transUserPassword: this.configService.getConfig().PG_PASSWORD,
        authkey: this.configService.getConfig().PG_AUTH_KEY,
        authiv: this.configService.getConfig().PG_AUTH_VALUE,
        sabPaisaUrl: this.configService.getConfig().PG_BASE_URL,
        callbackUrl: 'https://aftc.varnik.cloud/#/basic-chamber-registration',
        udf20: data.membershipDetailsId,
      }
      const isOpen = true
      this.render(isOpen, config)
    }
  }

  private render(isOpen: boolean, config: any) {
    this.reactRoot.render(
      React.createElement(PaymentInitModal, { ...config, isOpen }))
  }

  ngAfterViewInit() {
    this.reactRoot = createRoot(this.containerRef.nativeElement);
    this.render(false, '');
  }


  alertShow() {
    Swal.fire({
      title: "Details Saved Successfully, Kindly check with Chamber Administrative",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        location.replace("https://agrofoodchamber.com/")
      }
    });
  }


  moveFocus(e: any, p: any, c: any, n: any) {
    var length = c.value.length;
    var maxlength = c.getAttribute('maxlength');
    if (length == maxlength) {
      if (n != "") {
        n.focus();
      }
    }
    if (e.key == "Backspace") {
      if (p != "") {
        p.focus();
      }
    }
  }


  errorAlertShow() {
    Swal.fire({
      title: "Payment Failed, Kindly check with Chamber Administrative",
      icon: "error",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        location.replace("https://agrofoodchamber.com/")
      }
    });
  }

}

