import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '@modules/main/main.component';
import { BlankComponent } from '@pages/blank/blank.component';
import { LoginComponent } from '@modules/login/login.component';
import { ProfileComponent } from '@pages/profile/profile.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { AuthGuard } from '@guards/auth.guard';
import { ForgotPasswordComponent } from '@modules/forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from '@modules/recover-password/recover-password.component';
import { SubMenuComponent } from '@pages/main-menu/sub-menu/sub-menu.component';
import { ProductsComponent } from '@components/aftc-digitalization/products/products.component';
import { EntryListComponent } from '@components/aftc-digitalization/entry-list/entry-list.component';
import { EmployeeDetailsComponent } from '@components/aftc-digitalization/employee-details/employee-details.component';
import { TollGateFormComponent } from '@components/aftc-digitalization/Tollgate/toll-gate-form/toll-gate-form.component';
import { RenterDetailsComponent } from './renter-details/renter-details.component';
import { WeightBridgeEntryComponent } from '@components/aftc-digitalization/weigh-bridge/weigh-bridge-entry/weight-bridge-entry.component';
import { TollGatePrintScreenComponent } from "@components/aftc-digitalization/Tollgate/toll-gate-print-screen/toll-gate-print-screen.component";
import { PrintViewComponent } from "@components/aftc-digitalization/print-view/print-view.component";
import { FrontOfficeComponent } from "@components/aftc-digitalization/front-office/front-office.component";
import { AftcReportEntry } from "@components/labModule/aftc-report-entry/aftc-report-entry";
import { AFTCProductComponent } from "@components/labModule/aftc-product/aftc-product.component";
import { AFTCPrintComponent } from "@components/labModule/aftc-print/aftc-print.component";
import { AFTCAddProductComponent } from "@components/labModule/aftc-add-product/aftc-add-product.component";
import { AFTCEditComponent } from "@components/labModule/aftc-edit/aftc-edit.component";
import { AFTCShowComponent } from "@components/labModule/aftc-show/aftc-show.component";
import { AFTCViewComponent } from "@components/labModule/aftc-view/aftc-view.component";
import { WarehouseDepositEntryFormComponent } from '@components/aftc-digitalization/Warehouse/warehouse-deposit/warehouse-deposit-entry-form.component';
import { WarehouseDeliveryEntryFormComponent } from '@components/aftc-digitalization/Warehouse/warehouse-delivery/warehouse-delivery-entry-form.component';
import { PendingTestComponent } from "@components/labModule/pending-test/pending-test.component";
import { CameraRecordsComponent } from "@components/camera-records/camera-records.component";
import { AftcTechAnalystComponent } from '@components/labModule/aftc-tech-analyst-print/aftc-tech-analyst-print.component';
import { StockDetails } from '@components/impact-study/Impact-data-entry/impact-data-entry.component';
import { ImPactStudy } from '@components/impact-study/Impact-main-study/Impact-main-study.component';
import { ImpactDataView } from '@components/impact-study/Impact-data-view/Impact-data-view.component';
import { ImpactDashboard } from '@components/impact-study/Impact-dashboard/Impact-dashboard.component';
import { NewsFeedAdminComponent } from "@components/news-feed-admin/news-feed-admin.component";
import { NewsFeedViewComponent } from "@components/news-feed-view/news-feed-view.component";
import { AftcMembershipView } from '@components/aftc-membership/aftc-membership-details-view/aftc-membership-view.component';
import { AftcMembershipForm } from '@components/aftc-membership/aftc-membership-form/aftc-membership-form.component';
import { BasicAftcMembershipForm } from '@components/aftc-membership/basic-aftc-membership-form/basic-aftc-membership-form.component';
import { DepotEntryForm } from '@components/impact-study/aftc-depot-details/depot-entry-form.component';
import { AftcDepotView } from '@components/impact-study/aftc-depot-view/aftc-depot-view.component';
import { depotVehicleDataView } from '@components/impact-study/depot-vehicle-data-view/depot-vehicle-data-view.component';
import { AdminVehicleDataView } from '@components/impact-study/admin-vehicle-data-view/admin-vehicle-data-view.component';
import { VehiclePunchDataView } from '@components/impact-study/vehicle-punch-data-view/vehicle-punch-data-view.component';
import { BasicAftcTestingMembershipForm } from '@components/aftc-membership/basic-aftc-testing-membership-form/basic-aftc-testing-membership-form.component';
import { AftcTestingMembershipForm } from '@components/aftc-membership/aftc-testing-membership-form/aftc-testing-membership-form.component';
import { WarehouseDeliveryPrintComponent } from "@components/aftc-digitalization/Warehouse/warehouse-delivery-print/warehouse-delivery-print.component";
import { WarehouseDepositPrintComponent } from "@components/aftc-digitalization/Warehouse/warehouse-deposit-print/warehouse-deposit-print.component";
import { TollGateViewComponent } from '@components/aftc-digitalization/Tollgate/toll-gate-view/toll-gate-view.component';
import { TollGateOverallPrintComponent } from '@components/aftc-digitalization/Tollgate/toll-gate-cash-overall-print/toll-gate-cash-overall-print.component';
import { TollGateCashManagementComponent } from '@components/aftc-digitalization/Tollgate/toll-gate-cash-management/toll-gate-cash-management.component';
import { TollgateReceiptPrintComponent } from "@components/aftc-digitalization/Tollgate/tollgate-receipt-print/tollgate-receipt-print-component";
import { PreprocessingDepositComponent } from "@components/aftc-digitalization/preprocessing/preprocessing-deposit/preprocessing-deposit-component";
import { PreprocessingDeliveryComponent } from "@components/aftc-digitalization/preprocessing/preprocessing-delivery/preprocessing-delivery-component";
import { PreprocessingDepositPrintComponent } from "@components/aftc-digitalization/preprocessing/preprocessing-deposit-print/preprocessing-deposit-print.component";
import { PreprocessingDeliveryPrintComponent } from "@components/aftc-digitalization/preprocessing/preprocessing-delivery-print/preprocessing-delivery-print.component";
import {
  ColdStorageDepositEntryFormComponent
} from "@components/aftc-digitalization/Cold-Storage/cold-storage-deposit/cold-storage-deposit-entry-form.component";
import {
  ColdStorageDeliveryEntryFormComponent
} from "@components/aftc-digitalization/Cold-Storage/cold-storage-delivery/cold-storage-delivery-entry-form.component";
import {
  ColdStorageDepositPrintComponent
} from "@components/aftc-digitalization/Cold-Storage/cold-storage-deposit-print/cold-storage-deposit-print.component";
import {
  ColdStorageDeliveryPrintComponent
} from "@components/aftc-digitalization/Cold-Storage/cold-storage-delivery-print/cold-storage-delivery-print.component";
import {
  PackingDepositComponent
} from "@components/aftc-digitalization/Packing/packing-deposit/packing-deposit-component";
import {
  PackingDeliveryComponent
} from "@components/aftc-digitalization/Packing/packing-delivery/packing-delivery-component";
import {
  PackingDepositPrintComponent
} from "@components/aftc-digitalization/Packing/packing-deposit-print/packing-deposit-print.component";
import {
  PackingDeliveryPrintComponent
} from "@components/aftc-digitalization/Packing/packing-delivery-print/packing-delivery-print.component";
import { StackHolderDetailsComponent } from '@components/aftc-digitalization/stack-holder-details/stack-holder-details.component';
import { StackDetailsComponent } from '@components/aftc-digitalization/stack-details/stack-details.component';
import { StockDetailsViewComponent } from '@components/aftc-digitalization/stock-details-view/stock-details-view.component';
import { DepositUnitEntryComponent } from "@components/aftc-digitalization/Warehouse/deposit-unit-entry/deposit-unit-entry.component";
import { UnitViewComponent } from "@components/aftc-digitalization/Warehouse/unit-view/unit-view.component";
import {
  DeliveryUnitEntryComponent
} from "@components/aftc-digitalization/Warehouse/delivery-unit-entry/delivery-unit-entry.component";
import {
  WeighBridgeViewComponent
} from "@components/aftc-digitalization/weigh-bridge/weigh-bridge-view/weigh-bridge-view.component";
import {
  WeighBridgePrintComponent
} from "@components/aftc-digitalization/weigh-bridge/weigh-bridge-print/weigh-bridge-print.component";
import {
  WeighBridgeCashManagementComponent
} from "@components/aftc-digitalization/weigh-bridge/weigh-bridge-cash-management/weigh-bridge-cash-management.component";
import {
  WeighBridgeDetailViewComponent
} from "@components/aftc-digitalization/weigh-bridge/weigh-bridge-detail-view/weigh-bridge-detail-view.component";
import {
  WeighBridgeCashOverallPrintComponent
} from "@components/aftc-digitalization/weigh-bridge/weigh-bridge-cash-overall-print/weigh-bridge-cash-overall-print.component";
import { DeliveryRequestViewComponent } from "@components/aftc-digitalization/Cold-Storage/delivery-request-view/delivery-request-view.component";
import { StockManagementComponent } from "@components/aftc-digitalization/Cold-Storage/stock-management/stock-management.component";
import { SolarTunnelDryerDepositEntryFormComponent } from '@components/aftc-digitalization/Solar-Tunnel-Dryer/solar-tunnel-dryer--deposit/solar-tunnel-dryer-deposit-entry-form.component';
import { SolarTunnelDryerDepositPrintComponent } from '@components/aftc-digitalization/Solar-Tunnel-Dryer/solar-tunnel-dryer--deposit-print/solar-tunnel-dryer-deposit-print.component';
import { SolarTunnelDryerDeliveryEntryFormComponent } from '@components/aftc-digitalization/Solar-Tunnel-Dryer/solar-tunnel-dryer-delivery/solar-tunnel-dryer-delivery-entry-form.component';
import { SolarTunnelDryerDeliveryPrintComponent } from '@components/aftc-digitalization/Solar-Tunnel-Dryer/solar-tunnel-dryer-delivery-print/solar-tunnel-dryer-delivery-print.component';
import { AddNewMembersDetailsComponent } from '@components/impact-study/add-new-members/add-new-members-component';
import { DepositorViewComponent } from '@components/aftc-digitalization/Cold-Storage/depositor-view/depositor-view.component';
import { ColdStorageCreditScreenComponent } from '@components/aftc-digitalization/Cold-Storage/cold-storage-credit-screen/cold-storage-credit-screen.component';
import { DepositRequestViewComponent } from '@components/aftc-digitalization/Cold-Storage/deposit-request-view/deposit-request-view.component';
import { CommodityDetailsViewComponent } from '@components/aftc-digitalization/Cold-Storage/commodity-details-view/commodity-details-view.component';
import { ColdStorageDepositCasedPrintComponent } from '@components/aftc-digitalization/Cold-Storage/cold-storage-deposit-case-print/cold-storage-deposit-case-print.component';
import { ColdStorageDeliveryCasedPrintComponent } from '@components/aftc-digitalization/Cold-Storage/cold-storage-delivery-case-print/cold-storage-delivery-case-print.component';

const routes: Routes = [
  {
    path: 'main',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    // canDeactivate: [AuthGuard],
    children: [
      {
        path: 'profile',
        component: ProfileComponent,
      },
      {
        path: 'blank',
        component: BlankComponent,
      },
      {
        path: 'sub-menu-1',
        component: SubMenuComponent,
      },
      {
        path: 'products',
        component: ProductsComponent,
      },
      {
        path: 'entry',
        component: EntryListComponent,
      },
      {
        path: 'employee',
        component: EmployeeDetailsComponent,
      },
      {
        path: 'tollgate',
        component: TollGateFormComponent,
      },

      {
        path: 'renter',
        component: RenterDetailsComponent,
      },
      {
        path: 'sub-menu-2',
        component: BlankComponent,
      },
      {
        path: 'sub-menu-2',
        component: BlankComponent,
      },
      {
        path: 'weigh-bridge-entry',
        component: WeightBridgeEntryComponent,
      },
      {
        path: 'weigh-bridge-view',
        component: WeighBridgeViewComponent,
      },
      {
        path: 'weigh-bridge-detail-view',
        component: WeighBridgeDetailViewComponent,
      },
      {
        path: 'frontOffice',
        component: FrontOfficeComponent,
      },
      {
        path: 'dash',
        component: DashboardComponent,
      },
      {
        path: 'aftcl-Report-Screen',
        component: AftcReportEntry
      },
      {
        path: 'aftcl-Product-Screen',
        component: AFTCProductComponent,
      },
      {
        path: 'aftcl-add-Product-Screen',
        component: AFTCAddProductComponent,
      },
      {
        path: 'aftcl-Edit-Screen',
        component: AFTCEditComponent,
      },
      {
        path: 'pending-test',
        component: PendingTestComponent,
      },
      {
        path: 'depositEntry',
        component: WarehouseDepositEntryFormComponent,
      },
      {
        path: 'preprocessing-deposit',
        component: PreprocessingDepositComponent,
      },
      {
        path: 'preprocessing-delivery',
        component: PreprocessingDeliveryComponent,
      },
      {
        path: 'cold-storage-deposit',
        component: ColdStorageDepositEntryFormComponent,
      },
      {
        path: 'cold-storage-delivery',
        component: ColdStorageDeliveryEntryFormComponent,
      },
      {
        path: 'packing-deposit',
        component: PackingDepositComponent,
      },
      {
        path: 'packing-delivery',
        component: PackingDeliveryComponent,
      },
      {
        path: 'deliveryEntry',
        component: WarehouseDeliveryEntryFormComponent,
      },
      {
        path: 'stockDetails',
        component: StockDetails
      },
      {
        path: 'impactStudy',
        component: ImPactStudy
      },
      {
        path: 'impactDataView',
        component: ImpactDataView
      },
      {
        path: 'impactDashboard',
        component: ImpactDashboard
      },
      {
        path: 'newsFeedAdmin',
        component: NewsFeedAdminComponent
      },
      {
        path: 'newsFeedView',
        component: NewsFeedViewComponent
      },
      {
        path: 'membershipView',
        component: AftcMembershipView
      },
      {
        path: 'depotDetails',
        component: DepotEntryForm
      },
      {
        path: 'depotView',
        component: AftcDepotView
      },
      {
        path: 'depotVehicleDetails',
        component: depotVehicleDataView
      },
      {
        path: 'adminVehicleDetails',
        component: AdminVehicleDataView
      },
      {
        path: 'vehiclePunchDetails',
        component: VehiclePunchDataView
      },
      {
        path: 'tollGateView',
        component: TollGateViewComponent
      },
      {
        path: 'tollGateCash',
        component: TollGateCashManagementComponent
      },
      {
        path: 'weigh-bridge-cash',
        component: WeighBridgeCashManagementComponent
      },
      {
        path: 'stackHolders',
        component: StackHolderDetailsComponent
      },
      {
        path: 'stack-details-view',
        component: StackDetailsComponent
      },
      {
        path: 'stock-details-view',
        component: StockDetailsViewComponent,
      },
      {
        path: 'warehouse-deposit-unit-entry',
        component: DepositUnitEntryComponent
      },
      {
        path: 'warehouse-delivery-unit-entry',
        component: DeliveryUnitEntryComponent
      },
      {
        path: 'warehouse-unit-view',
        component: UnitViewComponent
      },
      {
        path: 'stock-management',
        component: StockManagementComponent
      },
      {
        path: 'solar-tunnel-dryer-deposit',
        component: SolarTunnelDryerDepositEntryFormComponent
      },
      {
        path: 'solar-tunnel-dryer-delivery',
        component: SolarTunnelDryerDeliveryEntryFormComponent
      },
      {
        path: 'add-new-members',
        component: AddNewMembersDetailsComponent,
      },
      {
        path: 'admin-cold-storage-delivery-request-view',
        component: DeliveryRequestViewComponent,
      },
      {
        path: 'admin-cold-storage-deposit-request-view',
        component: DepositRequestViewComponent,
      },
      {
        path: 'cold-storage-depositor-view',
        component: DepositorViewComponent,
      },
      {
        path: 'cold-storage-credits',
        component: ColdStorageCreditScreenComponent,
      },
      {
        path: 'commodity-details-view',
        component: CommodityDetailsViewComponent,
      }
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'qrEntry',
    component: PrintViewComponent,
  },

  {
    path: 'register',
    component: EmployeeDetailsComponent,
  },
  {
    path: 'toll-gate-print',
    component: TollGatePrintScreenComponent,
  },
  {
    path: 'deposit-print',
    component: WarehouseDepositPrintComponent,
  },
  {
    path: 'aftcl-view-Screen',
    component: AFTCViewComponent,
  },
  {
    path: 'aftcl-Print-ScreenWeb/:ulrNo',
    component: AFTCPrintComponent,
  },
  {
    path: 'aftc-show-Screen/:ulrNo',
    component: AFTCShowComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'recover-password',
    component: RecoverPasswordComponent,
  },
  {
    path: 'camera-records',
    component: CameraRecordsComponent
  },
  {
    path: 'aftcl-tech-print/:ulrNo',
    component: AftcTechAnalystComponent,
  },
  {
    path: 'chamberregistration',
    component: AftcMembershipForm,
  },
  {
    path: 'testchamberregistraion',
    component: AftcTestingMembershipForm,
  },
  {
    path: 'basic-chamber-registration',
    component: BasicAftcMembershipForm,
  },
  {
    path: 'basic-test-chamber-registration',
    component: BasicAftcTestingMembershipForm,
  },
  {
    path: 'deliveryPrint',
    component: WarehouseDeliveryPrintComponent,
  },
  {
    path: 'toll-gate-cash-print',
    component: TollGateOverallPrintComponent,
  },
  {
    path: 'tollgate-receipt',
    component: TollgateReceiptPrintComponent,
  },
  {
    path: 'weigh-receipt',
    component: WeighBridgePrintComponent,
  },
  {
    path: 'weigh-bridge-cash-receipt',
    component: WeighBridgeCashOverallPrintComponent,
  },
  {
    path: 'preprocessing-deposit-receipt',
    component: PreprocessingDepositPrintComponent
  },
  {
    path: 'preprocessing-delivery-receipt',
    component: PreprocessingDeliveryPrintComponent
  },
  {
    path: 'cold-storage-deposit-receipt',
    component: ColdStorageDepositPrintComponent
  },
  {
    path: 'cold-storage-delivery-receipt',
    component: ColdStorageDeliveryPrintComponent
  },
  {
    path: 'packing-deposit-receipt',
    component: PackingDepositPrintComponent
  },
  {
    path: 'packing-delivery-receipt',
    component: PackingDeliveryPrintComponent
  },
  {
    path: 'solar-tunnel-dryer-deposit-receipt',
    component: SolarTunnelDryerDepositPrintComponent
  },
  {
    path: 'solar-tunnel-dryer-delivery-receipt',
    component: SolarTunnelDryerDeliveryPrintComponent
  },
  {
    path: 'cold-storage-deposit-case-print',
    component: ColdStorageDepositCasedPrintComponent,
  },
  {
    path: 'cold-storage-delivery-case-print',
    component: ColdStorageDeliveryCasedPrintComponent,
  },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
