import { TollGate } from "@components/aftc-digitalization/aftc-digitalization-models/TollGate";
import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, takeUntil } from "rxjs";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import {
  WarehouseStockDeposit
} from "@/components/aftc-digitalization/aftc-digitalization-models/warehouseStockDeposit";
import { ColdStorageCreditedDetails } from "@components/aftc-digitalization/aftc-digitalization-models/cold-storage-credited-details";
import { CommodityDetails } from "@/model/commodityDetails/CommodityDetails";


@Component({
  selector: "app-cold-storage-deposit-entry-form",
  templateUrl: "./cold-storage-deposit-entry-form.component.html",
  styleUrls: ["./cold-storage-deposit-entry-form.component.scss"]
})
export class ColdStorageDepositEntryFormComponent implements OnInit {

  getLocalPassDetails: TollGate[] = [];
  getPassDetails: TollGate | undefined;
  depositorDetails: WarehouseStockDeposit | undefined;
  destroy$: Subject<boolean> = new Subject<boolean>();
  balanceDetails: ColdStorageCreditedDetails;
  depotId: number = 0;
  currentAvailableBalance: number = 0;
  currentWeight: number = 0;
  currentQuantity: number = 0;
  isShowCalculation: boolean = true;
  isShowSave: boolean = false;
  commodityDetails: CommodityDetails[] = [];
  commodityNames: string[] = [];
  showTaxValue: boolean = false;
  constructor(private apiServices: ApiService, private spinner: NgxSpinnerService,
    private router: Router, private toaster: ToastrService) {

  }

  depositForm = new UntypedFormGroup({
    stockDepositId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    // depositApplicationNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    depositDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    depositorName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    invoiceNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    billDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    endDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    commodityName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    quantity: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    weight: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    typeOfVehicle: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    wheels: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehicleNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    amount: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    createdAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    updatedAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    createdBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    updatedBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    currentStage: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    irmReceiptNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    storageType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    serviceType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    billPeriodType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    packageType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    commodityType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    cgst: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    sgst: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    roundedOff: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    totalAmount: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    placeOfOrigin: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    cgstAmount: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    sgstAmount: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    handlingCharge: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    availableBalance: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    // bearingNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    // bearingDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator])
  });

  depositFormErrors = {
    // depositApplicationNo: "",
    depositorName: "",
    invoiceNo: "",
    iRMReceiptNo: "",
    depositDate: "",
    billDate: "",
    commodityName: "",
    quantity: "",
    weight: "",
    typeOfVehicle: "",
    wheels: "",
    vehicleNo: "",
    amount: "",
    packageType: "",
    totalAmount: "",
    placeOfOrigin: "",
    // cgstAmount: "",
    // billPeriodType: "",
    // bearingNo: "",
    // bearingDate: ""
  };

  formValidation(from: any) {
    // this.depositFormErrors.depositApplicationNo = "";
    this.depositFormErrors.depositorName = "";
    this.depositFormErrors.invoiceNo = "";
    this.depositFormErrors.iRMReceiptNo = "";
    this.depositFormErrors.depositDate = "";
    this.depositFormErrors.billDate = "";
    this.depositFormErrors.commodityName = "";
    this.depositFormErrors.packageType = "";
    this.depositFormErrors.quantity = "";
    this.depositFormErrors.weight = "";
    this.depositFormErrors.typeOfVehicle = "";
    this.depositFormErrors.wheels = "";
    this.depositFormErrors.vehicleNo = "";
    this.depositFormErrors.amount = "";
    this.depositFormErrors.placeOfOrigin = "";
    // this.depositFormErrors.billPeriodType = "";
    // this.depositFormErrors.bearingNo = "";
    // this.depositFormErrors.bearingDate = "";
    let hasError = false;

    // if (this.depositForm.get("depositApplicationNo").invalid) {
    //   this.depositFormErrors.depositApplicationNo = "*Deposit Application No is Required";
    //   hasError = true;
    // }
    if (this.depositForm.get("depositorName").invalid) {
      this.depositFormErrors.depositorName = "*Depositor Name is Required";
      hasError = true;
    }
    if (this.depositForm.get("invoiceNo").invalid) {
      this.depositFormErrors.invoiceNo = "*Invoice Number is Required";
      hasError = true;
    }
    if (this.depositForm.get("irmReceiptNo").invalid) {
      this.depositFormErrors.iRMReceiptNo = "*IRM Receipt Number is Required";
      hasError = true;
    }
    /*if (this.depositForm.get("depositDate").invalid) {
      this.depositFormErrors.depositDate = "*Deposit Date is Required";
      hasError = true;
    }*/
    if (this.depositForm.get("billDate").invalid) {
      this.depositFormErrors.billDate = "*Bill Date is Required";
      hasError = true;
    }
    if (this.depositForm.get("commodityName").invalid) {
      this.depositFormErrors.commodityName = "*Commodity Name is Required";
      hasError = true;
    }
    if (this.depositForm.get("packageType").invalid) {
      this.depositFormErrors.packageType = "*Package Type is Required";
      hasError = true;
    }
    if (this.depositForm.get("quantity").invalid || this.depositForm.get("quantity").value === 0) {
      this.depositFormErrors.quantity = "*Number of Quantity is Required";
      hasError = true;
    }
    if (this.depositForm.get("weight").invalid) {
      this.depositFormErrors.weight = "*Weight is Required";
      hasError = true;
    }
    if (this.depositForm.get("typeOfVehicle").invalid) {
      this.depositFormErrors.typeOfVehicle = "*Type of Vehicle is Required";
      hasError = true;
    }
    if (this.depositForm.get("wheels").invalid) {
      this.depositFormErrors.wheels = "*Wheels Count is Required";
      hasError = true;
    }
    if (this.depositForm.get("vehicleNo").invalid) {
      this.depositFormErrors.vehicleNo = "*Vehicle Number is Required";
      hasError = true;
    }
    if (this.depositForm.get("amount").invalid) {
      this.depositFormErrors.amount = "*Amount is Required";
      hasError = true;
    }

    if (this.depositForm.get("placeOfOrigin").invalid) {
      this.depositFormErrors.placeOfOrigin = "*Place of Origin is Required";
      hasError = true;
    }

    if (this.isShowSave) {
      if (this.depositForm.get("totalAmount").invalid) {
        this.depositFormErrors.totalAmount = "*Total Amount is Required";
        hasError = true;
      }
    }
    // if (this.depositForm.get("billPeriodType").invalid) {
    //   this.depositFormErrors.billPeriodType = "*Bill Period Type is Required";
    //   hasError = true;
    // }
    // if (this.depositForm.get("bearingNo").invalid) {
    //   this.depositFormErrors.bearingNo = "*Bearing No is Required";
    //   hasError = true;
    // }
    // if (this.depositForm.get("bearingDate").invalid) {
    //   this.depositFormErrors.bearingDate = "*Bearing Date is Required";
    //   hasError = true;
    // }

    if (!hasError) {
      if (this.isShowSave) {
        if (this.depositForm.get('totalAmount').value <= this.currentAvailableBalance) {
          this.addStockDeposit();
        } else {
          this.toaster.error("Insufficient available balance");
        }
      } else if (this.isShowCalculation) {
        this.saveColdStorageHandlingCharge();
      }
    }
  }

  ngOnInit() {
    this.getCommodityDetails();
    // this.getApplicationNo();
    this.getTollgateDetailsForColdStorageDeposit();
    this.initial_data();
  }

  ngOnDestroy() {
    sessionStorage.removeItem("storageType");
    sessionStorage.removeItem("serviceType");
    sessionStorage.removeItem("tollgateSeqNo");
    sessionStorage.removeItem("depositId");
  }


  getCommodityDetails() {
    this.apiServices.getCommodityDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.commodityDetails = data.filter(item => item.unit == 'Cold Storage' && item.specialConcessionRent !== 0.0);
        console.log(this.commodityDetails)
        const set = new Set(this.commodityDetails.map(item => item.commodityName));
        this.commodityNames = Array.from(set).sort((a, b) => a.localeCompare(b));
      }, (err: any) => {
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  // getApplicationNo() {
  //   this.apiServices.getColdStorageDepositAppNo()
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe(data => {
  //       this.depositForm.controls["depositApplicationNo"].setValue(data.depositAppNo);
  //       const splitAppNo = data.depositAppNo.split("/");
  //       this.depositForm.controls["bearingNo"].setValue(splitAppNo[2]);
  //     }, (err: any) => {
  //       this.toaster.error("Something went wrong, Please try again later");
  //     });
  // }

  initial_data() {
    const now = new Date();
    const localDateTime = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
    const formattedDateTime = localDateTime.toISOString().slice(0, 16);
    this.depositForm.controls["depositDate"].setValue(formattedDateTime);
  }


  getTollgateDetailsForColdStorageDeposit() {
    this.spinner.show();
    this.apiServices.getTollGateById(Number(sessionStorage.getItem('tollgateSeqNo')))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        this.getPassDetails = data;
        this.depositForm.controls["depositorName"].setValue(this.getPassDetails.memberName);
        this.depositForm.controls["quantity"].setValue(this.getPassDetails.bagsUnits);
        this.depositForm.controls["weight"].setValue(this.getPassDetails.weightMt * 1000);
        this.depositForm.controls["typeOfVehicle"].setValue(this.getPassDetails.tollgateVehicleDetails.vehType);
        this.depositForm.controls["wheels"].setValue(this.getPassDetails.tollgateVehicleDetails.wheelCount);
        this.depositForm.controls["vehicleNo"].setValue(this.getPassDetails.tollgateVehicleDetails.vehNumberPlate);
        this.depositForm.controls["amount"].setValue(this.getPassDetails.charges);
        this.depositForm.controls["irmReceiptNo"].setValue(this.getPassDetails.irmNo);
        this.depositForm.controls["storageType"].setValue(sessionStorage.getItem("storageType"));
        this.depositForm.controls["serviceType"].setValue(sessionStorage.getItem("serviceType"));
        this.depotId = this.getPassDetails.depotId;
        setTimeout(() => {
          const commodityName = this.commodityNames.filter(item => item === this.getPassDetails.commodityName);
          console.log(commodityName)
          if (commodityName.length != 0) {
            console.log(commodityName)
            this.depositForm.controls["commodityName"].setValue(commodityName[0]);
            this.selectCommodity(commodityName[0]);
          }
        }, 1000)
        this.getColdStorageAvailableBalance();
      }, (err: any) => {
        this.spinner.hide();
        this.toaster.error(err.error.message || "Something went wrong, Please try again later");
      });
  }

  getColdStorageAvailableBalance() {
    this.apiServices.getColdStorageAvailabilityBalances(this.depotId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.balanceDetails = data;
        this.currentAvailableBalance = this.balanceDetails?.availableBalance;
        this.depositForm.controls['availableBalance'].setValue(this.currentAvailableBalance);
      }, (error: any) => {
        this.toaster.error(error.error.message || "Something went wrong, Please try again later");
      })
  }


  selectCommodity(event: any): void {
    const value = event.target.value;
    const commodityDetails = this.commodityDetails.find(item => item.commodityName === value);
    if (commodityDetails.inclusiveGst == 'Yes') {
      this.showTaxValue = true;
    } else {
      this.showTaxValue = false;
    }
    this.showCalculation();
  }

  showCalculation() {
    this.isShowCalculation = true;
    this.isShowSave = false;
  }

  checkQuantityValue(event: any) {
    const quantity = event.target.value;
    if (quantity != this.currentQuantity) {
      this.isShowCalculation = true;
      this.isShowSave = false;
    } else {
      this.isShowCalculation = false;
      this.isShowSave = true;
    }
  }

  checkWightValue(event: any) {
    const wight = event.target.value;
    if (wight != this.currentWeight) {
      this.isShowCalculation = true;
      this.isShowSave = false;
    } else {
      this.isShowCalculation = false;
      this.isShowSave = true;
    }
  }

  saveColdStorageHandlingCharge() {
    this.spinner.show();
    let commodityType: string = "";
    if (this.depositForm.get('commodityName').value === 'Chillies') {
      commodityType = "Chillies";
    } else {
      commodityType = "ExceptChillies"
    }
    this.depositForm.controls['commodityType'].setValue(commodityType)
    const JsonData = {
      commodityType: commodityType,
      packageType: this.depositForm.get('packageType').value,
      quantity: this.depositForm.get('quantity').value,
      weight: this.depositForm.get('weight').value,
      placeOfOrigin: this.depositForm.get('placeOfOrigin').value
    }
    this.currentWeight = JsonData.weight;
    this.currentQuantity = JsonData.quantity;
    this.apiServices.saveColdStorageHandlingCharge(JsonData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        this.depositForm.controls['cgst'].setValue(data.cgst);
        this.depositForm.controls['sgst'].setValue(data.sgst);
        this.depositForm.controls['cgstAmount'].setValue(data.cgstAmount);
        this.depositForm.controls['sgstAmount'].setValue(data.sgstAmount);
        this.depositForm.controls['roundedOff'].setValue(data.roundedOff);
        this.depositForm.controls['handlingCharge'].setValue(data.handlingCharge);
        this.depositForm.controls['totalAmount'].setValue(data.totalAmount);
        this.isShowCalculation = false;
        this.isShowSave = true;
      }, (error: any) => {
        this.spinner.hide();
        this.toaster.error(error.error.message || "Something went wrong, Please try again later");
      })
  }

  addStockDeposit() {
    this.spinner.show();
    const entryDate = this.depositForm.get("depositDate").value;
    const epochTimestamp = new Date(entryDate).getTime() / 1000;
    this.depositForm.controls["depositDate"].setValue(epochTimestamp * 1000);
    const billDate = this.depositForm.get("billDate").value;
    const epochStartTimestamp = new Date(billDate).getTime() / 1000;
    this.depositForm.controls["billDate"].setValue(epochStartTimestamp * 1000);
    // const bearingDate = this.depositForm.get("bearingDate").value;
    // const epochBearingDateTimestamp = new Date(bearingDate).getTime() / 1000;
    // this.depositForm.controls["bearingDate"].setValue(epochBearingDateTimestamp);
    this.depositForm.controls["createdAt"].setValue(Math.floor(new Date().getTime() / 1000));
    this.apiServices.saveColdStorageDepositRequest(this.depositForm.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        this.saveAlert(data.stockDepositId);
      }, (err: any) => {
        this.spinner.hide();
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  saveAlert(depositId: number) {
    Swal.fire({
      title: "Deposit Request Saved Successfully...",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate__animated animate__fadeInDown"
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp"
      }
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(["/main/frontOffice"]);
      }
    });
  }

}
